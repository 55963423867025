import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid, ruRU} from '@mui/x-data-grid';
import {AdsService} from "../../../services/ads.service";
import {useTranslation} from "react-i18next";
import {Alert, Box, IconButton, TextField, Typography} from "@mui/material";
import {adUrl} from "../../../components/ads/table/column/adUrl";
import {notes} from "../../../components/ads/table/column/notes";
import {keywords} from "../../../components/ads/table/column/keywords";
import {device} from "../../../components/ads/table/column/device";
import {KeywordsModal} from "../../../components/ads/KeywordsModal";
import {grey} from "@mui/material/colors"
import {HeaderAutocomplete} from "../../../components/autocompletes/HeaderAutocomplete";
import {CountryService} from "../../../services/country.service";
import {ProjectsService as ProjectService} from "../../../services/projects.service";
import moment from "moment";
import {AdsAdUrlFilter} from "./AdsAdUrlFilter";
import {country} from "../../../components/ads/table/column/country";
import {RouterLink} from "../../../components/RouterLink";
import {useNavigate} from "react-router-dom";
import {AdsCountModal} from "../../../components/ads/AdsCountModal/AdsCountModal";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../utils/Date";
import {AdUrlSelector} from "../../../components/autocompletes/AdUrlSelector";
import FlagIcon from '@mui/icons-material/Flag';
import {AddItemsToReportCampaignByAdUrl} from "./AddItemsToReportCampaignByAdUrl";
import {SubscriptionService} from "../../../services/subscription.service";
import toastr from "toastr";
import {OtherSubscriptionAlert} from "../../../components/subscriptions/OtherSubscriptionAlert";
import {getLastPageSize, getLastSelectedUser, setLastPageSize} from "../../../utils/Paginations";
import {CustomColors} from "../../../themes/custom-colors";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {AdsImpressionModal} from "./AdImpressionModal/AdsImpressionModal";

const grey400 = grey[400];

const sortDefault = {
    field: "sub_ads_count",
    order: "desc"
};

let filterTimer;

export const AdsAdUrl = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {role} = props;

    const params = new URLSearchParams(window.location.search);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [started, setStarted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(getLastPageSize());
    const [sort, setSort] = useState(sortDefault);
    const [countries, setCountries] = useState([]);
    const [projects, setProjects] = useState([]);

    const [keywordsModal, setKeywordsModal] = useState(false);
    const [keywordsModalAdUrl, setKeywordsModalAdUrl] = useState(null);
    const [keywordsModalProjectId, setKeywordsModalProjectId] = useState(null);

    const [countModal, setCountModal] = useState(false);
    const [countModalAdUrl, setCountModalAdUrl] = useState(null);

    const [adImpressionModal, setAdImpressionModal] = useState(false);
    const [adImpressionModalAdUrl, setAdImpressionModalAdUrl] = useState(null);

    const [addReportDialog, setAddReportDialog] = useState(false);
    const [addReportItem, setAddReportItem] = useState(null);

    const [parserSubscription, setParserSubscription] = useState(true);
    const [reporterSubscription, setReporterSubscription] = useState(false);

    const [filter, setFilter] = useState({
        projects: "",
        country: "",
        startDate: getDatePickerStartDate(),
        endDate: getDatePickerEndDate(),
        isNew: false,
        isActive: false,
        source: 0,
        device: 0,
        adUrl: params.get("ad_url") || "",
        user_id: getLastSelectedUser()
    });

    const columns = [
        {
            field: 'project_name',
            headerName: t("project"),
            minWidth: 200,
            sortable: false,
            renderCell: params => {
                return (
                    <div>
                        <div>{params.row.project_name}</div>
                        <Box sx={{marginTop: 1}}>
                            <IconButton
                                color="info"
                                size={"small"}
                                onClick={() => {
                                    setCountModal(true)
                                    setCountModalAdUrl(params.row.ad_url)
                                }}
                            >
                                <InsertChartIcon/>
                            </IconButton>

                            <IconButton
                                color="primary"
                                size={"small"}
                                onClick={() => {
                                    setAdImpressionModal(true)
                                    setAdImpressionModalAdUrl(params.row.ad_url)
                                }}
                            >
                                <VisibilityIcon/>
                            </IconButton>

                            {
                                reporterSubscription ? <IconButton
                                    color="warning"
                                    size={"small"}
                                    onClick={() => {
                                        setAddReportItem(params.row)
                                        setAddReportDialog(true)
                                    }}
                                >
                                    <FlagIcon/>
                                </IconButton> : ""
                            }
                        </Box>
                    </div>
                )
            },
            renderHeader: (params) => {
                return (
                    <div>
                        <HeaderAutocomplete
                            width={175}
                            labelKey={"name"}
                            label={params.colDef.headerName}
                            setValue={(e, value) => {
                                setFilter({...filter, projects: value !== null ? value.id : ""})
                            }}
                            data={projects}
                        />
                    </div>
                )
            },
        },
        {
            field: 'ad_url',
            headerName: t("site"),
            flex: 1,
            minWidth: 230,
            renderCell: params => {
                return adUrl(params, null, true);
            },
            renderHeader: (params) => {
                // return (
                //     <div>
                //         <TextField label={t("site")}
                //                    variant="standard"
                //                    sx={{width: 220}}
                //                    value={filter.adUrl}
                //                    onChange={(e) => {
                //                        setFilter({...filter, adUrl: e.target.value})
                //                    }}
                //         />
                //     </div>
                // )

                return (
                    <AdUrlSelector
                        variant={"standard"}
                        width={270}
                        userId={filter.user_id}
                        value={filter.adUrl}
                        setValue={(e, value) => {
                            setFilter({...filter, adUrl: value})
                        }}
                    />
                )
            },
        },
        {
            field: 'first_seen_at',
            headerName: t("first_seen_at"),
            width: 90,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'last_seen_at',
            headerName: t("last_seen_at"),
            width: 90,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'sub_ads_count',
            headerName: t("ads"),
            type: 'number',
            width: 130,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => {
                const href = `/dashboard/${role === "admin" ? "admin/" : ""}ads?ad_url=${params.row.ad_url}&project_id=${params.row.project_id}`;
                return (
                    <RouterLink color={"info"} href={href} text={params.row.sub_ads_count}/>
                )
            }
        },
        {
            field: 'notes',
            headerName: t("notes"),
            minWidth: 150,
            sortable: false,
            renderCell: params => {
                return notes(params, data, submitNotes, setEditableNotes)
            }
        },
        {
            field: 'preview_keyword_id_1',
            headerName: t("keywords"),
            width: 230,
            sortable: false,
            renderCell: params => {
                return keywords(params, () => {
                    setKeywordsModalAdUrl(params.row.ad_url);
                    setKeywordsModalProjectId(params.row.project_id);
                    setKeywordsModal(true)
                }, (item) => {
                    const href = `/dashboard/${role === "admin" ? "admin/" : ""}ads?project_id=${params.row.project_id}&keyword_id=${item.id}`;
                    navigate(href);
                })
            }
        },
        {
            field: 'country',
            headerName: t("countries"),
            width: 150,
            sortable: false,
            renderHeader: (params) => {
                return (
                    <div>
                        <HeaderAutocomplete
                            width={115}
                            labelKey={"label"}
                            label={params.colDef.headerName}
                            data={countries}
                            setValue={(e, value) => {
                                setFilter({...filter, country: value !== null ? value.code : ""})
                            }}
                        />
                    </div>
                )
            },
            renderCell: params => {
                return country(params.row.country, countries);
            }
        },
        {
            field: 'is_desktop',
            headerName: t("devices"),
            minWidth: 100,
            sortable: false,
            renderCell: params => {
                return device(params);
            }
        },
    ];

    useEffect(() => {
        if (filterTimer) {
            clearTimeout(filterTimer);
        }

        filterTimer = setTimeout(() => {
            getAds();
        }, 500)
    }, [page, sort, filter, pageSize])

    useEffect(() => {
        setPage(0)
    }, [filter])

    useEffect(() => {
        CountryService.get().then(data => {
            if (data.data) {
                setCountries(Object.keys(data.data).map(item => {
                    return {
                        code: item.toLowerCase(),
                        label: data.data[item]
                    }
                }))
            }
        })
    }, []);

    useEffect(() => {
        ProjectService.get(1, null, true, null, filter.user_id).then(data => {
            if (data.data) {
                setProjects(data.data)
            }
        })
    }, [filter.user_id])

    useEffect(() => {
        if(role !== "admin") {
            SubscriptionService.hasParserSubscription().then(data => {
                setParserSubscription(data.success);
            }).catch(e => {
                toastr.error(t("any_subscription_error"))
                setParserSubscription(true) // Just to hide alert if error on server
            })
            SubscriptionService.hasReporterSubscription().then(data => {
                setReporterSubscription(data.success);
            }).catch(e => {
                toastr.error(t("any_subscription_error"))
                setParserSubscription(true) // Just to hide alert if error on server
            })
        }
    }, []);

    const getAds = () => {
        if (!loading) {
            if((role === "admin" && filter.user_id === null)) return;

            setLoading(true)
            setStarted(true)

            AdsService.getAdByAdUrl(page + 1, sort.field, sort.order, pageSize, filter).then(data => {
                setLoading(false)
                setData(data.data.data);
                setRowCount(data.data.total);
                window.scrollTo(0, 0)
            }).catch(e => {
                setLoading(false)
                window.scrollTo(0, 0)
                console.log(e)
            })
        }
    }

    const setEditableNotes = (id, val) => {
        let copiedData = data.slice()

        copiedData = copiedData.map((item, index) => {
            if (item.id === id) {
                item.is_editable = val
            }

            return item
        });

        setData(copiedData);
    };

    const setNewDataNotesValues = (id, val) => {
        let copiedData = data.slice()

        copiedData = copiedData.map((item, index) => {
            if (item.id === id) {
                item.notes = val
            }

            return item
        });

        setData(copiedData);
    }

    const submitNotes = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const id = Number(formData.get("id"));
        const notes = formData.get("notes");

        AdsService.updateNotes(id, notes, filter.user_id).then(data => {
            setNewDataNotesValues(id, notes)
            setEditableNotes(id, false)
        }).catch(e => {
            setEditableNotes(id, false)
        })
    }

    const handleSortModelChange = (sortModel) => {
        if (sortModel.length === 0) {
            setSort(sortDefault)
        } else {
            setSort({
                field: sortModel[0].field,
                order: sortModel[0].sort
            })
        }
        setPage(0);
    };

    return (
        <div>
            <OtherSubscriptionAlert subscription={parserSubscription} title={t("empty_parser_subscriptions")}/>
            <Typography variant="h5" sx={{marginBottom: 2}}>
                {t("all_sites")}
            </Typography>
            <AdsAdUrlFilter role={role} filter={filter} setFilter={setFilter}/>

            {
                role === "admin" && filter.user_id === null ? <div>
                    <Alert severity={"warning"}>{t("select_user_to_continue")}</Alert>
                </div> : <div style={{width: '100%'}}>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        autoHeight={true}
                        sx={{
                            border: 1,
                            borderColor: grey400,
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                            '& .MuiDataGrid-columnSeparator': {
                                color: grey400,
                            },
                            '& .MuiDataGrid-withBorder': {
                                borderColor: grey400,
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                borderColor: grey400,
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderColor: grey400,
                            },
                        }}
                        disableSelectionOnClick={true}
                        showCellRightBorder={true}
                        rows={data}
                        columns={columns}
                        pagination
                        paginationMode={"server"}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        pageSize={pageSize}
                        onPageSizeChange={(pageSize) => {
                            setPageSize(pageSize)
                            setLastPageSize(pageSize)
                        }}
                        rowCount={rowCount}
                        rowsPerPageOptions={[10, 20, 50]}
                        disableColumnMenu={true}
                        loading={loading || !started}
                        disableColumnSelector={true}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        filterMode="server"
                        initialState={{
                            sorting: {
                                sortModel: [sortDefault],
                            }
                        }}
                    />

                    <KeywordsModal open={keywordsModal}
                                   adUrl={keywordsModalAdUrl}
                                   projectId={keywordsModalProjectId}
                                   inputKeywords={null}
                                   userId={filter.user_id}
                                   onItemSelect={(item, projectId) => {
                                       const href = `/dashboard/${role === "admin" ? "admin/" : ""}ads?project_id=${projectId}&keyword_id=${item.id}`;
                                       navigate(href);
                                   }}
                                   handleClose={() => setKeywordsModal(false)}
                    />

                    <AdsCountModal
                        userId={filter.user_id}
                        open={countModal}
                        adUrl={countModalAdUrl}
                        handleClose={() => setCountModal(false)}
                    />

                    <AdsImpressionModal
                        userId={filter.user_id}
                        open={adImpressionModal}
                        adUrl={adImpressionModalAdUrl}
                        handleClose={() => setAdImpressionModal(false)}
                    />

                    {
                        reporterSubscription ? <AddItemsToReportCampaignByAdUrl
                            addReportItem={addReportItem}
                            open={addReportDialog}
                            handleSuccess={() => setAddReportDialog(false)}
                            handleClose={() => setAddReportDialog(false)}
                        /> : ""
                    }
                </div>
            }
        </div>
    )
}