import {Alert, Box, Button, FormControlLabel, Switch, Tooltip, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataGrid, GridActionsCellItem, ruRU} from "@mui/x-data-grid";
import {grey} from "@mui/material/colors";
import toastr from "toastr";
import {ReporterService} from "../../../services/reporter.service";
import moment from "moment";
import {LinearProgressBarWithLabel} from "../../../components/LinearProgressBarWithLabel";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {CreateReportDialog} from "./CreateReportDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import {AlertDialog} from "../../../components/AlertDialog";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {AddUrlsDialog} from "./AddUrlsDialog";
import {SubscriptionService} from "../../../services/subscription.service";
import {Link} from "react-router-dom";
import {OtherSubscriptionAlert} from "../../../components/subscriptions/OtherSubscriptionAlert";

const grey400 = grey[400];
const grey200 = grey[200];

export const ReportCampaigns = () => {
    const {t} = useTranslation();

    const pageSize = 12;
    const [campaigns, setCampaigns] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [triggerStart, setTriggerStart] = useState(false);

    const [targetCampaign, setTargetCampaign] = useState(null);
    const [createDialog, setCreateDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [addUrlsDialog, setAddUrlsDialog] = useState(false);
    const [addUrlsCampaignId, setAddUrlsCampaignId] = useState(null);

    const [hasSubscription, setHasSubscription] = useState(true);

    const [filter, setFilter] = useState({
        is_finished: false
    });

    const handleDelete = () => {
        if (targetCampaign !== null) {
            ReporterService.delete(targetCampaign).then(data => {
                if (data.success) {
                    toastr.success(t("report_deleted"))
                    setPage(-1);
                } else {
                    toastr.error(t("report_not_deleted"));
                }

                setDeleteDialog(false);
            }).catch(e => {
                setDeleteDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    useEffect(() => {
        setPage(-1);
    }, [filter])

    useEffect(() => {
        if (!hasSubscription) return;

        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ReporterService.get(page + 1, pageSize, filter).then(data => {
                if (data.data) {
                    setCampaigns(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page, hasSubscription]);

    useEffect(() => {
        SubscriptionService.hasReporterSubscription().then(data => {
            setHasSubscription(data.success);
        }).catch(e => {
            toastr.error(t("any_subscription_error"))
            setHasSubscription(true) // Just to hide alert if error on server
        })
    }, []);

    const handleStart = (campaignId, previousValue) => {
        setTriggerStart(true);
        ReporterService.triggerStart(campaignId).then(data => {
            if (data.success) {
                if (previousValue) {
                    toastr.success(t("campaign_stoped"));
                } else {
                    toastr.success(t("campaign_started"));
                }

                setCampaigns(campaigns.map(item => {
                    if (item.id === campaignId) {
                        item.is_started = !previousValue
                    }

                    return item;
                }))
            } else {
                toastr.error(data.message);
            }

            setTriggerStart(false);
        }).catch(e => {
            toastr.error(t("error_loading"))
            setTriggerStart(false);
        })
    }

    const columns = [
        {
            field: 'actions',
            headerName: t("actions"),
            width: 150,
            sortable: false,
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={Boolean(triggerStart || params.row.items_count === 0 || params.row.is_finished) || params.row.is_auto_generated}
                    color={params.row.is_started ? "secondary" : "primary"}
                    icon={params.row.is_started ? <PauseIcon/> : <PlayArrowIcon/>}
                    onClick={() => {
                        if (!params.row.is_auto_generated) {
                            handleStart(params.row.id, params.row.is_started)
                        }
                    }}
                    label={"1"}
                />,
                <GridActionsCellItem
                    disabled={params.row.is_auto_generated}
                    color={"error"}
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        if (!params.row.is_auto_generated) {
                            setDeleteDialog(true);
                            setTargetCampaign(params.row.id)
                        }
                    }}
                    label={"2"}
                />,
                <GridActionsCellItem
                    disabled={Boolean(triggerStart || params.row.is_finished) || params.row.is_auto_generated}
                    color={"info"}
                    icon={<PlaylistAddIcon/>}
                    onClick={() => {
                        if (!params.row.is_auto_generated) {
                            setAddUrlsDialog(true);
                            setAddUrlsCampaignId(params.row.id)
                        }
                    }}
                    label={"2"}
                />
            ]
        },
        {
            field: 'name',
            headerName: t("campaign_name"),
            width: 250,
            sortable: false,
            renderCell: params => {
                if(params.row.is_auto_generated) {
                    return <strong>{t(params.row.name)}</strong>
                } else {
                    return <strong>{params.row.name}</strong>
                }
            }
        },
        {
            field: 'passes',
            headerName: t("campaign_passes"),
            width: 70,
            align: "center",
            sortable: false,
        },
        {
            field: 'second_label',
            headerName: t("report_type"),
            width: 250,
            align: "center",
            sortable: false,
            renderCell: params => {
                return <div>{params.row.second_label < 4 ? params.row.second_label : t("report_type_4")}</div>
            }
        },
        {
            field: 'items_count',
            headerName: t("progress"),
            sortable: false,
            width: 250,
            type: 'actions',
            renderCell: params => {
                const totalPasses = params.row.items_sum_total_passes;
                let value = params.row.items_sum_passes === null ? 0 : Number(params.row.items_sum_passes) / totalPasses * 100;
                value = value > 100 ? 100 : value;

                return <Tooltip
                    title={t("campaign_progress_tooltip", {done: params.row.items_sum_passes || 0, total: totalPasses})}
                    placement="top">
                    <div style={{width: "100%"}}>
                        <LinearProgressBarWithLabel color={params.row.is_finished ? "secondary" : "primary"}
                                                    value={value}/>
                    </div>
                </Tooltip>
            }
        },
        {
            field: 'created_at',
            headerName: t("created_at"),
            width: 140,
            sortable: false,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'updated_at',
            headerName: t("updated_at"),
            width: 140,
            sortable: false,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
    ]

    if (hasSubscription) {
        return (
            <div>
                <Box sx={{
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Typography variant="h5">
                        {t("report_campaigns")}
                    </Typography>

                    <Button
                        startIcon={<AddIcon/>}
                        variant={"contained"}
                        onClick={() => setCreateDialog(true)}
                    >
                        {t("add")}
                    </Button>
                </Box>

                <Box sx={{marginBottom: 2}}>
                    <FormControlLabel
                        control={<Switch
                            onChange={(e) => setFilter({...filter, is_finished: e.target.checked})}
                            checked={filter.is_finished}
                        />}
                        label={t("show_finished_report_campaigns")}
                    />
                </Box>

                <DataGrid
                    getRowHeight={() => 'auto'}
                    autoHeight={true}
                    sx={{
                        border: 1,
                        borderColor: grey400,
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                        '& .MuiDataGrid-columnSeparator': {
                            color: grey400,
                        },
                        '& .MuiDataGrid-withBorder': {
                            borderColor: grey400,
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderColor: grey400,
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderColor: grey400,
                        },
                        '& .report-finished--1': {
                            backgroundColor: grey200,
                            '&:hover': {
                                backgroundColor: grey200,
                            },
                        },
                    }}
                    disableSelectionOnClick={true}
                    showCellRightBorder={true}
                    rows={campaigns}
                    columns={columns}
                    paginationMode={"server"}
                    page={page < 0 ? 0 : page}
                    onPageChange={(newPage) => setPage(newPage)}
                    pageSize={pageSize}
                    rowCount={rowCount}
                    disableColumnMenu={true}
                    loading={loading}
                    disableColumnSelector={true}
                    getRowClassName={(params) => `report-finished--${params.row.is_finished}`}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    filterMode="server"
                />

                <CreateReportDialog open={createDialog}
                                    handleClose={() => setCreateDialog(false)}
                                    showLinks={false}
                                    handleSuccess={(newCampaignId) => {
                                        setPage(-1)
                                        setCreateDialog(false)
                                        setAddUrlsCampaignId(newCampaignId)
                                        setAddUrlsDialog(true)
                                    }}
                />

                <AlertDialog
                    open={deleteDialog}
                    handleClose={() => setDeleteDialog(false)}
                    title={t("report_delete_dialog_title")}
                    content={t("report_delete_dialog_content")}
                    handleCancel={() => setDeleteDialog(false)}
                    handleOk={handleDelete}
                />

                <AddUrlsDialog campaignId={addUrlsCampaignId}
                               open={addUrlsDialog}
                               handleClose={() => {
                                   setPage(-1)
                                   setAddUrlsDialog(false)
                               }}
                />
            </div>
        )
    } else {
        return (
            <OtherSubscriptionAlert
                subscription={hasSubscription}
                title={t("empty_reporter_subscriptions")}/>
        )
    }
}