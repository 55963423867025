import {Alert, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {dateToDefaultFormat, lessThanDays} from "../../../../utils/Date";
import {grey, orange, red} from "@mui/material/colors";
import {CustomColors} from "../../../../themes/custom-colors";

const grey_ = grey[600];
const orange_ = orange[400];
const red_ = red[400]

export const ReporterSubscriptionWidget = (props) => {
    const {t} = useTranslation();

    const {subscription} = props;
    const [attentionColor, setAttentionColor] = useState(grey_);

    useEffect(() => {
        if (!!subscription) {
            if (lessThanDays(subscription.expires_date, 2) || subscription.available_reports < 500) {
                setAttentionColor(red_)
                return;
            }
            if (lessThanDays(subscription.expires_date, 10) || subscription.available_reports < 5000) {
                setAttentionColor(orange_)
                return;
            }


            setAttentionColor(grey_)
        }
    }, [subscription]);

    if (!!subscription) {
        if (subscription.status) {
            return <Alert
                sx={{
                    mr: 1,
                    width: {
                        xs: "100%",
                        sm: "auto"
                    },
                    mb: {
                        xs: 3,
                        sm: 0,
                        md: 0
                    },
                    borderColor: CustomColors.blue
                }}
                icon={false}
                variant={"outlined"}
            >
                <strong style={{color: attentionColor}}>{t("reporter_subscription")}</strong><br/>
                {t("subscription_expires_date")}: <Typography display={"inline"}
                                                              color={attentionColor}>{dateToDefaultFormat(subscription.expires_date)}</Typography>
                <br/>
                {t("reports_available")}: <Typography display={"inline"}
                                                      color={attentionColor}>{subscription.available_reports}</Typography>
            </Alert>
        }
    } else return null
}