import {HeaderSelect} from "../../../autocompletes/HeaderSelect";
import * as React from "react";
import i18n from "../../../../lang/i18n";

export const device = (params, onChange, value) => {
    const values = [
        {
            id: 0,
            name: i18n.t("all_devices")
        },
        {
            id: 1,
            name: i18n.t("desktop")
        },
        {
            id: 2,
            name: "IPhone"
        },
        {
            id: 3,
            name: "Android"
        }
    ];

    return (
        <div>
            <HeaderSelect label={params.colDef.headerName}
                          value={value}
                          values={values}
                          width={100}
                          onChange={onChange}
            />
        </div>
    )
}