// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

import {CustomColors} from "../custom-colors";

const Theme = (colors) => {
    const { blue, red, gold, cyan, green, grey } = colors;
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    };
    const contrastText = '#fff';

    return {
        primary: {
            lighter: "#faba6b",
            100: "#faae52",
            200: "#f9a339",
            light: "#f89720",
            400: "#f78b08",
            main: "#ec8607",
            dark: "#df7e07",
            700: "#c67006",
            darker: "#ad6205",
            900: "#945405",
            contrastText
        },
        secondary: {
            lighter: greyColors[100],
            100: greyColors[100],
            200: greyColors[200],
            light: greyColors[300],
            400: greyColors[400],
            main: greyColors[500],
            600: greyColors[600],
            dark: greyColors[700],
            800: greyColors[800],
            darker: greyColors[900],
            A100: greyColors[0],
            A200: greyColors.A400,
            A300: greyColors.A700,
            contrastText: greyColors[0]
        },
        error: {
            lighter: red[0],
            light: red[2],
            main: red[4],
            dark: red[7],
            darker: red[9],
            contrastText
        },
        warning: {
            lighter: gold[0],
            light: CustomColors.purple,
            main: CustomColors.purple,
            dark: gold[7],
            darker: gold[9],
            contrastText: greyColors[100]
        },
        info: {
            lighter: cyan[0],
            light: cyan[3],
            main: CustomColors.blue,
            dark: cyan[7],
            darker: cyan[9],
            contrastText
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: CustomColors.green,
            dark: green[7],
            darker: green[9],
            contrastText
        },
        purple: {
          main: CustomColors.purple
        },
        grey: greyColors
    };
};

export default Theme;
