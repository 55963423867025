import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Modal,
    OutlinedInput,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ClipboardTextField} from "../../../../../components/ClipboardTextField";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const GeneratedInvoiceModal = (props) => {
    const {t} = useTranslation();

    const [link, setLink] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    useEffect(() => {
        const newInvoiceLink = window.location.protocol + "//" + window.location.host + `/dashboard/invoices/${props.invoiceId}`;
        setLink(newInvoiceLink);
    }, [props.invoiceId]);

    return (
        <Modal
            open={open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" align={"center"} variant="h4" component="h2">
                    {t("generated_invoice_link")}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t("generated_invoice_link_desc")}
                </Typography>

                <ClipboardTextField id={"invoice-link"} label={t("invoice_link")} value={link} />
            </Box>
        </Modal>
    )
}