import {TopBox} from "./TopBox";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AdsService as AnalyticsService} from "../../../../services/analytics.service";
import {getPercentage} from "../../../../utils/Number";
import _ from "underscore";
import {ActiveAdUrlsChart} from "./charts/ActiveAdUrlsChart";

export const ActiveAdUrlsBox = (props) => {
    const {t} = useTranslation();

    const {filter} = props;

    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);

    const [adsToday, setAdsToday] = useState({
        count: 0,
        trend: "up",
        percent: ""
    });

    useEffect(() => {
        setLoading(true);
        AnalyticsService.getActiveAdUrls(filter).then(data => {
            if (data.data) {
                const ads = _.sortBy(data.data, "date");
                setAds(ads)

                const adsCount = data.data.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue.count
                }, 0);
                const todayAds = ads[ads.length - 1]
                const todayAdsCount = todayAds !== undefined ? todayAds.count : 0;

                const adsAvg = adsCount / data.data.length;
                const newAdsPercent = getPercentage(todayAdsCount, adsAvg);
                const adsPercentFormatted = (newAdsPercent > 0 ? newAdsPercent : "-" + (newAdsPercent * -1)) + "%";

                setAdsToday({
                    count: adsCount,
                    trend: newAdsPercent > 0 ? "up" : "down",
                    percent: adsPercentFormatted
                });
            }

            setLoading(false)
        }).catch(e => {
            setLoading(false);
        });
    }, [filter])

    return (
        <TopBox
            title={t('active_domains')}
            count={adsToday.count}
            chipValue={adsToday.percent}
            trend={adsToday.trend}
            loading={loading}
        >
            <ActiveAdUrlsChart ads={ads} loading={loading}/>
        </TopBox>
    )
}