import {Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

export const ParserCustomEditFields = (props) => {
    const {t} = useTranslation();
    const {values, handleBlur, handleChange, touched, errors} = props;

    return <>
        <Grid item xs={12} md={6}>
            <TextField fullWidth
                       name="hour_interval"
                       value={values.hour_interval}
                       label={`${t("parser_frequency")} ${t("К-во раз в день")}`}
                       type={"number"}
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       error={Boolean(touched.hour_interval && errors.hour_interval)}
                       helperText={Boolean(touched.hour_interval && errors.hour_interval) ? errors.hour_interval : ""}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField fullWidth
                       name="keywords_count"
                       value={values.keywords_count}
                       label={t("keywords_count")}
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       error={Boolean(touched.keywords_count && errors.keywords_count)}
                       helperText={Boolean(touched.keywords_count && errors.keywords_count) ? errors.keywords_count : ""}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField fullWidth
                       name="cost"
                       type={"number"}
                       value={values.cost}
                       label={t("subscription_custom_price")}
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       error={Boolean(touched.cost && errors.cost)}
                       helperText={Boolean(touched.cost && errors.cost) ? errors.v : ""}
            />
        </Grid>
    </>
}