import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ProxyService} from "../../services/proxy.service";
import {CountryService} from "../../services/country.service";

export const CountrySelector = (props) => {
    const {t} = useTranslation();
    const {value, setValue} = props;
    const [data, setData] = useState([]);

    const allItems = [
        {
            code: null,
            label: t("all_countries")
        }
    ];


    useEffect(() => {
        CountryService.get().then(data => {
            if (data.data) {
                setData(Object.keys(data.data).map(item => {
                    return {
                        code: item.toLowerCase(),
                        label: data.data[item]
                    }
                }))
            }
        }).catch(e => {
            toastr.error(t("error_loading"))
        })
    }, []);

    return <Autocomplete
        disablePortal={false}
        options={data}
        getOptionLabel={option => option.label}
        freeSolo
        onChange={setValue}
        onClick={e => e.stopPropagation()}
        renderInput={(inputParams) =>
            <TextField {...inputParams}
                       fullWidth
                       size={"small"}
                       onClick={e => e.stopPropagation()}
                       variant={"outlined"}
                       label={t("countries")}
            />
        }
    />
}