import {Box, Button, Grid, TextField} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {UserSelector} from "../../../components/autocompletes/UserSelector";

export const ProjectFilter = (props) => {
    const {t} = useTranslation();

    const {handleSubmit} = props;

    const [values, setValues] = useState({
        name: "",
        user_id: null
    });

    return (
        <Box sx={{my: 5}} component="form">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth={true}
                        value={values.name}
                        onChange={(e) => setValues({...values, name: e.target.value})}
                        label={t("project_name")}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <UserSelector
                        freeSolo={false}
                        userType={"user"}
                        value={values.user_id}
                        excludeAllUsers={true}
                        setValue={(event, value) => {
                            setValues({...values, user_id: value === null ? null : value.id})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button type="button"
                            variant={"contained"}
                            color={"info"}
                            size={"large"}
                            onClick={(e) => handleSubmit(e, values)}
                    >
                        {t("show")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}