import {useTranslation} from "react-i18next";
import {Box, Button, Dialog, DialogTitle, Grid, TextField} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {ReportCampaignSelector} from "../../../components/autocompletes/ReportCampaignSelector";
import {DateRangePickerCustom} from "../../../components/DateRangePickerCustom";
import * as React from "react";
import {useEffect, useState} from "react";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../utils/Date";
import {ReporterService} from "../../../services/reporter.service";
import {CreateReportDialog} from "../reports/CreateReportDialog";
import moment from "moment";

export const AddItemsToReportCampaignByAdUrl = (props) => {
    const {handleClose, handleSuccess, open, addReportItem} = props;
    const {t} = useTranslation();

    const globalDatePickerStartDate = getDatePickerStartDate();
    const globalDatePickerEndDate = getDatePickerEndDate();

    const [formData, setFormData] = useState({
        campaignId: null,
        startDate: globalDatePickerStartDate,
        endDate: globalDatePickerEndDate,
        minDate: globalDatePickerStartDate,
        maxDate: globalDatePickerEndDate
    })

    const [createCampaign, setCreateCampaign] = useState(false);
    const [triggerCampaignSelector, setTriggerCampaignSelector] = useState(false);

    useEffect(() => {
        if(addReportItem !== null) {
            const momentFirstSeen = moment(addReportItem.first_seen_at);
            const momentLastSeen = moment(addReportItem.lastt_seen_at);
            const startDateValue = moment(globalDatePickerStartDate).isBetween(momentFirstSeen, momentLastSeen) ? globalDatePickerStartDate : addReportItem.first_seen_at;
            const endDateValue = moment(globalDatePickerStartDate).isBetween(momentFirstSeen, momentLastSeen) ? globalDatePickerEndDate : addReportItem.last_seen_at;

            setFormData({
                ...formData,
                campaignId: null,
                minDate: addReportItem.first_seen_at,
                maxDate: addReportItem.last_seen_at,
                startDate: startDateValue,
                endDate: endDateValue,
            })
        }
    }, [addReportItem])

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("add_items_to_report_campaign_by_ad_url")}</DialogTitle>

        <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={Yup.object().shape({
                campaignId: Yup.object().required(t('required')),
                startDate: Yup.date().required(t('required')),
                endDate: Yup.date().required(t('required')),
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);
                const payload = {
                    ad_url: addReportItem.ad_url,
                    start_date: values.startDate,
                    end_date: values.endDate
                };

                ReporterService.addToCampaign(values.campaignId.id, payload).then(data => {
                    if (data.success) {
                        toastr.success(t("items_added_to_report", {count: data.data}));
                        handleSuccess();
                    } else {
                        toastr.error(t("error_server"));
                    }

                    setSubmitting(false);
                }).catch(e => {
                    setSubmitting(false);
                    toastr.error(t("error_server"));
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => {
                return (
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{flexGrow: 1, padding: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth
                                               name="ad_url"
                                               value={addReportItem.ad_url}
                                               label={t("site")}
                                               variant="outlined"
                                               disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <ReportCampaignSelector variant={"outlined"}
                                                            trigger={triggerCampaignSelector}
                                                            error={errors.campaignId && touched.campaignId? t("error_select_campaign") : null}
                                                            setValue={(e, value) => setFieldValue("campaignId", value)}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <Button color={"primary"}
                                            onClick={() => setCreateCampaign(true)}
                                            variant={"contained"}
                                    >
                                        {t("add_campaign")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <DateRangePickerCustom
                                        startDate={values.startDate}
                                        endDate={values.endDate}
                                        minDate={formData.minDate}
                                        maxDate={formData.maxDate}
                                        onApply={(startDate, endDate) => {
                                            setFieldValue("startDate", startDate)
                                            setFieldValue("endDate", endDate)
                                        }}
                                        error={Boolean(touched.startDate && errors.startDate) || Boolean(touched.endDate && errors.endDate) ? t("choose_date") : null}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button disableElevation
                                            disabled={isSubmitting}
                                            variant={"outlined"}
                                            type={"submit"}
                                    >
                                        {t("add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                )
            }}
        </Formik>

        <CreateReportDialog open={createCampaign}
                            handleClose={() => setCreateCampaign(false)}
                            showLinks={false}
                            handleSuccess={() => {
                                setCreateCampaign(false)
                                setTriggerCampaignSelector(!triggerCampaignSelector)
                            }}
        />
    </Dialog>)
}