import {Autocomplete, Box, Button, Dialog, DialogTitle, Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import {Formik} from 'formik';
import toastr from "toastr";
import {KeywordsService} from "../../../../services/keywords.service";
import {deviceTypes} from "../../../../utils/Constants";
import _ from "underscore";

export const CreateDialog = (props) => {
    const {handleClose, open, countries, projectId} = props;
    const {t} = useTranslation();

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("create_keyword")}</DialogTitle>

        <Formik
            initialValues={{
                name: '',
                types: [],
                country: [],
                submit: null
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(t('required')),
                types: Yup.array().min(1, t('required')),
                country: Yup.array().min(1, t('required'))
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);
                const payload = {
                    keywords: values.name.split("\n"),
                    country: values.country.map(item => item.code),
                    devices: values.types.map(item => item.id),
                    project_id: projectId
                };

                KeywordsService.createKeyword(payload).then(data => {
                    if(data.success) {
                        setFieldValue("name", "")
                        toastr.success(t("keyword_created"))
                    } else {
                        if(_.has(data.data, "keywords_limit")) {
                            toastr.warning(data.data.keywords_limit)
                        }
                        setErrors(data.data)
                    }

                    setSubmitting(false)
                }).catch(e => {
                    toastr.error(t("error_server"));
                    setSubmitting(false)
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{flexGrow: 1, padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth
                                           multiline={true}
                                           rows={10}
                                           name="name"
                                           value={values.name}
                                           label={t("keyword")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    value={values.types}
                                    options={deviceTypes}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name}
                                    onChange={((event, value) => setFieldValue("types", value))}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                        error={Boolean(touched.types && errors.types)}
                                        helperText={Boolean(touched.types && errors.types) ? errors.types : ""}
                                        variant="outlined"
                                        label={t("devices")}
                                    />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    value={values.country}
                                    options={countries}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    getOptionLabel={(option) => option.label}
                                    onChange={((event, value) => setFieldValue("country", value))}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={Boolean(touched.country && errors.country) ? errors.country : ""}
                                        variant="outlined"
                                        label={t("country")}
                                    />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button disableElevation
                                        disabled={isSubmitting}
                                        variant={"outlined"}
                                        type={"submit"}
                                >
                                    {t("create")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        </Formik>
    </Dialog>)
}