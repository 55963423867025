// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
    const theme = useTheme();
    return (
        <Box sx={{ position: 'absolute', filter: 'blur(18px)', zIndex: -1, bottom: 180 }}>
            {/*<svg width="100%" height="calc(100vh - 175px)" viewBox="0 0 405 809" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <path*/}
            {/*        d="M-358.39 358.707L-293.914 294.23L-293.846 294.163H-172.545L-220.81 342.428L-233.272 354.889L-282.697 404.314L-276.575 410.453L0.316589 687.328L283.33 404.314L233.888 354.889L230.407 351.391L173.178 294.163H294.48L294.547 294.23L345.082 344.765L404.631 404.314L0.316589 808.629L-403.998 404.314L-358.39 358.707ZM0.316589 0L233.938 233.622H112.637L0.316589 121.301L-112.004 233.622H-233.305L0.316589 0Z"*/}
            {/*        fill={theme.palette.primary.light}*/}
            {/*    />*/}
            {/*    <path*/}
            {/*        d="M-516.39 358.707L-451.914 294.23L-451.846 294.163H-330.545L-378.81 342.428L-391.272 354.889L-440.697 404.314L-434.575 410.453L-157.683 687.328L125.33 404.314L75.8879 354.889L72.4068 351.391L15.1785 294.163H136.48L136.547 294.23L187.082 344.765L246.631 404.314L-157.683 808.629L-561.998 404.314L-516.39 358.707ZM-157.683 0L75.9383 233.622H-45.3627L-157.683 121.301L-270.004 233.622H-391.305L-157.683 0Z"*/}
            {/*        fill={theme.palette.success.light}*/}
            {/*        opacity="0.6"*/}
            {/*    />*/}
            {/*    <path*/}
            {/*        d="M-647.386 358.707L-582.91 294.23L-582.842 294.163H-461.541L-509.806 342.428L-522.268 354.889L-571.693 404.314L-565.571 410.453L-288.68 687.328L-5.66624 404.314L-55.1082 354.889L-58.5893 351.391L-115.818 294.163H5.48342L5.5507 294.23L56.0858 344.765L115.635 404.314L-288.68 808.629L-692.994 404.314L-647.386 358.707ZM-288.68 0L-55.0578 233.622H-176.359L-288.68 121.301L-401 233.622H-522.301L-288.68 0Z"*/}
            {/*        fill={theme.palette.error.lighter}*/}
            {/*        opacity="1"*/}
            {/*    />*/}
            {/*</svg>*/}
            {/*<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="calc(100vh - 300px)" viewBox="0 0 199 224" fill="none">*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M105 4C101.388 2.989 97.721 2.822 94 3.5C93.444 3.883 93.111 4.383 93 5H91C91.79 3.706 92.79 2.54 94 1.5C96.724 1.23 99.391 0.73 102 0C103.788 0.783 104.788 2.116 105 4Z" fill="#FBF3D4"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M74 5H63C64.194 3.723 65.86 3.056 68 3L73 3.5C73.556 3.883 73.889 4.383 74 5Z" fill="#C8B05F"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M105 4C105.989 5.34136 106.989 6.67474 108 8C108.35 12.53 107.683 16.864 106 21H105C105.658 18.345 106.325 15.678 107 13C105.989 10.3374 104.989 7.67073 104 5C100.316 3.772 96.65 3.772 93 5C93.111 4.383 93.444 3.883 94 3.5C97.721 2.822 101.388 2.989 105 4Z" fill="#D3A04A"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M61 4.99999C55.89 11.215 55.89 17.548 61 24C59.356 24.214 57.856 23.88 56.5 23C54.111 16.833 54.611 11 58 5.49999C58.9224 5.02146 59.9723 4.84647 61 4.99999Z" fill="#FBF2CD"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M89 8C87.682 12.436 88.015 16.769 90 21C89.003 21.03 88.336 20.53 88 19.5C86.345 15.232 86.679 11.398 89 8Z" fill="#F4DA9C"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M49 17C47.6121 15.5955 45.8924 14.5637 44 14C37.77 11.719 32.436 13.052 28 18C29.416 13.368 32.583 11.035 37.5 11C40.789 11.114 43.956 11.78 47 13C48.205 14.077 48.872 15.41 49 17Z" fill="#FEF4D2"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M61 5H63C61.6754 8.00385 60.342 11.0039 59 14C59.331 18.324 60.997 21.991 64 25H63L61 24C55.89 17.548 55.89 11.215 61 5Z" fill="#D49F3D"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M108 8C109.287 9.371 109.953 11.205 110 13.5L109.5 19C108.79 20.473 107.624 21.14 106 21C107.683 16.864 108.35 12.53 108 8Z" fill="#F9EABF"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M105 21C99.967 24.992 94.967 24.992 90 21C88.015 16.769 87.682 12.436 89 7.99999C88.927 6.47299 89.594 5.47299 91 4.99999H93C96.65 3.77199 100.316 3.77199 104 4.99999C104.985 7.65999 105.985 10.326 107 13C106.325 15.678 105.658 18.345 105 21Z" fill="#E29516"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M133 13C132.973 15.73 133.64 18.23 135 20.5C134.425 22.6226 134.09 24.8028 134 27H133C120.493 29.484 116.827 24.817 122 13C125.655 11.914 129.322 11.914 133 13Z" fill="#E4940F"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M133 13C133.996 12.914 134.829 13.248 135.5 14C137.805 18.805 137.305 23.138 134 27C134.09 24.804 134.423 22.637 135 20.5C133.64 18.23 132.973 15.73 133 13Z" fill="#D09C3E"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M133 13V12C134.728 11.8504 136.463 12.1974 138 13C141.15 21.414 138.817 27.58 131 31.5C126.99 31.857 122.99 31.69 119 31V26C122.143 29.74 126.143 30.907 131 29.5C132.256 29.083 132.923 28.25 133 27H134C137.305 23.138 137.805 18.805 135.5 14C134.829 13.248 133.996 12.914 133 13Z" fill="#FCF1CF"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M133 12V13C129.322 11.914 125.655 11.914 122 13C116.827 24.817 120.493 29.484 133 27C132.923 28.25 132.256 29.083 131 29.5C126.143 30.907 122.143 29.74 119 26C118.383 25.89 117.883 25.556 117.5 25C116.833 21.7 116.833 18.3 117.5 15C119.612 13.8778 121.482 12.3484 123 10.5C126.603 9.657 129.936 10.157 133 12Z" fill="#D4A246"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M63 5H74C76 5 77 6 77 8C81.504 20.702 77.17 26.368 64 25C60.997 21.991 59.331 18.324 59 14C60.342 11.0039 61.6753 8.00385 63 5Z" fill="#E29515"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M44 14C33.444 12.761 28.944 17.428 30.5 28C34.281 35.127 39.781 36.794 47 33C45.647 34.026 44.647 35.36 44 37H35L27 29C26.9208 25.3075 27.2562 21.6177 28 18C32.436 13.052 37.77 11.719 44 14Z" fill="#D9A242"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M77 8C77.958 8.453 78.792 9.12 79.5 10C81.688 24.334 76.188 29.334 63 25H64C77.17 26.368 81.504 20.702 77 8Z" fill="#FBEABC"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M49 17C50.208 17.734 51.541 18.067 53 18C53.33 23.0485 52.8232 28.1168 51.5 33C49.785 35.897 47.285 37.23 44 37C44.647 35.36 45.647 34.026 47 33C51.486 28.255 52.153 22.921 49 17Z" fill="#F9E8C1"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M44 14C45.8924 14.5637 47.6121 15.5955 49 17C52.153 22.921 51.486 28.255 47 33C39.781 36.794 34.281 35.127 30.5 28C28.944 17.428 33.444 12.761 44 14Z" fill="#E29511"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M7 32C5.532 33.28 4.865 34.946 5 37H2C1.43 42.682 2.43 48.015 5 53C3.678 53.17 2.511 52.837 1.5 52C-0.178997 45.73 -0.178997 39.397 1.5 33C3.199 32.094 5.033 31.76 7 32Z" fill="#F9F0CE"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M27 29L35 37C34.004 36.914 33.171 37.248 32.5 38C31.79 36.527 30.624 35.86 29 36C29.3283 40.6905 28.9917 45.4038 28 50C26.283 51.169 25.616 52.835 26 55H21C22.6156 52.19 24.4549 49.5147 26.5 47C27.377 33.976 21.21 28.642 8 31C8.847 29.534 10.18 28.7 12 28.5C15.2064 28.2964 18.3868 27.7942 21.5 27C22.436 30.268 24.436 32.601 27.5 34C27.884 32.303 27.718 30.637 27 29Z" fill="#FDF5DF"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M149 28C155.313 27.3 159.313 29.8 161 35.5C161.195 37.852 160.528 39.852 159 41.5C155.812 42.816 152.478 43.316 149 43C143.734 38.158 143.734 33.158 149 28Z" fill="#E39416"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M149 28C161.889 25.89 165.889 31.056 161 43.5C157.568 45.962 153.901 46.462 150 45C149.202 44.543 148.869 43.876 149 43C152.478 43.316 155.812 42.816 159 41.5C160.528 39.852 161.195 37.852 161 35.5C159.313 29.8 155.313 27.3 149 28Z" fill="#D6A74D"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M7.99999 31C21.21 28.642 27.377 33.976 26.5 47C24.4548 49.5147 22.6156 52.19 21 55C15.298 56.738 9.96499 56.071 4.99999 53C2.42999 48.015 1.42999 42.682 1.99999 37H4.99999C4.86499 34.946 5.53199 33.28 6.99999 32C7.66699 32 7.99999 31.667 7.99999 31Z" fill="#E29519"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M81 52C84.194 51.676 85.194 52.842 84 55.5C82.7267 55.9875 81.3541 56.1591 80 56C79.933 54.541 80.266 53.208 81 52Z" fill="#B8464B"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M99 53H102C102.262 54.478 101.929 55.811 101 57C99.389 56.18 98.722 54.846 99 53Z" fill="#A5525E"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M62 56C65.638 55.237 67.138 56.57 66.5 60C62.831 61.51 61.331 60.176 62 56Z" fill="#B43A3A"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M117 59C120.81 60.254 120.81 61.254 117 62V59Z" fill="#C0808D"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M183 56C183.164 58.6771 182.997 61.3642 182.5 64C181.412 65.0878 180.242 66.0908 179 67C171.151 66.818 167.485 62.818 168 55C173.242 49.382 178.242 49.716 183 56Z" fill="#E19616"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M168 55C167.485 62.818 171.151 66.818 179 67C174.479 68.98 170.813 67.98 168 64C165.333 61 165.333 58 168 55Z" fill="#D8A957"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M183 56C185.308 66.523 180.975 70.69 170 68.5C168.393 67.451 167.727 65.951 168 64C170.813 67.98 174.479 68.98 179 67C180.242 66.0908 181.412 65.0878 182.5 64C182.997 61.354 183.164 58.687 183 56Z" fill="#FCE6BF"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M46 65H52C52.484 68.067 51.318 70.067 48.5 71C45.17 70.009 44.336 68.009 46 65Z" fill="#BE4D51"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M47 66H50V69C46.933 69.38 45.933 68.38 47 66Z" fill="#D2111A"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M185 80C188.825 78.753 192.658 79.086 196.5 81C197.167 84.3 197.167 87.7 196.5 91C195.792 91.881 194.958 92.547 194 93C198.052 83.84 195.052 79.508 185 80Z" fill="#FBE9BF"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M32 79C36.979 78.15 38.979 79.983 38 84.5C36.0409 84.9943 34.0139 85.1632 32 85V79Z" fill="#C62E2E"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M185 80C195.052 79.508 198.052 83.84 194 93C183.177 97.013 179.01 93.346 181.5 82C182.59 81.2078 183.764 80.5371 185 80Z" fill="#DA9B35"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M184 82H188C187.653 84.3169 187.485 86.6572 187.5 89C188.683 90.231 189.849 90.231 191 89C190.263 87.8011 189.914 86.4044 190 85H193C194.235 93.024 191.402 95.024 184.5 91C184.002 88.0276 183.835 85.0093 184 82Z" fill="#E7930B"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M199 112C194.173 109.115 189.673 109.449 185.5 113C183.492 120.999 186.326 124.332 194 123C195.679 123.215 196.679 122.548 197 121H199C198.266 122.208 197.933 123.541 198 125C186.733 127.784 181.9 123.451 183.5 112C185.641 111.408 187.557 110.189 189 108.5C192.342 108.329 195.692 108.496 199 109V112Z" fill="#FDF5D7"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M199 112V121H197V113C193.174 111.037 190.007 111.704 187.5 115C186.762 117.712 187.429 120.045 189.5 122C190.856 122.88 192.356 123.214 194 123C186.326 124.332 183.492 120.999 185.5 113C189.673 109.449 194.173 109.115 199 112Z" fill="#D49E45"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M22 113H23C28.335 112.976 30.001 115.31 28 120C27.538 121.38 26.538 122.047 25 122C23.6687 121.659 22.3353 121.325 21 121L22 113Z" fill="#A53935"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M28 120H23C22.331 115.824 23.831 114.491 27.5 116C27.9877 117.273 28.1593 118.646 28 120Z" fill="#D81219"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M197 121C196.679 122.548 195.679 123.215 194 123C192.356 123.214 190.856 122.88 189.5 122C187.429 120.045 186.762 117.712 187.5 115C190.007 111.704 193.174 111.037 197 113V121Z" fill="#E39414"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M30 140H29C26.802 140.38 25.135 139.713 24 138C22.689 135.94 22.689 133.774 24 131.5C31.817 130.307 33.817 133.141 30 140Z" fill="#C53538"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M24 138C23.988 134.054 25.988 132.387 30 133V138H24Z" fill="#E00D14"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M191 141C194.958 144.759 194.958 148.592 191 152.5C182.934 153.258 180.267 149.758 183 142C185.493 139.916 188.16 139.583 191 141Z" fill="#D59934"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M37 148H33C32.3363 150.271 32.1658 152.658 32.5 155C34.167 156.333 35.833 156.333 37.5 155C37.995 153.027 38.162 151.027 38 149C38.992 148.828 39.658 149.162 40 150C40.667 155.333 38.333 157.667 33 157C29.325 154.545 28.991 151.545 32 148C33.799 146.712 35.465 146.712 37 148Z" fill="#C23232"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M37 148C37 148.667 37.333 149 38 149C38.1629 151.014 37.994 153.041 37.5 155C35.833 156.333 34.167 156.333 32.5 155C32.1658 152.658 32.3363 150.271 33 148H37Z" fill="#DB1014"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M136 155C133.627 154.944 131.262 155.281 129 156C129.812 154.507 131.145 153.84 133 154C134.226 154.02 135.226 154.353 136 155Z" fill="#F2A6A6"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M191 141C192.544 141.203 193.878 141.87 195 143C196.152 145.606 196.318 148.273 195.5 151C191.827 154.128 188.994 157.461 187 161C184.436 159.481 184.436 157.981 187 156.5C185.717 154.864 183.976 153.646 182 153L181 147.5C181.047 145.205 181.713 143.371 183 142C180.267 149.758 182.934 153.258 191 152.5C194.958 148.592 194.958 144.759 191 141Z" fill="#FDF5E0"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M186 142C190.952 142.596 192.619 145.262 191 150C189.155 150.957 187.055 151.307 185 151C185.844 147.955 186.177 144.955 186 142Z" fill="#E8930C"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M53 165C50.6465 164.827 48.3077 164.493 46 164C45.325 169.782 47.659 171.449 53 169C52.667 170 52 170.667 51 171C44.629 171.588 42.629 168.921 45 163C46.993 160.542 49.326 160.375 52 162.5C52.752 163.171 53.086 164.004 53 165Z" fill="#CD2422"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M136 155C137.591 155.719 139.091 156.719 140.5 158C141.247 161.424 141.08 164.757 140 168C134.918 171.15 130.418 170.483 126.5 166C126.008 164.382 125.838 162.683 126 161C125.81 158.541 126.81 156.874 129 156C131.262 155.281 133.627 154.944 136 155Z" fill="#C43031"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M132 157C139.293 156.284 141.626 159.284 139 166C130.876 170.352 127.043 168.019 127.5 159C129.043 158.436 130.547 157.768 132 157Z" fill="#E30D0D"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M45 163C42.629 168.921 44.629 171.588 51 171C48.831 173.568 46.498 173.568 44 171C42.311 167.85 42.644 165.183 45 163Z" fill="#C35150"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M53 165C54.333 166.333 54.333 167.667 53 169C47.659 171.449 45.325 169.782 46 164C48.306 164.493 50.639 164.826 53 165Z" fill="#DE1E18"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M126 161C125.838 162.683 126.008 164.382 126.5 166C130.418 170.483 134.918 171.15 140 168C136.159 171.586 131.826 171.919 127 169C126.277 172.99 125.61 176.991 125 181C121.52 182.306 118.187 182.306 115 181C118.053 181.389 120.72 180.723 123 179C123.617 178.889 124.117 178.556 124.5 178C126.52 168.796 123.02 165.462 114 168C116.885 164.565 120.218 164.232 124 167C124.629 164.988 125.296 162.987 126 161Z" fill="#F7CBCB"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M177 179C171.171 179.5 168.837 176.834 170 171C173.666 168.161 177.166 168.495 180.5 172C181.813 175.705 180.646 178.038 177 179Z" fill="#D29D36"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M69 172V179C69 180.333 68.333 181 67 181H60V179C59.674 172.674 62.674 170.341 69 172Z" fill="#DE1214"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M123 179C120.72 180.723 118.053 181.389 115 181C111.558 178.172 110.391 174.505 111.5 170C112.208 169.119 113.042 168.453 114 168C123.02 165.462 126.52 168.796 124.5 178C124.117 178.556 123.617 178.889 123 179Z" fill="#C52B28"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M69 172C62.674 170.341 59.674 172.674 60 179C59.039 177.235 58.705 175.235 59 173C62.052 169.521 65.386 169.187 69 172Z" fill="#BA3E3F"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M171 171C176.407 169.68 179.074 171.68 179 177H172C171.987 174.96 171.65 172.934 171 171Z" fill="#E3930F"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M69 173C71.667 175 71.667 177 69 179V173Z" fill="#D56571"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M123 179C119.963 179.321 116.892 178.98 114 178C112.193 170.899 114.86 168.233 122 170C122.907 170.817 123.595 171.848 124 173C123.156 174.881 122.812 176.947 123 179Z" fill="#E20F0F"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M170 171C168.837 176.834 171.171 179.5 177 179C169.823 180.817 167.489 178.15 170 171Z" fill="#EACE99"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M59 173C58.705 175.235 59.039 177.235 60 179V181C58.474 180.008 57.807 178.508 58 176.5C58.014 175.115 58.348 173.949 59 173Z" fill="#F1A9A8"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M61 181H67C66.53 182.193 65.53 182.86 64 183C62.47 182.86 61.47 182.193 61 181Z" fill="#CD7880"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M87 185C78.968 188.827 75.468 186.161 76.5 177C82.407 172.245 86.573 173.412 89 180.5C89.193 182.508 88.526 184.008 87 185Z" fill="#BD3635"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M87 185C84.3229 185.164 81.6358 184.997 79 184.5C76.155 181.676 76.488 179.343 80 177.5C86.418 175.893 88.751 178.393 87 185Z" fill="#E30F0A"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M106 184C103.642 186.736 100.642 187.57 97 186.5C94.235 183.505 93.402 180.005 94.5 176C97.612 172.777 101.112 172.277 105 174.5C107.193 177.538 107.527 180.704 106 184Z" fill="#C23433"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M106 184C98.077 186.928 94.744 184.261 96 176C103.734 173.063 107.067 175.729 106 184Z" fill="#E10F0F"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M152 192C158.599 192.553 160.266 195.553 157 201C156.538 202.38 155.538 203.047 154 203C152.235 202.612 150.546 201.936 149 201C148.837 198.643 149.003 196.31 149.5 194C150.208 193.119 151.042 192.453 152 192Z" fill="#DAAD5C"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M157 201H151C149.992 195.833 151.825 194.166 156.5 196C156.992 197.618 157.162 199.317 157 201Z" fill="#DE9614"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M152 192C157.124 190.565 160.124 192.732 161 198.5C159.217 203.286 155.883 204.953 151 203.5C149.744 203.083 149.077 202.25 149 201C150.533 201.931 152.2 202.598 154 203C155.538 203.047 156.538 202.38 157 201C160.266 195.553 158.599 192.553 152 192Z" fill="#FDF1CD"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M38 207C41 206.667 42.333 208 42 211H37C36.933 209.541 37.266 208.208 38 207Z" fill="#CAA360"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M131 210C131.295 212.235 130.961 214.235 130 216C128.235 216.961 126.235 217.295 124 217C122 210.333 124.333 208 131 210Z" fill="#CCA153"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M130 216C126 216.667 124.333 215 125 211C129 210.333 130.667 212 130 216Z" fill="#E19415"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M131 210C132.193 210.47 132.86 211.47 133 213C132.925 216.574 131.092 218.241 127.5 218C126.115 217.986 124.949 217.652 124 217C126.235 217.295 128.235 216.961 130 216C130.961 214.235 131.295 212.235 131 210Z" fill="#F3DEAC"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M66 216C70.659 215.515 71.992 217.348 70 221.5C65.228 222.31 63.895 220.477 66 216Z" fill="#CEA857"/>*/}
            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M95 217H101V224H95V217Z" fill="#DAA048"/>*/}
            {/*</svg>*/}

            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="calc(100vh - 400px)" viewBox="0 0 89 106" fill="none">
                <g clipPath="url(#clip0_9_120)">
                    <path d="M88.0034 29.7279V56.3726C85.7511 53.1952 82.1974 51.1352 78.2058 51.1352C74.2142 51.1352 70.6605 53.1952 68.4082 56.3726V29.7279C68.4082 23.9116 72.7877 19.1858 78.2058 19.1858C80.9211 19.1858 83.3611 20.3706 85.1379 22.269C86.9023 24.1808 88.0034 26.8197 88.0034 29.7279Z" fill="#FFBB24"/>
                    <path d="M88.0029 95.4579V64.3567C88.0029 58.5344 83.6164 53.8146 78.2053 53.8146H78.1928C72.7818 53.8146 68.3952 58.5344 68.3952 64.3567V95.4579C68.3952 101.28 72.7818 106 78.1928 106H78.2053C83.6164 106 88.0029 101.28 88.0029 95.4579Z" fill="#1A1A1A"/>
                    <path d="M53.805 10.5421V53.0067C51.5526 49.8293 47.999 47.7693 44.0074 47.7693C40.0158 47.7693 36.4496 49.8293 34.1973 53.0067V10.5421C34.1973 4.72577 38.5893 0 44.0074 0C46.7102 0 49.1627 1.18481 50.9395 3.09666C52.7163 5.00851 53.805 7.63394 53.805 10.5421Z" fill="#1A1A1A"/>
                    <path d="M34.1973 76.272V61.0041C34.1973 55.1743 38.5893 50.462 43.9949 50.462C49.4129 50.462 53.7925 55.1878 53.7925 61.0041V76.272C53.7925 82.1018 49.4004 86.8141 43.9949 86.8141C38.5768 86.8141 34.1973 82.0884 34.1973 76.272Z" fill="#FFBB24"/>
                    <path d="M19.6077 72.6368V95.4579C19.6077 101.288 15.2157 106 9.7976 106C7.09481 106 4.64228 104.829 2.86545 102.917C1.10113 101.005 0 98.366 0 95.4579V72.6503C2.25232 75.8277 5.80598 77.8877 9.7976 77.8877C13.7892 77.8877 17.3554 75.8277 19.6077 72.6368Z" fill="#1A1A1A"/>
                    <path d="M0 64.6528V29.7279C0 23.8981 4.39203 19.1858 9.7976 19.1858C15.2157 19.1858 19.5952 23.9116 19.5952 29.7279V64.6394C19.5952 70.4692 15.2032 75.1815 9.7976 75.1815C4.37951 75.1815 0 70.4557 0 64.6394V64.6528Z" fill="#FFBB24"/>
                </g>
                <defs>
                    <clipPath id="clip0_9_120">
                        <rect width="88.0032" height="106" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </Box>
    );
};

export default AuthBackground;
