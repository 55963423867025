import axios from "axios";
import * as qs from "query-string";

export class ProjectsService {
    static get(page = 1, perPage = null, withTrashed = false, name = null, userId = null) {
        const filterQuery = qs.stringify({
            page,
            perPage,
            withTrashed: Boolean(withTrashed),
            name,
            user_id: userId
        });

        return axios.get(`/dashboard/projects?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getById(id) {
        return axios.get(`/dashboard/projects/${id}`).then((response) => {
            return response.data;
        });
    }

    static create(payload) {
        return axios.post(`/dashboard/projects`, payload).then((response) => {
            return response.data;
        });
    }

    static update(id, payload) {
        return axios.post(`/dashboard/projects/${id}`, payload).then((response) => {
            return response.data;
        });
    }

    static deleteProject(id) {
        return axios.post(`/dashboard/projects/delete/${id}`).then((response) => {
            return response.data;
        });
    }

    static getAll() {
        return axios.get(`/dashboard/project/get/all`).then((response) => {
            return response.data;
        });
    }
}