import axios from "axios";
import * as qs from "query-string";

export class KeywordsService {
    static getByAdUrl(adUrl, projectId, userId) {
        return axios.get(`/dashboard/keywords/ad-url?ad_url=${adUrl}&project_id=${projectId}&user_id=${userId}`).then((response) => {
            return response.data;
        });
    }

    static getByProjectId(projectId, page = null, perPage = null, search = null, withKeywordTypes = false) {
        let filter = "";

        if (page !== null && perPage !== null) {
            filter = `page=${page}&perPage=${perPage}&`
        }

        if (withKeywordTypes) {
            filter += "withKeywordTypes=1&"
        }

        if (search) {
            filter += `search=${search}&`
        }

        return axios.get(`/dashboard/keywords/project-id?project_id=${projectId}&${filter}`)
            .then((response) => {
                return response.data;
            });
    }

    static createKeyword(payload) {
        return axios.post(`/dashboard/keywords`, payload).then((response) => {
            return response.data;
        });
    }

    static updateKeyword(id, payload) {
        return axios.post(`/dashboard/keywords/update/${id}`, payload).then((response) => {
            return response.data;
        });
    }

    static deleteKeyword(id) {
        return axios.post(`/dashboard/keywords/delete/${id}`).then((response) => {
            return response.data;
        });
    }

    static deleteManyKeywords(ids) {
        return axios.post(`/dashboard/keywords/delete-many`, {
            ids
        }).then((response) => {
            return response.data;
        });
    }

    static getById(id) {
        return axios.get(`/dashboard/keywords/get/${id}`).then((response) => {
            return response.data;
        });
    }

    static getAvgTopCountAds(page, perPage, sortField, sortOrder, startDate = null, endDate = null, keywordId = null, projectId = null) {
        const filterQuery = qs.stringify({
            page,
            perPage,
            sortField,
            sortOrder,
            keyword_id: keywordId,
            project_id: projectId,
            start_date: startDate,
            end_date: endDate
        });

        return axios.get(`/dashboard/keywords/avg-top-ads-count?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAvgTopCountAdsDaily(page, perPage, sortField, sortOrder, startDate = null, endDate = null, keywordId = null, projectId = null) {
        const filterQuery = qs.stringify({
            page,
            perPage,
            sortField,
            sortOrder,
            keyword_id: keywordId,
            project_id: projectId,
            start_date: startDate,
            end_date: endDate
        });

        return axios.get(`/dashboard/keywords/avg-top-ads-count-daily?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAvgTopCountAdsByKeyword(projectId, keywordId, startDate = null, endDate = null) {
        const filterQuery = qs.stringify({
            project_id: projectId,
            keyword_id: keywordId,
            start_date: startDate,
            end_date: endDate
        });

        return axios.get(`/dashboard/keywords/avg-top-ads-count-by-keyword?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAvgTopCountAdsByKeywordHourly(projectId, startDate, endDate, keywordId = null) {
        const filterQuery = qs.stringify({
            project_id: projectId,
            keyword_id: keywordId,
            start_date: startDate,
            end_date: endDate
        });

        return axios.get(`/dashboard/keywords/avg-top-ads-count-by-keyword-hourly?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
    static getAvgTopCountAdsByKeywordByDomains(startDate, endDate, keywordId = null) {
        const filterQuery = qs.stringify({
            keyword_id: keywordId,
            start_date: startDate,
            end_date: endDate
        });

        return axios.get(`/dashboard/keywords/avg-top-ads-count-by-keyword-by-domains?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAll() {
        return axios.get(`/dashboard/keywords/get-all`).then((response) => {
            return response.data;
        });
    }
}