import axios from "axios";
import * as qs from "query-string";

export class ReporterPreferencesService {
    static getMe(userId = null) {
        const filterQuery = qs.stringify({
            'user_id': userId
        });

        return axios.get(`/dashboard/reporter-preference/me?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static updateMe(payload) {
        return axios.post(`/dashboard/reporter-preference/update/me`, payload).then((response) => {
            return response.data;
        });
    }
}