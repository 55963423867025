import moment from "moment";
import {Cookies} from "react-cookie";

export const getDatePickerStartDate = () => {
    const cookies = new Cookies();
    const lastStartDate = cookies.get("last_start_date");
    if(lastStartDate) {
        return moment(lastStartDate).format('YYYY-MM-DD')
    } else {
        return moment().subtract("14", "days").format('YYYY-MM-DD')
    }
}

export const getDatePickerEndDate = () => {
    const cookies = new Cookies();
    const lastEndDate = cookies.get("last_end_date");
    if(lastEndDate) {
        return moment(lastEndDate).format('YYYY-MM-DD')
    } else {
        return moment().format('YYYY-MM-DD')
    }
}

export const setLastDates = (startDate, endDate) => {
    const cookies = new Cookies();
    if(startDate) {
        cookies.set("last_start_date", startDate, {
            path: "/",
            expires: moment().add(10, "minutes").toDate()
        })
    }
    if(endDate) {
        cookies.set("last_end_date", endDate, {
            path: "/",
            expires: moment().add(10, "minutes").toDate()
        })
    }
}

export const dateToDefaultFormat = (date) => {
    return moment(date).format("YYYY-MM-DD");
}

export const lessThanDays = (searchDate, searchDays) => {
    const now = moment();
    const momentSearchDate = moment(searchDate).subtract("days", searchDays);

    return now.isAfter(momentSearchDate)
}

export const subscriptionIsAvailableForPayment = (date) => {
    return lessThanDays(date, 3);
}