import axios from "axios";
import * as qs from "query-string";

export class AdminAnalyticsService {
    static getParserLogsByDay(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/getParserLogsByDay?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getReporterLogsByDay(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/getReporterLogsByDay?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getUserLogsByDay(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/getUserLogsByDay?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getActiveServerCounts(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/getActiveServerCounts?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getSubscriptionCounts(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/getSubscriptionCounts?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
}