import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";

export const MultipleAutocomplete = ({options, equalKey, label, placeholder, externalValues, setExternalValues}) => {
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (externalValues) {
            setValues(externalValues)
        }
    }, [externalValues])

    const handleChange = (e, newValues) => {
        setValues(newValues);

        if (setExternalValues) {
            setExternalValues(newValues);
        }
    }

    return <Autocomplete
        multiple
        options={options}
        value={values}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option[equalKey] === value[equalKey]}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                placeholder={placeholder}
            />
        )}
    />
}