import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ProjectsService} from "../../../services/projects.service";

export const ProjectSelector = ({value, setValue, projects}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);

    const filterOptions = createFilterOptions({
        limit: 24
    });

    const allItems = [
        {
            id: null,
            name: t("all_projects")
        }
    ];

    useEffect(() => {
        if (projects === undefined) {
            ProjectsService.getAll().then(data => {
                if (data.data) {
                    setData(allItems.concat(data.data));
                }
            }).catch(e => {
                toastr.error(t("error_loading"))
            })
        } else {
            setData(projects)
        }
    }, [projects]);

    return <Autocomplete
        filterOptions={filterOptions}
        disablePortal={false}
        options={data}
        getOptionLabel={option => option.name}
        freeSolo
        onChange={setValue}
        onClick={e => e.stopPropagation()}
        renderInput={(inputParams) =>
            <TextField {...inputParams}
                       fullWidth
                       size={"small"}
                       onClick={e => e.stopPropagation()}
                       variant={"outlined"}
                       label={t("projects")}
            />
        }
    />
}