import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {UserService} from "../../../services/user.service";
import toastr from "toastr";
import {Box, Grid, Paper, Skeleton, Typography} from "@mui/material";
import {ParserSubscriptionCard} from "../../admin/user/EditUser/Subscriptions/parser/ParserSubscriptionCard";
import {ReporterSubscriptionCard} from "../../admin/user/EditUser/Subscriptions/reporter/ReporterSubscriptionCard";
import * as React from "react";

export const UserCurrentSubscriptions = () => {
    const {t} = useTranslation();

    const [parserSubscription, setParserSubscription] = useState(null);
    const [reporterSubscription, setReporterSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setLoading(true);
        UserService.getCurrentSubscriptions().then(data => {
            if (data.success) {
                setParserSubscription(data.data.parser);
                setReporterSubscription(data.data.reporter);
            }
            setLoading(false)
        }).catch(e => {
            toastr.error(t("error_server"));
            setLoading(false)
        })
    }, [trigger]);

    const handleUpdate = () => {
        setTrigger(!trigger);
    }

    return (
        <Box>
            <Typography variant="h5">
                {t("subscriptions")}
            </Typography>

            <Grid sx={{mt: 2}} container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} md={5}>
                    {
                        loading ? <Skeleton variant="rectangular" height={100}/> :
                            <ParserSubscriptionCard onUpdate={handleUpdate}
                                                    subscription={parserSubscription}
                                                    userId={1}
                                                    isCurrent={true}
                            />
                    }
                </Grid>
                <Grid item xs={12} md={5}>
                    {
                        loading ? <Skeleton variant="rectangular" height={100}/> :
                            <ReporterSubscriptionCard onUpdate={handleUpdate}
                                                      subscription={reporterSubscription}
                                                      userId={1}
                                                      isCurrent={true}
                            />
                    }
                </Grid>
            </Grid>
        </Box>
    )
}