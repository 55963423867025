import axios from "axios";

export class InvoicesService {
    static getById(id) {
        return axios.get(`/dashboard/invoice/${id}`).then((response) => {
            return response.data;
        });
    }

    static makePayment(id, paymentType) {
        return axios.post(`/dashboard/invoice/${id}/makePayment`, {
            payment_type: paymentType
        }).then((response) => {
            return response.data;
        });
    }

    static generateParserInvoice(userId, values) {
        return axios.post(`/dashboard/invoice/generate/parser`, {
            ...values,
            user_id: userId
        }).then((response) => {
            return response.data;
        });
    }

    static generateReporterInvoice(userId, values) {
        return axios.post(`/dashboard/invoice/generate/reporter`, {
            ...values,
            user_id: userId
        }).then((response) => {
            return response.data;
        });
    }

    static generateByParserSubscription(id) {
        return axios.post(`/dashboard/invoice/generateByParserSubscription/${id}`).then((response) => {
            return response.data;
        });
    }
}