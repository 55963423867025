import axios from "axios";

export class ServersService {
    static getByUser(userId) {
        return axios.get(`/dashboard/servers/get/byUser/${userId}`).then((response) => {
            return response.data;
        });
    }

    static createServer(userId, type) {
        return axios.post(`/dashboard/servers`, {
            user_id: userId,
            type: type
        }).then((response) => {
            return response.data;
        });
    }

    static deleteServer(id) {
        return axios.post(`/dashboard/servers/delete/${id}`).then((response) => {
            return response.data;
        });
    }
}