export const getPrettifiedDomain = (url) => {
    if(!url) return url;

    let prettifyDomain = url;
    prettifyDomain = prettifyDomain.replace("https://", "");
    prettifyDomain = prettifyDomain.replace("http://", "");
    prettifyDomain = prettifyDomain.replace("www.", "");
    prettifyDomain = prettifyDomain.replace(/^\/|\/$/g, '')

    return prettifyDomain;
}

export const getDomainSource = (url) => {
    if(url.indexOf("play.google.com") > -1) return "android";
    if(url.indexOf("apps.apple.com") > -1) return "iphone";

    return "site";
}