import _ from "underscore";
import {FormControl, IconButton, InputAdornment, OutlinedInput} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";

export const notes = (params, data, submitNotes, setEditableNotes) => {
    const dataItem = data.find((item, index) => {
        return item.id === params.row.id
    });
    const editable = _.has(dataItem, "is_editable") ? dataItem.is_editable : false;

    if (editable) {
        return (
            <form onSubmit={submitNotes}>
                <input type="hidden" name="id" value={params.row.id}/>
                <FormControl variant="outlined">
                    <OutlinedInput
                        name={"notes"}
                        multiline={true}
                        minRows={1}
                        maxRows={4}
                        defaultValue={params.row.notes}
                        onKeyDown={(e) => e.stopPropagation()}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    size={"small"}
                                    type={"submit"}
                                >
                                    <SaveIcon fontSize={"small"}/>
                                </IconButton>
                            </InputAdornment>
                        }
                        sx={{
                            alignItems: "flex-start"
                        }}
                    />
                </FormControl>
            </form>
        )
    }
    return (
        <div>
            {params.row.notes !== null ? params.row.notes.split("\n").map((item, index, array) => {
                if(index !== array.length - 1) {
                    return (
                        <span key={index}>{item} <br/></span>
                    )
                } else {
                    return (
                        <span key={index}>{item}</span>
                    )
                }
            }) : ""}
            <IconButton sx={{width: 15, height: 15, marginLeft: 1}} onClick={() => setEditableNotes(params.row.id, true)}>
                <EditIcon sx={{fontSize: 10}}/>
            </IconButton>
        </div>
    )
}