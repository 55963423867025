import {Autocomplete, FormControl, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {ProjectsService as ProjectService} from "../../../../../services/projects.service";

export const Projects = ({projectIds, setProjectIds, projectIdsTmp, projects, setProjects}) => {
    const {t} = useTranslation();

    useEffect(() => {
        ProjectService.get(1, null, true).then(data => {
            if (data.data) {
                setProjects(data.data)
            }
        })
    }, []);

    useEffect(() => {
        if (projects.length > 0) {
            setProjectIds(projectIdsTmp.map(id => {
                const findIndex = projects.findIndex(item => item.id === Number(id))

                if (findIndex > -1) return projects[findIndex]
            }))
        }
    }, [projects, projectIdsTmp]);

    return <FormControl fullWidth>
        <Autocomplete
            multiple
            options={projects}
            onChange={(event, value, reason, details) => {
                setProjectIds(value);
            }}
            disableCloseOnSelect={true}
            getOptionLabel={(option) => option ? option.name : ""}
            isOptionEqualToValue={(option, value) => option.id === (value ? value.id : null)}
            value={projectIds}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("projects")}
                />
            )}
        />
    </FormControl>
}