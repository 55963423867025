import {DataGrid, ruRU} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Chip, Tooltip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ParserService} from "../../../services/parser.service";
import {ParserLogFilter, ReportLogFilter} from "./ReportLogFilter";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../utils/Date";
import {ReporterService} from "../../../services/reporter.service";

const grey400 = grey[400];

export const ReportLog = () => {
    const {t} = useTranslation();

    const pageSize = 24;
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [filter, setFilter] = useState({
        proxy_id: null,
        user_id: null,
        proxy_country: null,
        start_date: getDatePickerStartDate(),
        end_date: getDatePickerEndDate(),
    });

    const columns = [
        {
            field: 'date',
            headerName: t("date"),
            width: 100,
            sortable: true,
        },
        {
            field: 'total_count',
            headerName: t("report_total_count"),
            width: 150,
            sortable: false,
            align: "center",
            renderCell: params => {
                return <strong style={{fontSize: 16}}>{params.row.total_count}</strong>
            }
        },
        {
            field: 'recaptcha_count',
            headerName: t("report_recaptcha_count"),
            width: 150,
            sortable: false,
            renderCell: params => {
                const recaptchaPercent = params.row.recaptcha_count === 0 ? "0%" : Math.floor(params.row.recaptcha_count / params.row.total_count * 100) + "%"
                return <Tooltip title={t("report_recaptcha_tooltip", {
                    total_count: params.row.total_count,
                    recaptcha_count: params.row.recaptcha_count,
                    recaptcha_percent: recaptchaPercent
                })}>
                    <Chip color={"warning"} size={"small"} label={params.row.recaptcha_count + " / " + recaptchaPercent} />
                </Tooltip>
            }
        },
        {
            field: 'error_count',
            headerName: t("report_error_count"),
            width: 150,
            sortable: false,
            renderCell: params => {
                const recaptchaPercent = params.row.error_count === 0 ? "0%" : Math.round(params.row.error_count / params.row.total_count * 100) + "%"
                return <Tooltip title={t("report_error_tooltip", {
                    total_count: params.row.total_count,
                    error_count: params.row.error_count,
                    error_percent: recaptchaPercent
                })}>
                    <Chip color={"error"} size={"small"} label={params.row.error_count + " / " + recaptchaPercent} />
                </Tooltip>
            }
        }
    ]

    useEffect(() => {
        setPage(-1)
    }, [filter])

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ReporterService.getLog({
                page: page + 1,
                perPage: pageSize,
                ...filter
            }).then(data => {
                if (data.data) {
                    setLogs(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    return <div>
        <Box sx={{
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography variant="h5">
                {t("report_statistics")}
            </Typography>
        </Box>

        <ReportLogFilter filter={filter} setFilter={setFilter}/>

        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight={true}
            sx={{
                border: 1,
                borderColor: grey400,
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                '& .MuiDataGrid-columnSeparator': {
                    color: grey400,
                },
                '& .MuiDataGrid-withBorder': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-footerContainer': {
                    borderColor: grey400,
                },
                maxWidth: 600
            }}
            disableSelectionOnClick={true}
            showCellRightBorder={true}
            rows={logs}
            columns={columns}
            paginationMode={"server"}
            page={page < 0 ? 0 : page}
            getRowId={(option) => option.date}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowCount={rowCount}
            disableColumnMenu={true}
            loading={loading}
            disableColumnSelector={true}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            filterMode="server"
        />
    </div>
}