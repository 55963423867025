import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

export const ReportType = ({reportType, setReportType}) => {
    const {t} = useTranslation();

    return <FormControl fullWidth>
        <InputLabel>
            {t("report_type")}
        </InputLabel>
        <Select
            name={"second_label"}
            value={reportType}
            label={t("report_type")}
            onChange={(e) => setReportType(e.target.value)}
        >
            <MenuItem value={0}>
                1
            </MenuItem>
            <MenuItem value={3}>
                2
            </MenuItem>
            <MenuItem value={2}>
                3
            </MenuItem>
            <MenuItem value={1}>
                4
            </MenuItem>
            <MenuItem value={4}>
                {t(`mixed`)}
            </MenuItem>
        </Select>
    </FormControl>
}