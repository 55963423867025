import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {DataGrid, GridActionsCellItem, ruRU} from "@mui/x-data-grid";
import KeyIcon from "@mui/icons-material/Key";
import toastr from "toastr";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {CountryService} from "../../../services/country.service";
import {ProjectsService} from "../../../services/projects.service";
import {Box, Button, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {ProjectsKeywords} from "./ProjectsKeywords/ProjectsKeywords";
import {CreateDialog} from "./CreateDialog";
import {AlertDialog} from "../../../components/AlertDialog";
import {EditDialog} from "./EditDialog";
import {grey} from "@mui/material/colors";
import {AddReportClickUrl} from "../../user/ads/AddReportClickUrl";
import {ProjectFilter} from "./ProjectFilter";

const grey400 = grey[400];

export const ProjectsList = () => {
    const {t} = useTranslation();

    const pageSize = 12;
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [countries, setCountries] = useState([]);

    const [targetProjectId, setTargetProjectId] = useState(null);
    const [targetUserId, setTargetUserId] = useState(null);

    const [deleteProjectId, setDeleteProjectId] = useState(null);
    const [editProjectId, setEditProjectId] = useState(null);

    const [keywordsModal, setKeywordsModal] = useState(false);

    const [createDialog, setCreateDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const [filters, setFilters] = useState({
        name: null,
        user_id: null
    });

    const columns = [
        {
            field: 'user_id',
            headerName: t("user"),
            width: 200,
            sortable: false,
            renderCell: params => {
                return <strong>{params.row.user !== null ? params.row.user.name : t('without_user¬')}</strong>
            },
        },
        {
            field: 'name',
            headerName: t("project"),
            width: 140,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: 'notes',
            headerName: t("notes"),
            width: 180,
            sortable: false,
            renderCell: params => {
                return <i>{params.row.notes}</i>
            },
        },
        {
            field: 'created_at',
            headerName: t("created_at"),
            width: 180,
            sortable: false,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'actions',
            headerName: t("actions"),
            minWidth: 140,
            sortable: false,
            type: 'actions',
            flex: 1,
            getActions: (params) => [
                <GridActionsCellItem
                    color={"primary"}
                    icon={<KeyIcon/>}
                    onClick={() => {
                        setKeywordsModal(true);
                        setTargetProjectId(params.row.id)
                        setTargetUserId(params.row.user_id);
                    }}
                    label={"2"}
                />,
                <GridActionsCellItem
                    color={"info"}
                    icon={<EditIcon/>}
                    onClick={() => {
                        setEditDialog(true);
                        setEditProjectId(params.row.id)
                    }}
                    label={"2"}
                />,
                <GridActionsCellItem
                    color={"error"}
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setDeleteDialog(true);
                        setDeleteProjectId(params.row.id)
                    }}
                    label={"2"}
                />,
            ]
        }
    ]

    useEffect(() => {
        CountryService.get().then(data => {
            if (data.data) {
                setCountries(Object.keys(data.data).map(item => {
                    return {
                        code: item.toLowerCase(),
                        label: data.data[item]
                    }
                }))
            }
        })
    }, []);

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ProjectsService.get(page + 1, pageSize, false, filters.name, filters.user_id).then(data => {
                if (data.data) {
                    setProjects(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    useEffect(() => {
        setPage(-1)
    }, [filters])

    const handleDeleteProject = () => {
        if (deleteProjectId !== null) {
            ProjectsService.deleteProject(deleteProjectId).then(data => {
                if (data.success) {
                    toastr.success(t("project_deleted"))
                    setPage(-1);
                } else {
                    toastr.error(t("project_not_deleted"));
                }

                setDeleteDialog(false);
            }).catch(e => {
                setDeleteDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    return (
        <div>
            <Box sx={{
                marginBottom: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Typography variant="h5">
                    {t("projects")}
                </Typography>

                <Button
                    startIcon={<AddIcon/>}
                    variant={"contained"}
                    onClick={() => setCreateDialog(true)}
                >
                    {t("add")}
                </Button>
            </Box>

            <ProjectFilter handleSubmit={(e, values) => {
                e.preventDefault();
                setFilters({...values})
            }
            }/>

            <DataGrid
                getRowHeight={() => 'auto'}
                autoHeight={true}
                sx={{
                    border: 1,
                    borderColor: grey400,
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                    '& .MuiDataGrid-columnSeparator': {
                        color: grey400,
                    },
                    '& .MuiDataGrid-withBorder': {
                        borderColor: grey400,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderColor: grey400,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderColor: grey400,
                    },
                    maxWidth: 900
                }}
                disableSelectionOnClick={true}
                showCellRightBorder={true}
                rows={projects}
                columns={columns}
                paginationMode={"server"}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pageSize={pageSize}
                rowCount={rowCount}
                disableColumnMenu={true}
                loading={loading}
                disableColumnSelector={true}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                filterMode="server"
            />

            <ProjectsKeywords
                open={keywordsModal}
                countries={countries}
                projectId={targetProjectId}
                userId={targetUserId}
                handleClose={() => setKeywordsModal(false)}
            />

            <CreateDialog handleClose={() => setCreateDialog(false)}
                          open={createDialog}
                          handleSuccess={() => {
                              setCreateDialog(false);
                              setPage(-1);
                          }}
            />

            <AlertDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t("project_delete_dialog_title")}
                content={t("project_delete_dialog_content")}
                handleCancel={() => setDeleteDialog(false)}
                handleOk={handleDeleteProject}
            />

            <EditDialog open={editDialog}
                        projectId={editProjectId}
                        handleClose={() => {
                            setEditDialog(false);
                            setPage(-1);
                        }}
            />
        </div>
    )
}