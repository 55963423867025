import {Box, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TopSites} from "./TopSites/TopSites";
import {TopKeywords} from "./TopKeywords/TopKeywords";
import {TopSources} from "./TopSources/TopSources";
import {DateRangePickerCustom} from "../../../components/DateRangePickerCustom";
import {HeaderAutocomplete} from "../../../components/autocompletes/HeaderAutocomplete";
import {ProjectsService as ProjectService} from "../../../services/projects.service";
import {AdsService as AnalyticsService} from "../../../services/analytics.service";
import {TopBox} from "./TopBoxes/TopBox";
import {NewAdsChart} from "./TopBoxes/charts/NewAdsChart";
import {getPercentage} from "../../../utils/Number";
import {NewSiteChart} from "./TopBoxes/charts/NewSiteChart";
import {ActiveAdsBox} from "./TopBoxes/ActiveAdsBox";
import _ from "underscore";
import {getDatePickerEndDate, getDatePickerStartDate, setLastDates} from "../../../utils/Date";
import {AnySubscriptionAlert} from "../../../components/subscriptions/AnySubscriptionAlert";
import {TopDevices} from "./TopDevices/TopDevices";
import {UserService} from "../../../services/user.service";
import toastr from "toastr";
import {ReporterSubscriptionWidget} from "./Subscriptions/ReporterSubscriptionWidget";
import {ParserSubscriptionWidget} from "./Subscriptions/ParserSubscriptionWidget";
import {getLastSelectedUser, setLastSelectedUser} from "../../../utils/Paginations";
import {UserSelector} from "../../../components/autocompletes/UserSelector";
import {SelectUserAlert} from "../../../components/SelectUserAlert";
import {ActiveAdUrlsBox} from "./TopBoxes/ActiveAdUrlsBox";

export const UserHome = (props) => {
    const {t} = useTranslation();

    const {role} = props;

    const [projects, setProjects] = useState([]);

    const [adsByDay, setAdsByDay] = useState([]);
    const [adsToday, setAdsToday] = useState({
        count: 0,
        trend: "down",
        percent: ""
    });
    const [adsByDayLoading, setAdsByDayLoading] = useState(true);

    const [sites, setSites] = useState([]);
    const [sitesToday, setSitesToday] = useState({
        count: 0,
        trend: "up",
        percent: ""
    });
    const [sitesLoading, setSitesLoading] = useState(true);

    const [urlByDay, setUrlByDay] = useState([]);
    const [urlByDayLoading, setUrlByDayLoading] = useState(true);

    const [filter, setFilter] = useState({
        startDate: getDatePickerStartDate(),
        endDate: getDatePickerEndDate(),
        projectId: "",
        user_id: getLastSelectedUser()
    })

    const [parserSubscription, setParserSubscription] = useState(null);
    const [reporterSubscription, setReporterSubscription] = useState(null);

    useEffect(() => {
        if (role !== "admin") {
            UserService.getCurrentSubscriptions(1).then(data => {
                if (data.success) {
                    setParserSubscription(data.data.parser);
                    setReporterSubscription(data.data.reporter);
                }
            }).catch(e => {
                toastr.error(t("error_server"));
            })
        }
    }, [role]);

    useEffect(() => {
        ProjectService.get(1, null, true, null, filter.user_id).then(data => {
            if (data.data) {
                setProjects(data.data)
            }
        })
    }, [filter.user_id]);

    useEffect(() => {
        setAdsByDayLoading(true);
        setSitesLoading(true);
        setUrlByDayLoading(true);

        AnalyticsService.getAdsByDay(filter).then(data => {
            if (data.data) {
                const ads = _.sortBy(data.data, "date");
                setAdsByDay(ads)

                const adsCount = data.data.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue.count
                }, 0);
                const todayAds = ads[ads.length - 1];
                const todayAdsCount = todayAds !== undefined ? todayAds.count : 0;

                const adsAvg = adsCount / data.data.length;
                const newAdsPercent = getPercentage(todayAdsCount, adsAvg);
                const adsPercentFormatted = (newAdsPercent > 0 ? newAdsPercent : "-" + (newAdsPercent * -1)) + "%";

                setAdsToday({
                    count: adsCount,
                    trend: newAdsPercent > 0 ? "up" : "down",
                    percent: adsPercentFormatted
                });
            }

            setAdsByDayLoading(false)
        }).catch(e => {
            setAdsByDayLoading(false);
        });

        AnalyticsService.getAdsByUrl(filter).then(data => {
            if (data.data) {
                const sites = _.sortBy(data.data, "date");
                setSites(sites)

                const adsCount = data.data.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue.count
                }, 0);
                const todayAds = sites[sites.length - 1];
                const todayAdsCount = todayAds !== undefined ? todayAds.count : 0;

                const adsAvg = adsCount / data.data.length;
                const newAdsPercent = getPercentage(todayAdsCount, adsAvg);
                const adsPercentFormatted = (newAdsPercent > 0 ? newAdsPercent : "-" + (newAdsPercent * -1)) + "%";

                setSitesToday({
                    count: adsCount,
                    trend: newAdsPercent > 0 ? "up" : "down",
                    percent: adsPercentFormatted
                });
            }

            setSitesLoading(false)
        }).catch(e => {
            setSitesLoading(false);
        });

        AnalyticsService.getUrlByDay(filter).then(data => {
            if (data.data) {
                setUrlByDay(data.data)
            }

            setUrlByDayLoading(false);
        }).catch(e => {
            setUrlByDayLoading(false);
        });
    }, [filter]);

    return (
        <Box sx={{py: 3}}>
            <AnySubscriptionAlert/>

            <Box sx={{
                marginBottom: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between"
            }}>

                <Typography variant="h4">
                    {t("ads_analytics")}
                </Typography>

                <Box sx={{
                    m: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}>
                    <ParserSubscriptionWidget subscription={parserSubscription}/>
                    <ReporterSubscriptionWidget subscription={reporterSubscription}/>

                    <Box sx={{mr: 1}}>
                        <DateRangePickerCustom
                            startDate={filter.startDate}
                            endDate={filter.endDate}
                            onApply={(startDate, endDate) => {
                                setLastDates(startDate, endDate)
                                setFilter({...filter, startDate: startDate, endDate: endDate})
                            }}
                        />
                    </Box>


                    <Box>
                        <HeaderAutocomplete
                            width={115}
                            variant={"outlined"}
                            size={"small"}
                            labelKey={"name"}
                            label={t("project")}
                            setValue={(e, value) => {
                                setFilter({...filter, projectId: value !== null ? value.id : ""})
                            }}
                            data={projects}
                        />
                    </Box>
                    {
                        role === "admin" ? <Box sx={{width: "150px", ml: 1}}><UserSelector
                            userType={"user"}
                            excludeAllUsers={true}
                            setValue={(e, value) => {
                                setFilter({...filter, user_id: value.id})
                                setLastSelectedUser(value.id)
                            }}
                        /></Box> : ""
                    }
                </Box>
            </Box>

            {
                role === "admin" && filter.user_id === null ? <SelectUserAlert/> : ""
            }

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TopBox
                                title={t('new_ads')}
                                count={adsToday.count}
                                chipValue={adsToday.percent}
                                trend={adsToday.trend}
                                loading={adsByDayLoading}
                            >
                                <NewAdsChart loading={adsByDayLoading} adsByDay={adsByDay}/>
                            </TopBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ActiveAdsBox filter={filter}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TopBox
                                title={t('new_domains')}
                                count={sitesToday.count}
                                chipValue={sitesToday.percent}
                                trend={sitesToday.trend}
                                loading={sitesLoading}
                            >
                                <NewSiteChart loading={sitesLoading} sites={sites}/>
                            </TopBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ActiveAdUrlsBox filter={filter}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5" sx={{marginBottom: 2}}>
                        {t("top_sites")}
                    </Typography>
                    <TopSites sites={urlByDay} loading={urlByDayLoading}/>
                </Grid>
            </Grid>

            <Box sx={{marginTop: 5}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" sx={{marginBottom: 2}}>
                            {t("top_keywords")}
                        </Typography>
                        <TopKeywords filter={filter}/>
                    </Grid>
                    {/*<Grid item xs={12} md={6}>*/}
                    {/*    <Typography variant="h5" sx={{marginBottom: 2}}>*/}
                    {/*        {t("top_ads")}*/}
                    {/*    </Typography>*/}
                    {/*    <TopAds/>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" sx={{marginBottom: 2}}>
                            {t("source_statistic")}
                        </Typography>
                        <TopSources filter={filter}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" sx={{marginBottom: 2}}>
                            {t("device_statistic")}
                        </Typography>
                        <TopDevices filter={filter}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}