import {useTranslation} from "react-i18next";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {useState} from "react";
import moment from "moment";
import {reportsCounts} from "../../../utils/Constants";
import {SubscriptionService} from "../../../services/subscription.service";
import {useNavigate} from "react-router-dom";

export const CurrentReporterSubscriptionEdit = (props) => {
    const {handleClose, open, userId} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [initialValues, setInitialValues] = useState({
        reports_count: Object.keys(reportsCounts)[0],
    });

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>{t("adding_new_reports")}</DialogTitle>

            <Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        reports_count: Yup.number().required(t('required')),
                    })}
                    onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                        setSubmitting(true);

                        SubscriptionService.updateCurrentReporterSubscription(values).then(data => {
                            if (data.success) {
                                navigate(`/dashboard/invoices/${data.data.id}`)
                            } else {
                                toastr.error(data.message || t("error_server"));
                            }

                            setSubmitting(false);
                        }).catch(e => {
                            setSubmitting(false);
                            toastr.error(toastr.error(e.response.data.message || t("error_server")));
                        })
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box sx={{p: 3}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                {t("target_report_count")}
                                            </InputLabel>
                                            <Select
                                                name={"reports_count"}
                                                value={values.reports_count}
                                                label={t("target_report_count")}
                                                onChange={handleChange}
                                                error={Boolean(touched.reports_count && errors.reports_count)}
                                            >
                                                {
                                                    Object.keys(reportsCounts).map(item => {
                                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                                    })
                                                }
                                            </Select>

                                            {
                                                Boolean(touched.reports_count && errors.reports_count) ?
                                                    <FormHelperText
                                                        error={true}>{errors.reports_count}</FormHelperText> : ""
                                            }

                                            <Box sx={{my:2}}>
                                                <Alert severity={"info"}>
                                                    {
                                                        t("price_for_reports", {
                                                            count: values.reports_count
                                                        })
                                                    } - <strong>{reportsCounts[values.reports_count].sum}$</strong>
                                                </Alert>
                                            </Box>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Button disableElevation
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"submit"}
                                        >
                                            {t("go_pay")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    )
}