import axios from "axios";

export class AdsService {
    static getAds(page, sortField, sortOrder, perPage, filter = {}) {
        let filterQuery = '';

        Object.keys(filter).forEach(item => {
            if (filter[item] !== "") {
                filterQuery += `&filter[${item}]=${filter[item]}`
            }
        })

        return axios.get(`/dashboard/ad?page=${page}&sort_field=${sortField}&sort_order=${sortOrder}&perPage=${perPage}${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAdByAdUrl(page, sortField, sortOrder, pageSize, filter = {}) {
        let filterQuery = '';

        Object.keys(filter).forEach(item => {
            if (filter[item] !== "") {
                filterQuery += `&filter[${item}]=${filter[item]}`
            }
        })

        return axios.get(`/dashboard/ad/agg/ad-url?page=${page}&sort_field=${sortField}&sort_order=${sortOrder}&perPage=${pageSize}${filterQuery}`)
            .then((response) => {
                return response.data;
            });
    }

    static updateNotes(id, notes, userId = null) {
        return axios.post(`/dashboard/ad/agg/ad-url`, {
            id,
            notes,
            user_id: userId
        }).then((response) => {
            return response.data;
        });
    }

    static exportAds(filter, select, type) {
        return axios.post(`/dashboard/ad/export`, {filter: filter, select: select, exportType: type}, {
            responseType: 'blob'
        }).then((response) => {
            return response;
        });
    }

    static getCountAdUrlByDay(adUrl, userId) {
        return axios.get(`/dashboard/ad/agg/ad-url/count-by-day?ad_url=${adUrl}&user_id=${userId}`).then((response) => {
            return response.data;
        });
    }

    static getCountAdUrlByMonth(adUrl, userId) {
        return axios.get(`/dashboard/ad/agg/ad-url/count-by-month?ad_url=${adUrl}&user_id=${userId}`).then((response) => {
            return response.data;
        });
    }
}