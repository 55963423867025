import {Box, Button, Grid, IconButton, Popover, Typography} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import * as React from "react";
import {ReportCampaignSelector} from "../../../components/autocompletes/ReportCampaignSelector";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import {CreateReportDialog} from "../reports/CreateReportDialog";
import _ from "underscore"
import {ReporterService} from "../../../services/reporter.service";
import toastr from "toastr";

export const AddReportClickUrl = (props) => {
    const {t} = useTranslation();

    const {ad} = props

    const [anchorEl, setAnchorEl] = useState(null);

    const [submitting, setSubmitting] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [campaignError, setCampaignError] = useState(null);

    const [createCampaign, setCreateCampaign] = useState(false);
    const [triggerCampaignSelector, setTriggerCampaignSelector] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCampaign(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'add-report-click-url' : undefined;

    const handleSubmit = (e) => {
        setSubmitting(true)
        setCampaignError(null);

        if(!ad) {
            setSubmitting(false)
            return false;
        }

        if(campaign === null) {
            setCampaignError(t("error_select_campaign"))
            setSubmitting(false)
            return false;
        }

        ReporterService.addClickUrlToCampaign(campaign.id, {
            links: [
                {
                    click_url: ad.click_url,
                    ad_url: ad.ad_url,
                    country: ad.country
                }
            ]
        }).then(data => {
            if (data.success) {
                toastr.success(t("items_added_to_report", {count: data.data}));
                handleClose();
            } else {
                toastr.error(t("error_server"));
            }

            setSubmitting(false);
        }).catch(e => {
            setSubmitting(false);
            toastr.error(t("error_server"));
        })
    }

    return (
        <Box sx={{marginTop: 1}}>
            <IconButton
                color="info"
                onClick={handleClick}
                aria-describedby={id}
                size={"small"}
            >
                <FlagIcon/>
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{p: 2}}>
                    <Typography sx={{mb: 3, fontWeight: 700}}
                                variant="button"
                                display="block"
                                gutterBottom
                    >
                        {t("add_click_url_to_report")}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"space-between", width: "100%" }}>
                        <ReportCampaignSelector
                            variant={"outlined"}
                            trigger={triggerCampaignSelector}
                            error={campaignError}
                            width={"100%"}
                            value={campaign}
                            setValue={(e, value) => {
                                setCampaign(value)
                            }}
                        />

                        <IconButton
                            color={"primary"}
                            sx={{ marginLeft: 1 }}
                            size={"small"}
                            onClick={() => setCreateCampaign(true)}
                        >
                            <AddIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{mt:2}}>
                        <Button variant={"outlined"}
                                disabled={submitting}
                                color={"primary"}
                                onClick={handleSubmit}
                        >
                            {t("add")}
                        </Button>
                    </Box>
                </Box>
            </Popover>

            <CreateReportDialog open={createCampaign}
                                handleClose={() => setCreateCampaign(false)}
                                showLinks={false}
                                handleSuccess={() => {
                                    setCreateCampaign(false)
                                    setTriggerCampaignSelector(!triggerCampaignSelector)
                                }}
            />
        </Box>
    )
}