import i18n from "../lang/i18n";

export const getProxiesUsersText = (users) => {
    if (users.length === 0) {
        return i18n.t("all_users")
    } else {
        const extraCount = users.length - 1;
        const extraText = extraCount > 0 ? `, + ${extraCount} ${i18n.t('users')}` : '';

        return `${users[0].name}${extraText}`
    }
}