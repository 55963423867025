import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import * as React from "react";
import {useEffect, useState} from "react";
import {Skeleton} from "@mui/material";
import moment from "moment";
import {CustomColors} from "../../../../../themes/custom-colors";

export const NewAdsChart = (props) => {
    const {t} = useTranslation();
    const {adsByDay, loading} = props;

    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setData(adsByDay.map(item => item.count))
        setCategories(adsByDay.map(item => item.date))
    }, [adsByDay]);

    const adsChartSettings = {
        series: [
            {
                name: t("ads"),
                data: data
            },
        ],
        options: {
            colors: [CustomColors.blue],
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                }
            },
            grid: {
                show: true,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories,
                crosshairs: {
                    show: false,
                },
                type: 'date',
                tickAmount: 7,
                labels: {
                    show: true,
                    hideOverlappingLabels: true,
                    formatter: function (value) {
                        return moment(value, "YYYY-MM-DD", "uk")
                            .format("D MMM");
                    }
                }
            },
            yaxis: {
                labels: {
                    show: true,
                }
            },
            legend: {
                show:false,
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                marker: {
                    show: false,
                },
            }
        },
    }

    if(!loading) {
        if(data.length > 0) {
            return (
                <Chart options={adsChartSettings.options}
                       type={"bar"}
                       height={200}
                       series={adsChartSettings.series}
                />
            )
        }
    } else {
        return <Skeleton variant={"rectangular"} height={200}/>
    }
}