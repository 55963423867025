import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {useEffect, useState} from "react";
import moment from "moment";
import {parserFrequency, parserKeywordsCount} from "../../../../utils/Constants";
import {SubscriptionService} from "../../../../services/subscription.service";
import {useNavigate} from "react-router-dom";
import {PriceAlert} from "./PriceAlert";
import {calculatePrice} from "../../../../utils/Price";
import {AlertDialog} from "../../../../components/AlertDialog";
import * as React from "react";


export const CurrentParserSubscriptionEdit = (props) => {
    const navigate = useNavigate();
    const {handleClose, open, subscription} = props;
    const {t} = useTranslation();

    const [oldPrice, setOldPrice] = useState(0);
    const [newPrice, setNewPrice] = useState(0);
    const [leftThan3days, setLeftThan3days] = useState(false);
    const [decreaseModal, setDecreaseModal] = useState(false);

    const [initialValues, setInitialValues] = useState({
        hour_interval: 1,
        keywords_count: 100
    });

    useEffect(() => {
        const leftLessThan3Days = moment().isAfter(moment(subscription.expires_date).subtract(3, "days"));
        setLeftThan3days(leftLessThan3Days);

        setInitialValues({
            hour_interval: subscription.hour_interval,
            keywords_count: subscription.keywords_count
        })

        setOldPrice(calculatePrice(subscription.hour_interval, subscription.keywords_count))
    }, [subscription]);

    const handleDecrease = (values, setSubmitting) => {
        SubscriptionService.setTemporaryParserSubscription(values).then(data => {
            if (data.success) {
                toastr.success(t("subscription_changed_later_start_work"))
                handleClose();
            } else {
                toastr.error(t("error_server"));
            }

            setSubmitting(false);
        }).catch(e => {
            setSubmitting(false);
            toastr.error(toastr.error(e.response.data.message || t("error_server")));
        })
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>{t("editing_parser_subscription")}</DialogTitle>

            <Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        hour_interval: Yup.number().required(t('required')),
                        keywords_count: Yup.number().required(t('required'))
                    })}
                    onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                        const handle = () => {
                            const insertValues = {...values};
                            insertValues.expires_date = moment(insertValues.expires_date).format("YYYY-MM-DD")

                            SubscriptionService.updateCurrentParserSubscription(insertValues).then(data => {
                                if (data.success) {
                                    navigate(`/dashboard/invoices/${data.data.id}`)
                                } else {
                                    toastr.error(data.message || t("error_server"));
                                }

                                setSubmitting(false);
                            }).catch(e => {
                                setSubmitting(false);
                                toastr.error(toastr.error(e.response.data.message || t("error_server")));
                            })
                        }

                        if (leftThan3days || (newPrice > oldPrice) || !subscription.status) {
                            handle()
                        }

                        if(newPrice === oldPrice && subscription.status) {
                            handle()
                        }

                        if (!leftThan3days && newPrice < oldPrice) {
                            setDecreaseModal(true);
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue,
                          setSubmitting
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box sx={{p: 3}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>{t("parser_frequency")}</InputLabel>
                                            <Select
                                                name={"hour_interval"}
                                                value={values.hour_interval}
                                                label={t("parser_frequency")}
                                                onChange={handleChange}
                                                error={Boolean(touched.hour_interval && errors.hour_interval)}
                                            >
                                                {
                                                    parserFrequency.map(item => {
                                                        return <MenuItem key={item.value}
                                                                         value={item.value}>{t(item.label)}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            {
                                                Boolean(touched.hour_interval && errors.hour_interval) ?
                                                    <FormHelperText
                                                        error={true}>{errors.hour_interval}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>{t("keywords_count")}</InputLabel>
                                            <Select
                                                name={"keywords_count"}
                                                value={values.keywords_count}
                                                label={t("keywords_count")}
                                                onChange={handleChange}
                                                error={Boolean(touched.keywords_count && errors.keywords_count)}
                                            >
                                                {
                                                    parserKeywordsCount.map(item => <MenuItem key={item}
                                                                                              value={item}>{item}</MenuItem>)
                                                }
                                            </Select>
                                            {
                                                Boolean(touched.keywords_count && errors.keywords_count) ?
                                                    <FormHelperText
                                                        error={true}>{errors.keywords_count}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <PriceAlert expiresDate={subscription.expires_date}
                                                    startDate={subscription.start_date}
                                                    oldPrice={oldPrice}
                                                    onNewPrice={(newPrice) => setNewPrice(newPrice)}
                                                    status={subscription.status}/>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {
                                            leftThan3days || (newPrice > oldPrice) || !subscription.status ?
                                                <Button sx={{mr: 1}}
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        variant={"outlined"}
                                                        type={"submit"}
                                                >
                                                    {t("go_pay")}
                                                </Button> : ""
                                        }

                                        {
                                            newPrice === oldPrice && subscription.status ?
                                                <Button sx={{mr: 1}}
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        variant={"outlined"}
                                                        type={"submit"}
                                                >
                                                    {t("renew")}
                                                </Button> : ""
                                        }

                                        {
                                            !leftThan3days && newPrice < oldPrice ? <Button
                                                sx={{mr: 1}}
                                                disableElevation
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"submit"}
                                            >
                                                {t("change_and_pay_later")}
                                            </Button> : ""
                                        }

                                        <Button
                                            type={"button"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            onClick={handleClose}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <AlertDialog
                                open={decreaseModal}
                                handleClose={() => setDecreaseModal(false)}
                                title={t("decrease_subscription_title")}
                                content={t("decrease_subscription_description")}
                                handleCancel={() => setDecreaseModal(false)}
                                handleOk={() => handleDecrease(values)}
                            />
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    )
}