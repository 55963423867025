import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {NotAllowed} from "../../pages/common/NotAllowed";

export const PrivateRoute = (props) => {
    const {layout, role} = props

    const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthed());
    const [roleIsAllowed, setRoleIsAllowed] = useState(role === "any" ? true : AuthService.getCurrentRole() === role)

    useEffect(() => {
        AuthService.me().then(
            (data) => {
                if (Object.keys(data.data).length > 0) {
                    if (data.data.role === role || role === "any") {
                        setRoleIsAllowed(true)
                    } else {
                        setRoleIsAllowed(false)
                    }

                    setIsAuthenticated(true)
                } else {
                    setIsAuthenticated(false)
                }
            },
            (error) => {
                setIsAuthenticated(false);
            }
        );
    }, [role]);

    if(isAuthenticated) {
        if (roleIsAllowed) {
            return layout
        } else {
            return <NotAllowed/>
        }
    } else {
        return <Navigate to={"login"} replace />
    }
}