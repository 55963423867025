import {Box, Card, CardContent, IconButton, Skeleton, Typography} from "@mui/material";
import * as React from "react";

export const CardItem = (props) => {
    const {title, count, subTitle, color, bgColor, children, loading} = props;

    if(!loading) {
        return <Card>
            <CardContent>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
                >
                    <Box>
                        <Typography variant={"h5"}>{title}</Typography>
                        <Typography sx={{mt: 1}} variant={"h3"}>{count}</Typography>
                        <Typography sx={{mt: 1}} variant={"subtitle2"}>{subTitle}</Typography>
                    </Box>

                    <IconButton size="large"
                                sx={{background: bgColor, color: color }}
                    >
                        {children}
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    } else {
        return <Card>
            <CardContent>
                <Skeleton variant={"rectangular"} height={80} />
            </CardContent>
        </Card>
    }
}