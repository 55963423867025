import Grid from "@mui/material/Grid";
import {Box, Button, CircularProgress, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {grey} from "@mui/material/colors";
import AuthService from "../../../services/auth.service";
import toastr from "toastr";

const grey500 = grey[500];

export const ConfirmEmail = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    let { token } = useParams();

    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");

    useEffect(() => {
        AuthService.confirmEmail(token)
            .then(response => {
                if (response.success) {
                    setTitle(t("success_confirm_email"))
                    setSubTitle(t("success_confirm_email_desc"))
                } else {
                    if(response.message === 403) {
                        setTitle(t("email_is_already_verified"));
                        setSubTitle(t("email_is_already_verified_desc"));
                    } else {
                        setTitle(t("error"));
                        setSubTitle(t("email_cannot_be_verified"));
                    }
                }

                setLoading(false)
            }).catch(e => {
            setTitle(t("error"));
            setSubTitle(t("email_cannot_be_verified"));
            setLoading(false)
        })
    }, []);

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Stack sx={{mb: {xs: -0.5, sm: 0.5}}}>
                {
                    loading ? <CircularProgress></CircularProgress> : <>
                        <Typography gutterBottom align={"center"} display={"block"} variant="h3">{title}</Typography>
                        <Typography display={"block"} variant="h5" color={grey500}>{subTitle}</Typography>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt:3 }}>
                            <Button variant={"contained"} color={"primary"} type={"button"} onClick={() => navigate("/login")}>
                                {t("back_to_login")}
                            </Button>
                        </Box>
                    </>
                }
            </Stack>
        </Grid>
    </Grid>
}