import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Skeleton} from "@mui/material";
import {CustomColors} from "../../../../themes/custom-colors";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {KeywordsService} from "../../../../services/keywords.service";
import toastr from "toastr";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../../utils/Date";
import {DateRangePickerCustom} from "../../../../components/DateRangePickerCustom";

const defaultFilter = () => ({
    startDate: getDatePickerStartDate(),
    endDate: getDatePickerEndDate(),
});

export const KeywordsStatisticsByDomains = ({incomingData}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState(defaultFilter())

    var chartOptions = {
        colors: [CustomColors.orange, CustomColors.green, CustomColors.blue],
        chart: {
            height: 280,
            type: "bar",
            stacked: true,
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: categories
        },
        title: {
            text: t("avg_top_ads_chart_domains_title"),
            align: 'left'
        },
        subtitle: {
            text: incomingData.keyword_name,
            align: 'left'
        },
        legend: {
            show: false
        }
    };

    const getData = () => {
        if (incomingData.keyword_id !== null) {
            setLoading(true);
            KeywordsService.getAvgTopCountAdsByKeywordByDomains(
                filter.startDate,
                filter.endDate,
                incomingData.keyword_id
            )
                .then(data => {
                    if (data.data) {
                        const newData = {
                            desktop: {
                                name: t('desktop'),
                                data: []
                            },
                            android: {
                                name: t('android'),
                                data: []
                            },
                            iphone: {
                                name: t('iphone'),
                                data: []
                            }
                        };
                        data.data.forEach(item => {
                            newData[item.device].data.push(item.score);
                        });
                        setCategories(getDataCategories(data.data))
                        setData(Object.values(newData));
                    }
                    setLoading(false);
                }).catch(e => {
                setLoading(false);
                toastr.error(t("error_loading"));
            })
        }
    }

    useEffect(() => {
        getData();
    }, [filter])

    return (
        <Box sx={{height: '100%'}}>
            <Box mb={2}>
                <DateRangePickerCustom
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onApply={(startDate, endDate) => {
                        setFilter({...filter, startDate: startDate, endDate: endDate})
                    }}
                />
            </Box>

            {
                loading ? <Skeleton height="100%" variant={'rectangular'}/> :
                    <Box sx={{overflowX: 'auto', height: '90%'}}>
                        <Chart options={chartOptions}
                               type="bar"
                               width={data.length > 24 ? data[0].data.length * 30 : '100%'}
                               height={'90%'}
                               series={data}
                        />
                    </Box>
            }
        </Box>
    )
}
const getDataCategories = (data) => {
    const set = new Set();
    if (data.length > 0) {
        data.forEach(item => set.add(item.ad_url));
        return Array.from(set);
    }

    return [];

}