import {useTranslation} from "react-i18next";
import {CustomColors} from "../../../../themes/custom-colors";
import toastr from "toastr";
import Box from "@mui/material/Box";
import Chart from "react-apexcharts";
import {useEffect, useState} from "react";
import {KeywordsService} from "../../../../services/keywords.service";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../../utils/Date";
import {DateRangePickerCustom} from "../../../../components/DateRangePickerCustom";

const defaultFilter = () => ({
    startDate: getDatePickerStartDate(),
    endDate: getDatePickerEndDate(),
});

export const KeywordsStatisticsByDay = ({incomingData}) => {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(defaultFilter())

    var chartSeries = [
        {
            name: t('desktop'),
            data: getDataValues(data, 'desktop')
        },
        {
            name: t('android'),
            data: getDataValues(data, 'android')
        },
        {
            name: t('iphone'),
            data: getDataValues(data, 'iphone')
        },
    ];
    var chartOptions = {
        colors: [CustomColors.orange, CustomColors.green, CustomColors.blue],
        chart: {
            height: 280,
            type: "line",
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: getDataCategories(data)
        },
        title: {
            text: t("avg_top_ads_chart_title"),
            align: 'left'
        },
        subtitle: {
            text: incomingData.keyword_name,
            align: 'left'
        }
    };

    // useEffect(() => {
    //     if (open) {
    //         setFilter(defaultFilter());
    //     }
    // }, [open])

    useEffect(() => {
        getData();
    }, [filter])

    const getData = () => {
        if (incomingData.keyword_id !== null && incomingData.project_id !== null) {
            KeywordsService.getAvgTopCountAdsByKeyword(
                incomingData.project_id,
                incomingData.keyword_id,
                filter.startDate,
                filter.endDate
            )
                .then(data => {
                    if (data.data) {
                        setData(data.data);
                    }
                }).catch(e => {
                toastr.error(t("error_loading"));
            })
        }
    }

    return (
        <Box sx={{height: '100%'}}>
            <Box mb={2}>
                <DateRangePickerCustom
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onApply={(startDate, endDate) => {
                        setFilter({...filter, startDate: startDate, endDate: endDate})
                    }}
                />
            </Box>

            <Chart options={chartOptions} height={'90%'} type="line" series={chartSeries}/>
        </Box>
    )
}

const getDataValues = (data, device = 'desktop') => {
    return data.filter(item => item.device === device).map(item => item.score)
}
const getDataCategories = (data) => {
    const set = new Set();
    data.forEach(item => set.add(item.date));
    return Array.from(set);
}