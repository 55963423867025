import Grid from "@mui/material/Grid";
import {DateRangePickerCustom} from "../../../components/DateRangePickerCustom";
import {Button, FormControlLabel, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {SourceSelector} from "../../../components/ads/SourceSelector";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {AdsExportDialog} from "../../../components/ads/AdsExportDialog";
import {useState} from "react";
import {setLastDates} from "../../../utils/Date";
import {UserSelector} from "../../../components/autocompletes/UserSelector";
import {setLastSelectedUser} from "../../../utils/Paginations";
import {DeviceSelector} from "../../../components/ads/DeviceSelector";

export const AdsAdUrlFilter = (props) => {
    const {t} = useTranslation();
    const {filter, setFilter, hasExport, role} = props;

    const [exportDialog, setExportDialog] = useState(false);

    return (
        <Box sx={{my: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <DateRangePickerCustom
                        startDate={filter.startDate}
                        endDate={filter.endDate}
                        onApply={(startDate, endDate) => {
                            setLastDates(startDate, endDate)
                            setFilter({...filter, startDate: startDate, endDate: endDate})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <SourceSelector
                        value={filter.source}
                        setValue={(e, value) => {
                            setFilter({...filter, source: value.props.value})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <DeviceSelector
                        value={filter.device}
                        setValue={(e, value) => {
                            setFilter({...filter, device: value.props.value})
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <FormControlLabel
                        control={<Switch
                            onChange={(e) => setFilter({...filter, isActive: e.target.checked})}
                            checked={filter.isActive}
                        />}
                        label={t("only_active")}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <FormControlLabel
                        control={<Switch
                            onChange={(e) => setFilter({...filter, isNew: e.target.checked})}
                            checked={filter.isNew}
                        />}
                        label={t("only_new")}
                    />
                </Grid>

                {
                    role === "admin" ? <Grid item xs={6} md={3}><UserSelector
                        userType={"user"}
                        excludeAllUsers={true}
                        setValue={(e, value) => {
                            setFilter({...filter, user_id: value.id})
                            setLastSelectedUser(value.id)
                        }}
                    /> </Grid> : ""
                }

                {
                    hasExport ? <>
                        <Grid item xs={12} md={1}>
                            <Box display={"flex"}
                                 flexDirection={"row"}
                                 justifyContent={role === "admin" ? "flex-start" : "flex-end"}
                                 alignItems={"flex-end"}
                            >
                                <Button
                                    startIcon={<FileDownloadIcon/>}
                                    variant={"outlined"}
                                    onClick={() => setExportDialog(true)}
                                >
                                    {t("export")}
                                </Button>
                            </Box>
                        </Grid>

                        <AdsExportDialog
                            open={exportDialog}
                            filter={filter}
                            handleClose={() => setExportDialog(false)}
                        />
                    </> : ""
                }

            </Grid>
        </Box>
    )
}