import {
    Box,
    Button, Checkbox,
    Chip,
    CircularProgress,
    Divider,
    IconButton, LinearProgress,
    List,
    ListItem, ListItemButton, ListItemIcon,
    ListItemText,
    Modal,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState} from "react";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";
import {KeywordsService} from "../../../../services/keywords.service";
import {country} from "../../../../components/ads/table/column/country";
import InfiniteScroll from "react-infinite-scroll-component";
import {SearchKeywords} from "./SearchKeywords";
import toastr from "toastr";
import {CreateDialog} from "./CreateDialog";
import {AlertDialog} from "../../../../components/AlertDialog";
import {EditDialog} from "./EditDialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 800,
    maxHeight: "90%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 4,
    pb: 4
};

export const ProjectsKeywords = (props) => {
    const {open, handleClose, countries, projectId} = props;
    const {t} = useTranslation();

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [keywords, setKeywords] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [createDialog, setCreateDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteKeyword, setDeleteKeyword] = useState(null);

    const [editDialog, setEditDialog] = useState(false);
    const [editDialogId, setEditDialogId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const [checked, setChecked] = useState([]);

    useEffect(() => {
        if (page === -1) {
            setPage(1)
            return;
        }

        getKeywords(page !== 1)
    }, [page]);

    useEffect(() => {
        setPage(-1)
    }, [projectId]);

    const getKeywords = (concat = true) => {
        if (!loading) {
            if (projectId !== null) {
                setLoading(true);
                KeywordsService.getByProjectId(projectId, page, 1200, search, 1).then(data => {
                    setTimeout(() => {
                        if (data.data.data) {
                            if (concat) {
                                const prevValue = keywords.slice();
                                setKeywords(prevValue.concat(data.data.data));
                            } else {
                                setKeywords(data.data.data);
                            }

                            setHasMore(data.data.next_page_url !== null)
                        }

                        setChecked([]);
                        setLoading(false);
                    }, 300)
                }).catch(e => {
                    setLoading(false);
                    toastr.error(t("error_loading"));
                })
            }
        }
    }

    const handleDeleteKeyword = () => {
        setIsDeleting(true);
        if (deleteKeyword !== null) {
            KeywordsService.deleteKeyword(deleteKeyword).then(data => {
                if (data.success) {
                    toastr.success(t("keyword_deleted"))
                    setPage(-1);
                } else {
                    toastr.error(t("keyword_not_deleted"));
                }

                setIsDeleting(false);
                setDeleteDialog(false);
            }).catch(e => {
                setIsDeleting(false);
                setDeleteDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    const handleToggleCheckbox = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const selectAll = () => {
        if (checked.length === keywords.length) {
            setChecked([]);
        } else {
            setChecked(keywords.map(item => item.id))
        }
    }

    const deleteMany = () => {
        setIsDeleting(true);
        if (checked.length  > 0) {
            KeywordsService.deleteManyKeywords(checked).then(data => {
                if (data.success) {
                    toastr.success(t("keyword_deleted"))
                    setPage(-1);
                } else {
                    toastr.error(t("keyword_not_deleted"));
                }

                setDeleteDialog(false);
                setIsDeleting(false);
            }).catch(e => {
                setIsDeleting(false);
                setDeleteDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style} id={"scrollableBox"}>
                    <Box sx={{position: "sticky", top: 0, left: 0, background: 'white', zIndex: 1}}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{pt: 2}}>
                            <Button variant={"outlined"}
                                    color={"primary"}
                                    onClick={() => setCreateDialog(true)}
                            >
                                {t("add_keyword")}
                            </Button>
                            <IconButton onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <SearchKeywords search={search} searchCallback={(value) => {
                            setSearch(value);
                            setPage(-1);
                        }}/>

                        <Box sx={{py: 2}} display={"flex"} flexDirection={"row"} alignItems={"center"}
                             justifyContent={"space-between"}>
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <Button variant={"contained"}
                                        color={"info"}
                                        onClick={selectAll}
                                >
                                    {
                                        checked.length === keywords.length ? t("cancel_all") : t("select_all")
                                    }
                                </Button>

                                {
                                    checked.length > 0 ?
                                        <Typography variant={"subtitle2"} sx={{ml: 1}}>
                                            {t("selected")}: {checked.length}
                                        </Typography>
                                        : ""
                                }
                            </Box>

                            <Box>
                                {
                                    checked.length > 0 ? <Button disabled={isDeleting}
                                                                 onClick={deleteMany}
                                                                 color={"error"}
                                                                 variant={"contained"}
                                    >
                                        <DeleteIcon/>
                                    </Button> : ""
                                }
                            </Box>
                        </Box>

                        {
                            loading ? <LinearProgress sx={{my: 2}}/> : ""
                        }
                    </Box>

                    {
                        keywords.length > 0 ? <List>
                            {
                                keywords.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ListItem>
                                                <ListItemButton role={undefined} dense
                                                                onClick={() => handleToggleCheckbox(item.id)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(item.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{'aria-labelledby': item.id}}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} sx={{flexShrink: 0}}
                                                                  primaryTypographyProps={{
                                                                      fontSize: 14,
                                                                      fontWeight: 'bold',
                                                                      letterSpacing: 0
                                                                  }}/>
                                                </ListItemButton>


                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    {
                                                        item.keyword_types.map((keywordType, ind) => {
                                                            if (keywordType.type === "desktop") {
                                                                return (
                                                                    <ScreenSearchDesktopIcon key={ind}
                                                                                             fontSize={"small"}
                                                                                             sx={{marginLeft: 1}}/>
                                                                )
                                                            }
                                                            if (keywordType.type === "android") {
                                                                return (
                                                                    <AndroidIcon key={ind} fontSize={"small"}
                                                                                 sx={{marginLeft: 1}}
                                                                                 color={"success"}/>
                                                                )
                                                            }
                                                            if (keywordType.type === "iphone") {
                                                                return (
                                                                    <AppleIcon key={ind} fontSize={"small"}
                                                                               sx={{marginLeft: 1}}
                                                                               color={"info"}/>
                                                                )
                                                            }

                                                            return ""
                                                        })
                                                    }

                                                    <Chip sx={{marginLeft: 5}}
                                                          label={country(item.country, countries)}/>

                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginLeft: 5
                                                    }}>
                                                        <IconButton color={"warning"}
                                                                    onClick={() => {
                                                                        setEditDialogId(item.id)
                                                                        setEditDialog(true)
                                                                    }}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                        <IconButton color={"error"}
                                                                    disabled={isDeleting}
                                                                    onClick={() => {
                                                                        setDeleteKeyword(item.id);
                                                                        setDeleteDialog(true);
                                                                    }}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </ListItem>
                                            <Divider sx={{listStyle: "none"}} component="li"/>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </List> : <Typography sx={{marginTop: 4}} textAlign={"center"}
                                              variant={"h5"}>{t("no_keywords")}</Typography>
                    }
                </Box>
            </Modal>

            <CreateDialog open={createDialog}
                          countries={countries}
                          projectId={projectId}
                          handleClose={() => {
                              setCreateDialog(false);
                              setPage(-1);
                          }}
            />

            <EditDialog open={editDialog}
                        countries={countries}
                        projectId={projectId}
                        keywordId={editDialogId}
                        handleClose={() => {
                            setEditDialog(false);
                            setPage(-1);
                        }}
            />

            <AlertDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t("delete_dialog_title")}
                content={t("delete_dialog_content")}
                handleCancel={() => setDeleteDialog(false)}
                handleOk={handleDeleteKeyword}
            />
        </div>
    )
}