import moment from "moment";
import {Link, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import LaunchIcon from "@mui/icons-material/Launch";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {grey} from "@mui/material/colors";
import _ from "underscore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import i18n from "../../../../lang/i18n";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import toastr from "toastr"
import {getPrettifiedDomain} from "../../../../utils/Domain";

const greyColor = grey[500];

export const adUrl = (params, onLinkClick, hideDate = false) => {
    const prettifyDomain = getPrettifiedDomain(params.row.ad_url);

    const firstSeenAt = moment(params.row.first_seen_at).format('YYYY-MM-DD').toString();
    const lastSeenAt = moment(params.row.last_seen_at).format('YYYY-MM-DD').toString();

    const link = params.row.ad_url.indexOf('http') > -1 ? params.row.ad_url : 'https://' + params.row.ad_url;

    return (
        <div>
            <div>
                {/*<Tooltip title={params.row.ad_url}>*/}
                    <Link href={link}
                          target="_blank"
                          color={greyColor}
                          underline={"always"}
                          flex="row"
                          alignItems={"center"}
                          onClick={onLinkClick || null}
                    >
                        <Box sx={{display: "flex", alignItems: 'center'}}>
                            {
                                prettifyDomain.indexOf("play.google.com") > -1 ?
                                    <AndroidIcon color={"success"} sx={{marginRight: 1}} fontSize={"8"}/>
                                    : prettifyDomain.indexOf("apps.apple.com") > -1 ?
                                        <AppleIcon color={"info"} sx={{marginRight: 1}} fontSize={"8"}/>
                                        : <LaunchIcon color={greyColor} sx={{marginRight: 1}} fontSize={"8"}/>
                            }
                            {prettifyDomain}
                        </Box>
                    </Link>
                {/*</Tooltip>*/}

                {
                    !hideDate ? <Typography sx={{opacity: 0.7}} sx={{fontSize: 12}}>
                        ({firstSeenAt} - {lastSeenAt})
                    </Typography> : ""
                }

                {/*{*/}
                {/*    _.has(params.row, "click_url") ?*/}
                {/*        <CopyToClipboard text={params.row.click_url} onCopy={() => toastr.success(i18n.t("full_ad_url_copied"))} >*/}
                {/*            <Box sx={{*/}
                {/*                lineHeight: 1,*/}
                {/*                marginTop: 2,*/}
                {/*                display: "flex",*/}
                {/*                alignItems: "center",*/}
                {/*                cursor: "pointer",*/}
                {/*                fontSize: 10,*/}
                {/*                opacity: 0.7*/}
                {/*            }}>*/}
                {/*                <ContentCopyIcon sx={{ fontSize: 10, marginRight: "4px"}}/>*/}
                {/*                {i18n.t("full_ad_url")}*/}
                {/*            </Box>*/}
                {/*        </CopyToClipboard> : ""*/}
                {/*}*/}
            </div>
        </div>
    )
}