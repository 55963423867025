import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export const SourceSelector = (props) => {
    const {value, setValue} = props;
    const {t} = useTranslation();

    const sources = [
        {
            id: 0,
            name: t("all_sources")
        },
        {
            id: 1,
            name: t("sites")
        },
        {
            id: 2,
            name: "GooglePlay"
        },
        {
            id: 3,
            name: "AppStore"
        }
    ]

    return (
        <FormControl fullWidth>
            <InputLabel>{t("source")}</InputLabel>
            <Select
                value={value}
                label={t("source")}
                onChange={setValue}
            >
                {
                    sources.map(item => {
                        return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}