import {useEffect, useState} from "react";
import toastr from "toastr";
import {useTranslation} from "react-i18next";
import {
    Avatar, Box, Button, Chip,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton, ListItemIcon,
    ListItemText,
    ListSubheader, Popover, Skeleton
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {ServersService} from "../../../../../services/servers.service";
import Grid from "@mui/material/Grid";
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import {AlertDialog} from "../../../../../components/AlertDialog";
import * as React from "react";
import {CreateServer} from "./CreateServer";

export const UserServers = (props) => {
    const {t} = useTranslation();

    const {user} = props;

    const [parserServers, setParserServers] = useState([]);
    const [reporterServers, setReporterServers] = useState([]);
    const [loading, setLoading] = useState(false)
    const [trigger, setTrigger] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [targetDelete, setTargetDelete] = useState(null);

    useEffect(() => {
        setLoading(true);

        ServersService.getByUser(user.id).then(data => {
            if (data.success) {
                setParserServers(data.data.filter(item => item.type === "parser"))
                setReporterServers(data.data.filter(item => item.type === "reporter"))
            } else {
                toastr.error(t("error_server"));
            }
            setLoading(false)
        }).catch(e => {
            toastr.error(t("error_server"));
            setLoading(false)
        })
    }, [user, trigger]);

    const deleteServer = () => {
        ServersService.deleteServer(targetDelete).then(data => {
            if (data.success) {
                toastr.success(t("server_deleted"));
                setDeleteModal(false);
                setTrigger(!trigger);
            } else {
                toastr.error(t("error_server"));
            }
            setLoading(false)
        }).catch(e => {
            toastr.error(t("error_server"));
            setLoading(false)
        })
    }

    const renderList = (headerTitle, list) => {
        if(loading) {
            return (
                <div>
                    <Skeleton sx={{ mb: 1 }} variant="rectangular" height={40} />
                    <Skeleton sx={{ mb: 1 }} variant="rectangular" height={40} />
                    <Skeleton sx={{ mb: 1 }} variant="rectangular" height={40} />
                    <Skeleton sx={{ mb: 1 }} variant="rectangular" height={40} />
                    <Skeleton sx={{ mb: 1 }} variant="rectangular" height={40} />
                </div>
            )
        } else {
            return (
                <List
                    subheader={
                        <ListSubheader component="div">
                            {headerTitle}
                        </ListSubheader>
                    }
                >
                    {
                        list.map((item, index) => {
                            return (
                                <>
                                    <ListItem
                                        key={item.id}
                                        secondaryAction={
                                            item.external_id !== null ? <IconButton
                                                edge="end"
                                                color={"error"}
                                                onClick={() => {
                                                    setDeleteModal(true);
                                                    setTargetDelete(item.id);
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton> : ""
                                        }
                                    >
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {index + 1}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={
                                                    <Box sx={{mt: 1}}>
                                                        <Chip
                                                            size={"small"}
                                                            variant={"filled"}
                                                            label={item.server_created && item.is_ready ? t("ready") : t("pending")}
                                                            color={item.server_created && item.is_ready ? "success" : "primary"}
                                                        />
                                                    </Box>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>

                                    {
                                        index !== list.length - 1 ? <Divider/> : ''
                                    }
                                </>
                            )
                        })
                    }
                </List>
            )
        }
    }

    return (
        <Box>
            <Box sx={{my: 1}}>
                <CreateServer handleSuccess={() => setTrigger(!trigger)} userId={user.id}/>
            </Box>

            <Grid container>
                <Grid item xs={12} md={6}>
                    {renderList(t("servers_for_parsing"), parserServers)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderList(t("servers_for_reporting"), reporterServers)}
                </Grid>
            </Grid>

            <AlertDialog
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                title={t("title_delete_server")}
                content={t("content_delete_server")}
                handleCancel={() => setDeleteModal(false)}
                handleOk={deleteServer}
            />
        </Box>
    )
}