import axios from "axios";

export class PaymentsService {
    static get(page = 1, perPage = null) {
        let filter = `page=${page}&`;

        if(perPage !== null) {
            filter += `perPage=${perPage}&`;
        }

        return axios.get(`/dashboard/payments?${filter}`).then((response) => {
            return response.data;
        });
    }
}