import axios from "axios";
import * as qs from "query-string";

export class ReportWhiteListService {
    static store(payload) {
        return axios.post(`/dashboard/report-white-list/store`, payload).then((response) => {
            return response.data;
        });
    }

    static delete(id, userId) {
        const filterQuery = qs.stringify({
            'user_id': userId
        });

        return axios.post(`/dashboard/report-white-list/delete/${id}?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static index(page, userId) {
        const filterQuery = qs.stringify({
            'user_id': userId
        });

        return axios.get(`/dashboard/report-white-list?page=${page}&${filterQuery}`).then((response) => {
            return response.data;
        });
    }
}