import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import * as React from "react";
import {Skeleton} from "@mui/material";
import {useEffect, useState} from "react";
import moment from "moment";
import {CustomColors} from "../../../../../themes/custom-colors";

export const ActiveAdsChart = (props) => {
    const {t} = useTranslation();

    const {loading, ads} = props;

    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setData(ads.map(item => item.count))
        setCategories(ads.map(item => item.date))
    }, [ads]);

    const adsChartSettings = {
        series: [
            {
                name: t("ads"),
                data: data
            },
        ],
        options: {
            colors: [CustomColors.orange],
            chart: {
                type: 'line',
                toolbar: {
                    show: false,
                },
                sparkline: {
                    enabled: false
                }
            },
            grid: {
                show: true,
                padding: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    hideOverlappingLabels: true,
                    formatter: function (value) {
                        return moment(value, "YYYY-MM-DD", "uk")
                            .format("D MMM");
                    }
                },
                crosshairs: {
                    show: false,
                },
                type: 'date',
                tickAmount: 7
            },
            yaxis: {
                labels: {
                    show: true,
                }
            },
            legend: {
                show:false,
            },
            fill: {
                opacity: 1,
            },
            stroke: {
                curve: 'straight'
            },
            tooltip: {
                marker: {
                    show: false,
                }
            }
        },
    }

    if(!loading) {
        if(ads.length > 0) {
            return (
                <Chart options={adsChartSettings.options}
                       type={"line"}
                       height={200}
                       series={adsChartSettings.series}
                />
            )
        }
    } else {
        return <Skeleton variant={"rectangular"} height={200}/>
    }
}