import axios from 'axios';
import AuthService from "../services/auth.service";

export const initAxios = () => {
    // axios defaults settings
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post.Accept = 'application/json';

    axios.defaults.params = {}
    axios.defaults.params['lang'] = localStorage.getItem("locale") || "ru"

    axios.interceptors.request.use((config) => {
        const user = AuthService.getCurrentUser();
        if (user !== null) {
            config.headers.Authorization = user.token ? `Bearer ${user.token}` : '';
        }
        return config;
    });
}