import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import toastr from "toastr";
import {InvoicesService} from "../../../services/invoices.service";
import {useTranslation} from "react-i18next";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {RouterLink} from "../../../components/RouterLink";
import {grey} from "@mui/material/colors";
import {ReporterInvoice} from "./ReporterInvoice";
import {ParserInvoice} from "./ParserInvoice";
import {PaymentTypes} from "../../../utils/Constants";

export const Invoice = (props) => {
    let {id} = useParams();
    const {t} = useTranslation();

    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paymentType, setPaymentType] = useState("crypto")
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (id) {
            InvoicesService.getById(id).then(data => {
                if (data.success) {
                    if (data.data !== null) {
                        const insertData = data.data;
                        if (insertData.details) {
                            insertData.details = JSON.parse(insertData.details)
                        }
                        setInvoice(insertData);
                        setPaymentType(insertData.payment_type === null ? "crypto" : insertData.payment_type);
                    }
                }
                setLoading(false)
            }).catch(e => {
                toastr.error(t("error_server"));
                setLoading(false)
            })
        }
    }, []);

    const handlePayment = () => {
        setIsSubmitting(true);
        InvoicesService.makePayment(id, paymentType).then(data => {
            if (data.success) {
                if (data.data) {
                    window.open(data.data.hosted_url, "_blank")
                }
            }
            setIsSubmitting(false);
        }).catch(e => {
            toastr.error(t("error_server"));
            setIsSubmitting(false);
        })
    }

    const renderContent = () => {
        if (invoice === null && !loading) {
            return getErrorLoadTemplate();
        }

        if (invoice.type === "reporter") {
            return <ReporterInvoice id={id} invoice={invoice}/>
        }

        if (invoice.type === "parser") {
            return <ParserInvoice id={id} invoice={invoice}/>
        }

        return getErrorLoadTemplate();
    }

    const getErrorLoadTemplate = () => {
        return <Typography sx={{mb: 1.5}} align={"center"} color="text.secondary">
            {t("no_load_invoice")}
        </Typography>
    }

    return <Card sx={{maxWidth: 600, margin: '0 auto'}}>
        {loading ? <LinearProgress color={"primary"}/> : ""}
        <CardContent>
            {
                !loading ? renderContent() : ""
            }
        </CardContent>
        <CardActions sx={{flexDirection: "column"}}>
            {
                !loading && invoice.status === "pending" ? <FormControl fullWidth={true} variant="standard" sx={{ mb:3, px:3, minWidth: 120 }}>
                    <InputLabel sx={{ ml:3 }} id="payment_type">{t("payment_type")}</InputLabel>
                    <Select
                        disabled={invoice.payment_type !== null}
                        fullWidth={true}
                        labelId="payment_type"
                        id="payment_type"
                        value={paymentType}
                        onChange={(event) => setPaymentType(event.target.value)}
                        label={t("payment_type")}
                    >
                        {
                            PaymentTypes.map(type => {
                                return (
                                    <MenuItem value={type.id}>
                                        {t(type.label)}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl> : ""
            }

            {
                !loading && invoice ? invoice.status === "pending" ?
                    <Button sx={{margin: "0 auto"}} disabled={isSubmitting} onClick={handlePayment} variant={"outlined"}>
                        {t("go_pay")}
                    </Button> : "" : ""
            }

            <RouterLink
                text={t("return_to_home")}
                href={"/dashboard"}
                sx={{display: "block", my: 2, opacity: .9, color: grey[400]}}
            />
        </CardActions>
    </Card>
}