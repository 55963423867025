import {Box, Chip, Grid, IconButton, Paper, Typography} from "@mui/material";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {grey} from "@mui/material/colors";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../../utils/Date";
import {useEffect, useState} from "react";
import toastr from "toastr";
import {KeywordsService} from "../../../../services/keywords.service";
import {KeywordsStatisticsFilter} from "./KeywordsStatisticsFilter";
import {KeywordsStatisticsModal} from "./KeywordsStatisticsModal";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {KeywordsStatisticsDailyChart} from "./KeywordsStatisticsDailyChart";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {KeywordsStatisticsHourlyChart} from "./KeywordsStatisticsHourlyChart";
import LanguageIcon from '@mui/icons-material/Language';

const grey400 = grey[400];

const sortDefault = {
    field: "sum_score",
    order: "desc"
};

export const KeywordsStatistics = () => {
    const {t} = useTranslation();

    const pageSize = 24;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [filter, setFilter] = useState({
        keywordId: null,
        projectId: null,
        startDate: getDatePickerStartDate(),
        endDate: getDatePickerEndDate(),
    });
    const [sort, setSort] = useState(sortDefault);

    const [chartModal, setChartModal] = useState(false);
    const [chartModalData, setChartModalData] = useState({
        keyword_id: null,
        keyword_name: null,
        project_id: null,
        project_name: null
    });
    const [chartModalType, setChartModelType] = useState('day');

    const columns = [
        {
            field: 'project_name',
            headerName: t("project"),
            width: 200,
            sortable: true,
        },
        {
            field: 'keyword_name',
            headerName: t("keyword"),
            width: 170,
            sortable: true,
            renderCell: (params) => {
                return <strong>{params.row.keyword_name}</strong>;
            }
        },
        {
            field: 'sum_score',
            headerName: t("total_ads_displays"),
            width: 180,
            sortable: true,
            align: 'center',
            renderCell: (params) => {
                return <Chip label={params.row.sum_score} color={'info'}/>
            }
        },
        {
            field: "action",
            headerName: t('statistics'),
            sortable: false,
            width: 130,
            renderCell: (params) => {
                return <Box>
                    <Box>
                        <IconButton onClick={() => {
                            setChartModelType('day');
                            setChartModalData({
                                project_id: params.row.project_id,
                                project_name: params.row.project_name,
                                keyword_id: params.row.keyword_id,
                                keyword_name: params.row.keyword_name,
                            })
                            setChartModal(true);
                        }}>
                            <CalendarMonthIcon color="info"/>
                        </IconButton>
                        <IconButton onClick={() => {
                            setChartModelType('hour');
                            setChartModalData({
                                project_id: params.row.project_id,
                                project_name: params.row.project_name,
                                keyword_id: params.row.keyword_id,
                                keyword_name: params.row.keyword_name,
                            })
                            setChartModal(true);
                        }}>
                            <QueryBuilderIcon color="warning"/>
                        </IconButton>
                        <IconButton onClick={() => {
                            setChartModelType('domains');
                            setChartModalData({
                                project_id: params.row.project_id,
                                project_name: params.row.project_name,
                                keyword_id: params.row.keyword_id,
                                keyword_name: params.row.keyword_name,
                            })
                            setChartModal(true);
                        }}>
                            <LanguageIcon color="success"/>
                        </IconButton>
                    </Box>
                </Box>
            }
        }
    ]

    useEffect(() => {
        setPage(-1)
    }, [filter, sort])

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            KeywordsService.getAvgTopCountAds(
                page + 1,
                pageSize,
                sort.field,
                sort.order,
                filter.startDate,
                filter.endDate,
                filter.keywordId,
                filter.projectId
            ).then(data => {
                if (data.data) {
                    setData(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    const handleSortModelChange = (sortModel) => {
        if (sortModel.length === 0) {
            setSort(sortDefault)
        } else {
            setSort({
                field: sortModel[0].field,
                order: sortModel[0].sort
            })
        }
        setPage(0);
    };

    return (
        <Box>
            <Box sx={{
                marginBottom: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Typography variant="h5">
                    {t("average_keyword_position_in_search_results")}
                </Typography>
            </Box>

            <Box mb={2}>
                <KeywordsStatisticsFilter filter={filter} onFilterChange={(newFilterData) => {
                    setFilter(newFilterData)
                }}/>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={3}>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            autoHeight={true}
                            sx={{
                                border: 0,
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                                '& .MuiDataGrid-columnSeparator': {
                                    color: grey400,
                                },
                                '& .MuiDataGrid-withBorder': {
                                    borderColor: grey400,
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    borderColor: grey400,
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    borderColor: grey400,
                                }
                            }}
                            disableSelectionOnClick={true}
                            showCellRightBorder={true}
                            rows={data}
                            columns={columns}
                            paginationMode={"server"}
                            page={page < 0 ? 0 : page}
                            getRowId={(option) => option.keyword_id + option.project_id}
                            onPageChange={(newPage) => setPage(newPage)}
                            pageSize={pageSize}
                            rowCount={rowCount}
                            disableColumnMenu={true}
                            loading={loading}
                            disableColumnSelector={true}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            filterMode="server"
                            sortingMode="server"
                            onSortModelChange={handleSortModelChange}
                        />

                        <KeywordsStatisticsModal
                            open={chartModal}
                            handleClose={() => setChartModal(false)}
                            incomingData={chartModalData}
                            type={chartModalType}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <KeywordsStatisticsDailyChart filter={filter}/>
                    <KeywordsStatisticsHourlyChart filter={filter}/>
                </Grid>
            </Grid>
        </Box>
    )
}