import {Box, Toolbar} from "@mui/material";
import AppBarStyled from "./AppBarStyled";
import {useTheme} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {Logo} from "./Logo";
import {DesktopMenu} from "./DesktopMenu/DesktopMenu";
import Profile from "./Profile";
import {MobileMenu} from "./MobileMenu/MobileMenu";


export const HeaderTop = (props) => {
    const theme = useTheme();
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <AppBarStyled {...appBar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <MobileMenu/>
                    <Logo />
                    <DesktopMenu />
                    <Box sx={{width: "100%", display: { xs: 'flex', md: 'none' }}}/>
                    <Profile />
                </Toolbar>
            </Container>
        </AppBarStyled>
    )
}