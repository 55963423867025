import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

export const SelectorType = (props) => {
    const {t} = useTranslation();

    const {value, setValue} = props;

    const values = [
        {
            id: "day",
            name: t("by_day")
        },
        {
            id: "month",
            name: t("by_month")
        },
    ]

    return (
        <FormControl variant="standard">
            <InputLabel id="demo-simple-select-standard-label">
                {t("statistic_by")}
            </InputLabel>
            <Select
                value={value}
                onChange={setValue}
                label={t("statistic_by")}
            >
                {
                    values.map(item => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}