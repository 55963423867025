import axios from "axios";
import * as qs from "query-string";

export class ReporterService {
    static get(page = 1, perPage = null, inputFilter = {}) {
        const filterQuery = qs.stringify({
            ...inputFilter,
            page,
            perPage
        });

        return axios.get(`/dashboard/report-campaign?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static triggerStart(campaignId) {
        return axios.post(`/dashboard/report-campaign/start/${campaignId}`).then((response) => {
            return response.data;
        });
    }

    static create(data) {
        return axios.post(`/dashboard/report-campaign/`, data).then((response) => {
            return response.data;
        });
    }

    static delete(campaignId) {
        return axios.post(`/dashboard/report-campaign/delete/${campaignId}`).then((response) => {
            return response.data;
        });
    }

    static getLog(filter = {}) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/report/log?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getLogDetailed(filter = {}) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/report/log/detailed?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAll() {
        return axios.get(`/dashboard/report-campaign/get/all`).then((response) => {
            return response.data;
        });
    }

    static addToCampaign(id, payload) {
        return axios.post(`/dashboard/report-campaign/addItem/${id}`, payload).then((response) => {
            return response.data;
        });
    }

    static addClickUrlToCampaign(id, payload) {
        return axios.post(`/dashboard/report-campaign/addClickUrl/${id}`, payload).then((response) => {
            return response.data;
        });
    }
}