import {Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover} from "@mui/material";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import CampaignIcon from "@mui/icons-material/Campaign";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {AlertDialog} from "../../../../../components/AlertDialog";
import {ReporterService} from "../../../../../services/reporter.service";
import toastr from "toastr";
import {ServersService} from "../../../../../services/servers.service";

export const CreateServer = (props) => {
    const {userId, handleSuccess} = props;
    const [createDialog, setCreateDialog] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [targetCreateType, setTargetCreateType] = useState("parser");

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'create-popover' : undefined;

    const handleCreate = () => {
        setCreateLoading(true);
        ServersService.createServer(userId, targetCreateType).then(data => {
            if (data.success) {
                toastr.success(t("server_created"));
                handleSuccess();
                handleClose();
                setCreateDialog(false);
            } else {
                toastr.error(t("error_server"));
            }

            setCreateLoading(false);
        }).catch(e => {
            setCreateLoading(false);
            toastr.error(t("error_server"));
        })
    }

    return (
        <>
            <Button
                onClick={handleClick}
                color={"primary"}
                variant={"contained"}
                aria-describedby={id}
            >
                {t("add_server")}
            </Button>

            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItem onClick={() => {
                        setTargetCreateType("parser");
                        setCreateDialog(true);
                    }}>
                        <ListItemButton>
                            <ListItemIcon sx={{mr: 1}}>
                                <SettingsInputComponentIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={t("add_parsing_server")}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        setTargetCreateType("reporter");
                        setCreateDialog(true);
                    }}>
                        <ListItemButton>
                            <ListItemIcon sx={{mr: 1}}>
                                <CampaignIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={t("add_reporting_server")}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>

            <AlertDialog
                open={createDialog}
                handleClose={() => setCreateDialog(false)}
                title={t(targetCreateType === "parser" ? "title_add_parsing_server" : "title_add_reporting_server")}
                content={t("content_add_server")}
                handleCancel={() => setCreateDialog(false)}
                handleOk={handleCreate}
                loading={createLoading}
            />
        </>
    )
}