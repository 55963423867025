import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const ClipboardTextField = (props) => {
    return (
        <FormControl fullWidth={true} sx={{my: 3}} variant="outlined">
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <OutlinedInput
                fullWidth={true}
                id={props.id}
                type="text"
                readOnly={true}
                value={props.value}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            data-clipboard-target={`#${props.id}`}
                            edge="end"
                        >
                            <ContentCopyIcon/>
                        </IconButton>
                    </InputAdornment>
                }
                label={props.label}
            />
        </FormControl>
    )
}