import {Box, Button, Card, CardContent, Chip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ComputerIcon from '@mui/icons-material/Computer';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import {ParserSubscriptionEdit} from "./ParserSubscriptionEdit";
import {useState} from "react";
import moment from "moment";
import {CurrentParserSubscriptionEdit} from "../../../../../user/subscriptions/parser/CurrentParserSubscriptionEdit";

export const ParserSubscriptionCard = (props) => {
    const {subscription, userId, onUpdate, isCurrent} = props;
    const {t} = useTranslation();

    const [editModal, setEditModal] = useState(false);

    const renderStatus = () => {
        if (!subscription) return "";

        if (subscription.status) {
            return (
                <Chip size={"small"} label={t("is_active")} color={"success"}/>
            )
        } else {
            return (
                <Chip size={"small"} label={t("no_active")} color={"secondary"}/>
            )
        }
    }
    const renderCustomLabel = () => {
        if (!subscription) return "";

        if (subscription.is_custom) {
            return (
                <Chip sx={{mr: 1}} size={"small"} label={t("custom_subscription")} color={"warning"}/>
            )
        }
    }

    const renderHasDesktop = () => {
        if (!subscription) return "";

        if (subscription.has_desktop) {
            return (
                <Chip sx={{mr: 1}} size={"small"} icon={<ComputerIcon/>} label={t("desktop")} color={"warning"}/>
            )
        }

        return "";
    };

    const renderHasAndroid = () => {
        if (!subscription) return "";

        if (subscription.has_android) {
            return (
                <Chip sx={{mr: 1}} size={"small"} icon={<AndroidIcon/>} label="Android" color={"success"}/>
            )
        }

        return "";
    };

    const renderHasIphone = () => {
        if (!subscription) return "";

        if (subscription.has_iphone) {
            return (
                <Chip sx={{mr: 1}} size={"small"} icon={<AppleIcon/>} label="IPhone" color={"info"}/>
            )
        }

        return "";
    };

    return (
        <Card>
            <CardContent>
                <Box sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <Typography variant={"h4"}>
                        {t("parser_subscription")}
                    </Typography>
                    <div>
                        {renderCustomLabel()}
                        {renderStatus()}
                    </div>
                </Box>

                {
                    subscription.status ? <Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Box sx={{p: 2}}>
                                <Typography variant={"body2"} align={"center"}>{t("parser_interval")}</Typography>
                                <Typography variant={"h5"}
                                            align={"center"}>{!!subscription ? subscription.hour_interval : ""} / {t("day")}</Typography>
                            </Box>
                            <Box sx={{p: 2}}>
                                <Typography variant={"body2"} align={"center"}>{t("keywords_count")}</Typography>
                                <Typography variant={"h5"}
                                            align={"center"}>{!!subscription ? subscription.keywords_count : ""}</Typography>
                            </Box>
                            <Box sx={{p: 2}}>
                                <Typography variant={"body2"} align={"center"}>{t("subscription_price")}</Typography>
                                <Typography variant={"h5"}
                                            align={"center"}>{!!subscription ? subscription.cost + "$" : ""}</Typography>
                            </Box>
                        </Box>

                        {
                            !isCurrent ? <Box sx={{
                                mb: 2,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                {renderHasDesktop()}
                                {renderHasAndroid()}
                                {renderHasIphone()}
                            </Box> : ""
                        }

                        <Typography variant={"caption"} gutterBottom align={"center"} display={"block"}
                                    color={"text.secondary"}>
                            {t("subscription_expires_date")} <br/>
                            {moment(subscription.expires_date).format("YYYY-MM-DD")}
                        </Typography>

                        <Typography variant={"caption"} gutterBottom align={"center"} display={"block"}
                                    color={"text.secondary"}>
                            {t("page_per_parse")} <br/>
                            {!!subscription ? subscription.page_per_parse : ""}
                        </Typography>

                    </Box> : <Typography gutterBottom align={"center"}
                                         variant={"h6"}>{t("subscription_is_not_active")}</Typography>
                }

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {
                        !subscription.is_custom || !isCurrent ? <Button
                            size={"small"}
                            variant={"outlined"}
                            onClick={() => setEditModal(true)}
                        >
                            {isCurrent ? t("extend_order") : t("edit")}
                        </Button> : ""
                    }
                </Box>
            </CardContent>

            {
                !isCurrent ? <ParserSubscriptionEdit
                    open={editModal}
                    handleClose={() => {
                        setEditModal(false)
                        onUpdate();
                    }}
                    subscription={subscription}
                    userId={userId}
                /> : <CurrentParserSubscriptionEdit open={editModal}
                                                    handleClose={() => {
                                                        setEditModal(false)
                                                        onUpdate();
                                                    }}
                                                    subscription={subscription}
                />
            }
        </Card>
    )
}