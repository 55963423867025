import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Chip, Typography} from "@mui/material";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {grey} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {PaymentsService} from "../../../services/payments.service";
import moment from "moment/moment";

const grey400 = grey[400];
const pageSize = 12;

export const Payments = (props) => {
    const {t} = useTranslation();

    const [payments, setPayments] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);


    const columns = [
        {
            field: 'invoice_id',
            headerName: "ID",
            width: 140,
            sortable: false,
            renderCell: params => {
                return <strong>{params.value}</strong>
            }
        },
        {
            field: 'type',
            headerName: t("payment_type"),
            width: 180,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: params => {
                return <Chip size={"small"} color={"primary"} label={t(params.value)}/>
            }
        },
        {
            field: 'invoice.sum',
            headerName: t("sum"),
            width: 120,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: params => {
                return params.row.invoice.sum + " " + params.row.invoice.currency.toUpperCase()
            }
        },
        {
            field: 'invoice.type',
            headerName: t("destination"),
            width: 250,
            sortable: false,
            renderCell: params => {
                return t("payment_for", {
                    service: t(params.row.invoice.type)
                })
            }
        },
        {
            field: 'created_at',
            headerName: t("created_at"),
            width: 200,
            sortable: false,
        },
        {
            field: 'invoice.details',
            headerName: t("details"),
            width: 300,
            sortable: false,
            renderCell: params => {
                const details = params.row.invoice.details !== null ? JSON.parse(params.row.invoice.details) : [];

                return <ul>
                    {
                        Object.keys(details).map((detailIndex, index) => {
                            return <li key={index}>
                                <strong>{t("p_" + detailIndex)}:</strong>&nbsp;{details[detailIndex]}
                            </li>
                        })
                    }
                </ul>
            }
        },
    ]


    useEffect(() => {
        if(page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            PaymentsService.get(page + 1, pageSize).then(data => {
                if (data.data) {
                    setPayments(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    return <div>
        <Box sx={{
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography variant="h5">
                {t("billing")}
            </Typography>
        </Box>

        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight={true}
            sx={{
                border: 1,
                borderColor: grey400,
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                '& .MuiDataGrid-columnSeparator': {
                    color: grey400,
                },
                '& .MuiDataGrid-withBorder': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-footerContainer': {
                    borderColor: grey400,
                }
            }}
            disableSelectionOnClick={true}
            showCellRightBorder={true}
            rows={payments}
            columns={columns}
            paginationMode={"server"}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowCount={rowCount}
            disableColumnMenu={true}
            loading={loading}
            disableColumnSelector={true}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            filterMode="server"
        />
    </div>
}