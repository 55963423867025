import {Box, Typography} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {KeywordSiteDateExport} from "./KeywordSiteDateExport";

export const Exports = () => {
    const {t} = useTranslation();

    return (
        <Box>
            <Typography variant="h5">
                {t("exports")}
            </Typography>

            <Box>
                <KeywordSiteDateExport/>
            </Box>
        </Box>
    )
}