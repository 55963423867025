import {
    Avatar,
    Box,
    Divider,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Skeleton,
    Typography
} from "@mui/material";
import * as React from "react"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {AdsService as AnalyticsService} from "../../../../services/analytics.service";
import {CustomColors} from "../../../../themes/custom-colors";
import {TopKeywordsModal} from "./TopKeywordsModal";

const colors = [CustomColors.purple, CustomColors.blue, CustomColors.green, CustomColors.orange, CustomColors.black]


export const TopKeywords = (props) => {
    const {t} = useTranslation();
    const {filter} = props;

    const [keywords, setKeywords] = useState([]);
    const [loading, setLoading] = useState(true);

    const [modalOpened, setModalOpened] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState(null);

    useEffect(() => {
        setLoading(true);
        AnalyticsService.getTopKeywords(filter).then(data => {
            if (data.data) {
                setKeywords(data.data)
            }

            setLoading(false);
        }).catch(e => {
            setLoading(false);
        });
    }, [filter]);

    const openModal = (id, name) => {
        setSelectedKeyword({
            id,
            name
        })
        setModalOpened(true);
    }

    if (!loading) {
        if (keywords.length > 0) {
            return (
                <>
                    <List component={Paper} sx={{height: 350, overflow: 'auto'}}>
                        {
                            keywords.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ListItemButton
                                            onClick={() => openModal(item.keyword_id, item.name)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar sx={{bgcolor: colors[index % colors.length]}}>
                                                    {index + 1}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name}/>
                                            <Box sx={{textAlign: "center"}}>
                                                <Typography variant={"h5"} color={CustomColors.black}>
                                                    {item.count}
                                                </Typography>
                                                <Typography variant={"h5"} color={"grey.300"} fontSize={8}>
                                                    {t("ads_1")}
                                                </Typography>
                                            </Box>
                                        </ListItemButton>

                                        {
                                            keywords.length - 1 !== index ? <Divider/> : ""
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </List>
                    <TopKeywordsModal
                        open={modalOpened}
                        setOpen={setModalOpened}
                        keyword={selectedKeyword}
                    />
                </>
            );
        } else {
            return (
                <Paper sx={{p: 3}}>
                    <Typography variant={"h5"}>{t("no_data")}</Typography>
                </Paper>
            )
        }
    } else {
        return (
            <Paper>
                <Skeleton variant={"rectangular"} height={50} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={50} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={50} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={50} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={50}/>
            </Paper>
        )
    }
}