import {useEffect, useState, useTransition} from "react";
import {ServersService} from "../../../../../services/servers.service";
import toastr from "toastr";
import {UserService} from "../../../../../services/user.service";
import {useTranslation} from "react-i18next";
import {Grid, Skeleton} from "@mui/material";
import {ParserSubscriptionCard} from "./parser/ParserSubscriptionCard";
import {ReporterSubscriptionCard} from "./reporter/ReporterSubscriptionCard";

export const UserSubscriptions = (props) => {
    const {user} = props;
    const {t} = useTranslation();
    
    const [parserSubscription, setParserSubscription] = useState(null);
    const [reporterSubscription, setReporterSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(user) {
            UserService.getSubscriptions(user.id).then(data => {
                if (data.success) {
                    setParserSubscription(data.data.parser);
                    setReporterSubscription(data.data.reporter);
                }
                setLoading(false)
            }).catch(e => {
                toastr.error(t("error_server"));
                setLoading(false)
            })
        }
    }, [user, trigger]);

    const handleUpdate = () => {
        setTrigger(!trigger);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                {
                    loading ? <Skeleton variant="rectangular" height={100} /> :
                        <ParserSubscriptionCard onUpdate={handleUpdate} subscription={parserSubscription} userId={user.id}/>
                }
            </Grid>
            <Grid item xs={12} md={5}>
                {
                    loading ? <Skeleton variant="rectangular" height={100} /> :
                        <ReporterSubscriptionCard onUpdate={handleUpdate} subscription={reporterSubscription} userId={user.id}/>
                }
            </Grid>
        </Grid>
    )
}