import {DataGrid, ruRU} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ParserService} from "../../../services/parser.service";
import {ParserLogFilter} from "./ParserLogFilter";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../utils/Date";

const grey400 = grey[400];

export const ParserLog = () => {
    const {t} = useTranslation();

    const pageSize = 24;
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [filter, setFilter] = useState({
        proxy_id: null,
        user_id: null,
        project_id: null,
        start_date: getDatePickerStartDate(),
        end_date: getDatePickerEndDate(),
    });

    const columns = [
        {
            field: 'date',
            headerName: t("date"),
            width: 100,
            sortable: true,
        },
        {
            field: 'count',
            headerName: t("page_count"),
            width: 120,
            sortable: false,
            renderCell: params => {
                return <strong>{params.row.count}</strong>
            }
        }
    ]

    useEffect(() => {
        setPage(-1)
    }, [filter])

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ParserService.get({
                page: page + 1,
                perPage: pageSize,
                ...filter
            }).then(data => {
                if (data.data) {
                    setLogs(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    return <div>
        <Box sx={{
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography variant="h5">
                {t("parser_statistics")}
            </Typography>
        </Box>

        <ParserLogFilter filter={filter} setFilter={setFilter}/>

        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight={true}
            sx={{
                border: 1,
                borderColor: grey400,
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                '& .MuiDataGrid-columnSeparator': {
                    color: grey400,
                },
                '& .MuiDataGrid-withBorder': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-footerContainer': {
                    borderColor: grey400,
                },
                maxWidth: 400
            }}
            disableSelectionOnClick={true}
            showCellRightBorder={true}
            rows={logs}
            columns={columns}
            paginationMode={"server"}
            page={page < 0 ? 0 : page}
            getRowId={(option) => option.date}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowCount={rowCount}
            disableColumnMenu={true}
            loading={loading}
            disableColumnSelector={true}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            filterMode="server"
        />
    </div>
}