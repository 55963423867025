import axios from "axios";

export class ProxyService {
    static get(page = 1, perPage = null) {
        let filter = `page=${page}&`;

        if(perPage !== null) {
            filter += `perPage=${perPage}&`;
        }

        return axios.get(`/dashboard/proxy?${filter}`).then((response) => {
            return response.data;
        });
    }

    static getAll() {
        return axios.get(`/dashboard/proxy/get/all`).then((response) => {
            return response.data;
        });
    }

    static create(data) {
        return axios.post(`/dashboard/proxy/`, data).then((response) => {
            return response.data;
        });
    }

    static toggleActive(id) {
        return axios.post(`/dashboard/proxy/toggle-active/${id}`).then((response) => {
            return response.data;
        });
    }

    static update(id, data) {
        return axios.post(`/dashboard/proxy/${id}`, data).then((response) => {
            return response.data;
        });
    }

    static delete(id) {
        return axios.post(`/dashboard/proxy/delete/${id}`).then((response) => {
            return response.data;
        });
    }

    static getById(id) {
        return axios.get(`/dashboard/proxy/${id}`).then((response) => {
            return response.data;
        });
    }
}