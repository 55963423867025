import {Autocomplete, Button, CircularProgress, Dialog, DialogTitle, Menu, MenuItem, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AdsService} from "../../services/ads.service";
import Box from "@mui/material/Box";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as React from "react";
import {useState} from "react";
import toastr from "toastr"

export const AdsExportDialog = (props) => {
    const {t} = useTranslation();

    const columns = [
        {
            id: "id",
            name: "ID"
        },
        {
            id: "project_name",
            name: t("project_name")
        },
        {
            id: "keywords",
            name: t("keywords")
        },
        {
            id: "title",
            name: t("title")
        },
        {
            id: "description",
            name: t("description")
        },
        {
            id: "ad_url",
            name: t("site")
        },
        {
            id: "click_url",
            name: t("full_ad_url")
        },
        {
            id: "is_app",
            name: t("is_app")
        },
        {
            id: "is_desktop",
            name: t("is_desktop")
        },
        {
            id: "is_iphone",
            name: t("is_iphone")
        },
        {
            id: "is_android",
            name: t("is_android")
        },
        {
            id: "country",
            name: t("country")
        },
        {
            id: "first_seen_at",
            name: t("first_seen_at")
        },
        {
            id: "last_seen_at",
            name: t("last_seen_at")
        }
    ];

    const {open, handleClose, filter} = props;

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [loading, setLoading] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const dropdownOpen = Boolean(anchorEl);
    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const exportAllRows = (type) => {
        if(!loading) {
            setLoading(true);
            if(selectedColumns.length === 0) {
                toastr.warning(t("error_export_no_column"));
                setLoading(false)
                return;
            }

            const select = selectedColumns.map(item => item.id);

            AdsService.exportAds(filter, select, type).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');

                const currentTimestamp = Math.floor(Date.now() / 1000);
                const fileName = `ads_export_${currentTimestamp}.${type}`;

                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                link.remove();
                setLoading(false)
            }).catch(e => {
                toastr.error(t("error_export_no_column"));
                setLoading(false)
            })
        }
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <Box sx={{padding: 3}}>
                <DialogTitle sx={{fontWeight: 700, fontSize: 18, textAlign: "center"}}>
                    {t("ads_export_dialog_title")}
                </DialogTitle>

                <Autocomplete
                    multiple
                    value={selectedColumns}
                    onChange={(event, value, reason, details) => {
                        setSelectedColumns(value);
                    }}
                    disableCloseOnSelect
                    options={columns}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={t("export_columns")}
                        />
                    )}
                />

                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 4
                }}
                >
                    <Button
                        disabled={loading}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleDropdownClick}
                        startIcon={<FileDownloadIcon/>}
                        variant={"outlined"}
                    >
                        {t("export")}

                        {
                            loading ? <CircularProgress sx={{ marginLeft: 2 }} size={20} /> : ""
                        }
                    </Button>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={dropdownOpen}
                        onClose={handleDropdownClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => {exportAllRows("xlsx");handleDropdownClose();}}>EXCEL</MenuItem>
                        <MenuItem onClick={() => {exportAllRows("csv");handleDropdownClose();}}>CSV</MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Dialog>
    )
}