import i18n from "../lang/i18n";

export const deviceTypes = [{
    id: "desktop", name: i18n.t("desktop")
}, {
    id: "android", name: "Android"
}, {
    id: "iphone", name: "IPhone"
}]

export const parserSteps = [
    {
        value: 8,
        label: "8"
    },
    {
        value: 12,
        label: "12"
    },
    {
        value: 24,
        label: "24"
    },
];

export const parserKeywordsCount = [100, 150, 250];

export const parserFrequency = [
    {
        value: 8,
        label: "every_3_hour"
    },
    {
        value: 12,
        label: "every_2_hour"
    },
    {
        value: 24,
        label: "every_hour"
    }
]

export const reportsCounts = {
    30000: {
        sum: 1600,
        currency: "usd"
    },
    60000: {
        sum: 2500,
        currency: "usd"
    },
    90000: {
        sum: 3400,
        currency: "usd"
    }
}

export const PaymentTypes = [
    {
        "id": "crypto",
        "label": "crypto_payment"
    }
]

export const ParserSubscriptionMonths = [1,3,6,12];