import React from 'react';
import {useNavigate} from 'react-router-dom';

// material-ui
import {Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack} from '@mui/material';

// third party
import * as Yup from 'yup';
import {Formik} from 'formik';

// project import
import AnimateButton from '../../../components/@extended/AnimateButton';

// assets
import AuthService from "../../../services/auth.service";
import {useTranslation} from "react-i18next";
import toastr from "toastr";

const ResetPasswordForm = () => {
    const {t} = useTranslation();
    const [requestSent, setRequestSent] = React.useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(t('email_invalid')).max(255).required(t('required'))
                })}
                onSubmit={(values, {setErrors, setStatus, setSubmitting}) => {
                    setSubmitting(true)
                    AuthService.forgotPassword(values.email)
                        .then(response => {
                            if (response.success) {
                                setRequestSent(true);
                                toastr.success(t("forget_password_success"))
                            } else {
                                setSubmitting(false)
                                setErrors({
                                    submit: t(response.message || "server_error")
                                })
                            }
                        }).catch(e => {
                        setSubmitting(false)
                        setErrors({
                            submit: t("server_error")
                        })
                    })
                }}
            >
                {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login">E-mail</InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t("enter_email")}
                                        fullWidth
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting || requestSent}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {t('reset_password_action')}
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ResetPasswordForm;
