import {Grid} from "@mui/material";
import {DateRangePickerCustom} from "../../../../components/DateRangePickerCustom";
import {setLastDates} from "../../../../utils/Date";
import {ProjectSelector} from "../../../../components/autocompletes/common/ProjectSelector";
import {KeywordSelector} from "../../../../components/autocompletes/common/KeywordSelector";

export const KeywordsStatisticsFilter = ({filter, onFilterChange}) => {
    return <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
            <DateRangePickerCustom
                startDate={filter.startDate}
                endDate={filter.endDate}
                onApply={(startDate, endDate) => {
                    setLastDates(startDate, endDate);
                    const newFilterData = {...filter, startDate: startDate, endDate: endDate};
                    onFilterChange(newFilterData)
                }}
            />
        </Grid>
        <Grid item xs={12} md={4}>
            <ProjectSelector
                value={filter.projectId}
                setValue={(info, newProjectId) => {
                    const newFilterData = {...filter, projectId: newProjectId.id};
                    onFilterChange(newFilterData)}
                }
            />
        </Grid>
        <Grid item xs={12} md={4}>
            <KeywordSelector
                value={filter.keywordId}
                setValue={(newKeyword) => {
                    const newFilterData = {...filter, keywordId: newKeyword.id};
                    onFilterChange(newFilterData)}
                }
            />
        </Grid>
    </Grid>
}