import {IconButton, Link, Modal, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import {KeywordsService} from "../../services/keywords.service";
import {grey} from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 600,
    maxHeight: "90%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styleCloseButton = {
    position: 'absolute',
    top: '10px',
    right: '10px',
};

const grey400 = grey[400];

export const KeywordsModal = (props) => {
    const {t} = useTranslation();
    const {open, adUrl, projectId, inputKeywords, handleClose, onItemSelect, userId} = props;

    const [keywords, setKeywords] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (adUrl != null) {
            KeywordsService.getByAdUrl(adUrl, projectId, userId).then(data => {
                const keysPrepared = data.data.map(item => {
                    item.show = true;
                    return item;
                })
                setKeywords(keysPrepared);
            }).catch(e => {

            })
        } else {
            if (inputKeywords != null) {
                setKeywords(inputKeywords.map(item => {
                    item.show = true;
                    return item;
                }))
            }
        }
    }, [adUrl, inputKeywords]);

    const onSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        const keysPrepared = keywords.map(item => {
            if (item.name.indexOf(value) > -1) {
                item.show = true
            } else {
                item.show = false;
            }
            return item;
        })
        setKeywords(keysPrepared);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton sx={styleCloseButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
                <Box sx={{marginTop: 3}}>
                    <TextField
                        fullWidth={true}
                        label={t("keywords_search")}
                        value={searchValue}
                        onChange={onSearchInputChange}
                    />
                </Box>
                <Box sx={{marginTop: 2}}>
                    {
                        keywords.map(item => {
                            if (item.show) {
                                return (
                                    <Link
                                        key={item.id}
                                        href={"#"}
                                        underline={"always"}
                                        sx={{
                                            display: "inline-block",
                                            marginRight: 2,
                                            marginBottom: 1,
                                            textDecorationStyle: "dashed"
                                        }}
                                        onClick={(e) => {e.preventDefault();onItemSelect(item, projectId);}}
                                    >
                                        {item.name}
                                    </Link>
                                )
                            }
                            return "";
                        })
                    }
                </Box>
                <Typography id="modal-modal-title"
                            color={grey400}
                            fontSize={10}
                            variant="h6"
                            component="h2"
                            sx={{marginTop: 3}}
                >
                    *{t("keywords_by_ad_url")}: {adUrl}
                </Typography>
            </Box>
        </Modal>
    )
}