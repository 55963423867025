import PropTypes from 'prop-types';
import {useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';

// assets
import {DollarCircleOutlined, EditOutlined, LogoutOutlined, WalletOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import AuthService from "../../../../services/auth.service";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton component={Link} to={AuthService.isAdmin() ? "/dashboard/admin/profile" : "/dashboard/profile"}>
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary={t("edit_profile")} />
            </ListItemButton>

            {/*{*/}
            {/*    !AuthService.isAdmin() ? <ListItemButton component={Link} to={"/dashboard/subscriptions"}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <DollarCircleOutlined />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={t("subscription")} />*/}
            {/*    </ListItemButton> : ""*/}
            {/*}*/}
            {/*{*/}
            {/*    !AuthService.isAdmin() ? <ListItemButton component={Link} to={"/dashboard/payments"}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <WalletOutlined />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={t("billing")} />*/}
            {/*    </ListItemButton> : ""*/}
            {/*}*/}

            <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary={t("logout")} />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
