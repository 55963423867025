import {Chip, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {CustomColors} from "../../../themes/custom-colors";
import React from "react";
import {useTranslation} from "react-i18next";

export const ReporterInvoice = (props) => {
    const {t} = useTranslation();
    const {id, invoice} = props;

    const getPaymentStatusChip = (status) => {
        if(status === "success") {
            return <Chip variant={"outlined"} color={"success"} label={t("is_paid")} />
        }
        if(status === "pending") {
            return <Chip variant={"outlined"} color={"primary"} label={t("pending")} />
        }
        if(status === "error") {
            return <Chip variant={"outlined"} color={"error"} label={t("error_or_canceled")} />
        }
    }

    return <>
        <Typography variant={"h1"} align={"center"} color={CustomColors.black} gutterBottom>
            {t("invoice")} #{id}
        </Typography>
        <Typography sx={{ mb: 1.5 }} align={"center"} color="text.secondary">
            {t("invoice_reporter_sub_title")}
        </Typography>
        <TableContainer>
            <Table  aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("sum")}
                        </TableCell>
                        <TableCell align="right">{invoice.sum} $</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("report_count")}
                        </TableCell>
                        <TableCell align="right">
                            {invoice.details.available_reports}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("payment_status")}
                        </TableCell>
                        <TableCell align="right">
                            {getPaymentStatusChip(invoice.status)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
}