import {initAxios} from "./config/axios.js";
import {Routes} from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/common/ScrollTop";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {initClipboard} from "./utils/Clipboard";
import {initMoment} from "./config/moment";

initAxios();
//initMoment();
initClipboard();

function App() {
    return (
        <ThemeCustomization>
            <ScrollTop>
                <Routes/>
            </ScrollTop>
        </ThemeCustomization>
    );
}

export default App;
