import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {KeywordsService} from "../../../services/keywords.service";

export const KeywordSelector = ({value, setValue, keywords = null}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);

    const filterOptions = createFilterOptions({
        limit: 24
    });

    const allItems = [
        {
            id: null,
            name: t("any_keyword")
        }
    ];

    useEffect(() => {
        if (keywords === null) {
            KeywordsService.getAll().then(data => {
                if (data.data) {
                    setData(allItems.concat(data.data));
                }
            }).catch(e => {
                toastr.error(t("error_loading"))
            })
        } else {
            setData(keywords)
        }
    }, [keywords]);

    return <Autocomplete
        filterOptions={filterOptions}
        disablePortal={false}
        options={data}
        getOptionLabel={option => option.name}
        freeSolo
        onChange={(object, newValue) => setValue(newValue)}
        onClick={e => e.stopPropagation()}
        renderInput={(inputParams) =>
            <TextField {...inputParams}
                       fullWidth
                       size={"small"}
                       onClick={e => e.stopPropagation()}
                       variant={"outlined"}
                       label={t("keyword")}
            />
        }
    />
}