import {Link} from "@mui/material";
import {Link as RoutLink} from "react-router-dom"
import * as React from "react";

export const RouterLink = (props) => {
    const {text, href, color} = props;

    return (
        <Link component={RoutLink}
              to={href}
              color={color || "info"}
              sx={props.sx || {}}
              underline={props.underline || "hover"}
        >
            {text}
        </Link>
    )
}