import {useTranslation} from "react-i18next";
import {Box, Button, Dialog, DialogTitle, Grid, LinearProgress, Tab, Tabs, TextField} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {useState} from "react";
import {TabPanel} from "../../../components/TabPanel";
import {useEffect} from "react";
import {UserService} from "../../../services/user.service";
import {UserServers} from "./EditUser/Servers/UserServers";
import {UserSubscriptions} from "./EditUser/Subscriptions/UserSubscriptions";
import AuthService from "../../../services/auth.service";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const EditUserDialog = (props) => {
    const {handleClose, open, userId} = props;
    const {t} = useTranslation();

    const [tabValue, setTabValue] = useState(0)
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        password: '',
        telegram: ''
    });
    const [clickVerified, setClickVerified] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (userId) {
            setLoading(true)
            UserService.getUserById(userId).then(data => {
                if (data.success) {
                    setUser(data.data);

                    const newInitialValues = {
                        name: data.data.name,
                        email: data.data.email,
                        password: '',
                        telegram: data.data.telegram || ''
                    }

                    setInitialValues(newInitialValues);
                } else {
                    toastr.error(t("error_server"));
                }
                setLoading(false)
            }).catch(e => {
                toastr.error(t("error_server"));
                setLoading(false)
            })
        }
    }, [open]);

    const confirmEmail = () => {
        if(!user.id) return false;

        UserService.confirmEmail(user.id).then(data => {
            if (data.success) {
                setClickVerified(true)
                toastr.success(t("confirmed_email"));
            } else {
                toastr.error(t("error_server"));
            }
        }).catch(e => {
            toastr.error(toastr.error(e.response.data.message || t("error_server")));
        })
    }

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("editing_user")}</DialogTitle>

        {
            !loading ? <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label={t("general")} {...a11yProps(0)} />

                        {
                            user.roles[0].name === "user" && user.email_verified_at !== null ? <Tab label={t("servers")} {...a11yProps(1)} /> : null
                        }

                        {
                            user.roles[0].name === "user" && user.email_verified_at !== null ? <Tab label={t("subscriptions")} {...a11yProps(1)} /> : null
                        }
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(t('required')),
                            email: Yup.string().email(t('email_invalid')).max(255).required(t('required')),
                            password: Yup.string().nullable().min(8, t('min_length_8')),
                            telegram: Yup.string().nullable(),
                        })}
                        onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            const payload = {
                                id: userId,
                                name: values.name,
                                email: values.email,
                                password: values.password,
                                telegram: values.telegram
                            };

                            UserService.update(payload).then(data => {
                                if (data.success) {
                                    toastr.success(t("user_general_updated"));
                                } else {
                                    toastr.error(t("error_server"));
                                }

                                setSubmitting(false);
                            }).catch(e => {
                                setSubmitting(false);
                                toastr.error(toastr.error(e.response.data.message || t("error_server")));
                            })
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values,
                              setFieldValue
                          }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Box sx={{flexGrow: 1}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth
                                                       name="name"
                                                       value={values.name}
                                                       label={t("user_name")}
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       error={Boolean(touched.name && errors.name)}
                                                       helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth
                                                       name="email"
                                                       value={values.email}
                                                       label={"E-mail"}
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       error={Boolean(touched.email && errors.email)}
                                                       helperText={Boolean(touched.email && errors.email) ? errors.email : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField fullWidth
                                                       name="password"
                                                       type={"password"}
                                                       value={values.password}
                                                       label={t("password")}
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       error={Boolean(touched.password && errors.password)}
                                                       helperText={Boolean(touched.password && errors.password) ? errors.password : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField fullWidth
                                                       name="telegram"
                                                       value={values.telegram}
                                                       label={"Telegram"}
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       error={Boolean(touched.telegram && errors.telegram)}
                                                       helperText={Boolean(touched.telegram && errors.telegram) ? errors.telegram : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Button disableElevation
                                                    disabled={isSubmitting}
                                                    variant={"outlined"}
                                                    type={"submit"}
                                            >
                                                {t("update")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Button disableElevation
                                                    color={"info"}
                                                    onClick={confirmEmail}
                                                    disabled={user.email_verified_at !== null || clickVerified}
                                                    variant={"outlined"}
                                                    type={"button"}
                                            >
                                                {user.email_verified_at !== null ? t("verified") : t("confirm_email")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </TabPanel>

                {
                    user.roles[0].name === "user" ? <TabPanel value={tabValue} index={1}>
                        <UserServers user={user} />
                    </TabPanel> : ""
                }

                {
                    user.roles[0].name === "user" ? <TabPanel value={tabValue} index={2}>
                        <UserSubscriptions user={user} />
                    </TabPanel> : ""
                }
            </Box> : <LinearProgress />
        }

    </Dialog>)
}