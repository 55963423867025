import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Popover
} from "@mui/material";
import c_ruRU from "date-fns/locale/ru";
import * as React from "react";
import {Calendar, DateRange} from "react-date-range";
import moment from "moment";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";

export const DatePickerCustom = (props) => {
    const {date, onApply, maxDate, minDate, error, onBlur} = props;

    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [localDate, setLocalDate] = useState(null)

    useEffect(() => {
        setLocalDate(date)
    }, [date])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

        onApply(localDate);
    };

    const onChange = (data) => {
        setLocalDate(data)
    }

    const getInputValue = () => {
        return moment(localDate).format("YYYY-MM-DD").toString()
    }

    return (
        <div>
            <FormControl fullWidth variant="outlined" onClick={handleClick} aria-describedby={id}>
                <InputLabel htmlFor="outlined-adornment-password">
                    {t("choose_date")}
                </InputLabel>
                <OutlinedInput
                    value={getInputValue()}
                    fullWidth
                    error={!!error}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                            >
                                <CalendarMonthIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("choose_date")}
                />
                {!!error && (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <Calendar
                        months={window.innerWidth < 768 ? 1 : 2}
                        direction={window.innerWidth < 768 ? "vertical" : "horizontal"}
                        showPreview={true}
                        locale={c_ruRU}
                        date={localDate}
                        maxDate={maxDate !== undefined ? moment(maxDate).toDate() : new Date()}
                        minDate={minDate !== undefined ? moment(minDate).toDate() : moment().subtract(10, "years").toDate()}
                        showDateDisplay={false}
                        onChange={onChange}
                    />
                </Box>
                <Box sx={{ padding: 1, display: "flex", justifyContent: "flex-end"}}>
                    <Button color={"primary"} variant={"contained"} size={"small"} onClick={handleClose}>
                        {t("apply")}
                    </Button>
                </Box>
            </Popover>
        </div>
    )
}