import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import {CustomColors} from "../../../../themes/custom-colors";
import Chart from "react-apexcharts";
import {Paper, Skeleton} from "@mui/material";
import {KeywordsService} from "../../../../services/keywords.service";

export const KeywordsStatisticsDailyChart = ({filter}) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        KeywordsService.getAvgTopCountAdsDaily(
            null,
            null,
            null,
            null,
            filter.startDate,
            filter.endDate,
            filter.keywordId,
            filter.projectId
        ).then(data => {
            setData(data.data);
            setLoading(false);
        });
    }, [filter]);

    var chartSeries = [
        {
            name: t('desktop'),
            data: getDataValues(data, 'desktop')
        },
        {
            name: t('android'),
            data: getDataValues(data, 'android')
        },
        {
            name: t('iphone'),
            data: getDataValues(data, 'iphone')
        },
    ];

    const chartSettings = {
        options: {
            colors: [CustomColors.orange, CustomColors.green, CustomColors.blue],
            chart: {
                height: 300,
                type: 'line',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                }
            },
            title: {
                text: t("avg_top_ads_chart_title"),
                align: 'left',
                style: {
                    fontSize: '14px'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: getDataCategories(data),
                labels: {
                    show: true,
                    hideOverlappingLabels: true
                },
                tickAmount: 7
            },
            yaxis: {
                labels: {
                    show: true,
                }
            },
            legend: {
                show: false,
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                marker: {
                    show: true,
                },
            }
        },
    }

    if (!loading) {
        if (data.length > 0) {
            return (
                <Paper elevation={3} sx={{paddingTop: 1}}>
                    <Chart options={chartSettings.options}
                           type={"area"}
                           height={280}
                           series={chartSeries}
                    />
                </Paper>
            )
        }
    } else {
        return <Paper elevation={3}><Skeleton variant={"rectangular"} height={280}/></Paper>
    }
}

const getDataValues = (data, device = 'desktop') => {
    return data.length > 0 ? data.filter(item => item.device === device).map(item => item.sum_score) : [];
}
const getDataCategories = (data) => {
    const set = new Set();

    if (data.length > 0) {
        data.forEach(item => set.add(item.date));
        return Array.from(set);
    } else {
        return [];
    }
}