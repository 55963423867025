import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Paper, Skeleton} from "@mui/material";
import {CustomColors} from "../../../../themes/custom-colors";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {KeywordsService} from "../../../../services/keywords.service";
import toastr from "toastr";

export const KeywordsStatisticsHourlyChart = ({filter}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    var chartOptions = {
        colors: [CustomColors.orange, CustomColors.green, CustomColors.blue],
        chart: {
            height: 280,
            width: '2000px',
            type: "bar",
            stacked: true,
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: categories
        },
        title: {
            text: t("avg_top_ads_chart_hourly_title"),
            align: 'left'
        },
        legend: {
            show: false
        }
    };

    useEffect(() => {
        if (filter.projectId !== null) {
            setLoading(true);
            KeywordsService.getAvgTopCountAdsByKeywordHourly(
                filter.projectId,
                filter.startDate,
                filter.endDate,
                filter.keywordId
            )
                .then(data => {
                    if (data.data) {
                        const newData = {
                            desktop: {
                                name: t('desktop'),
                                data: []
                            },
                            android: {
                                name: t('android'),
                                data: []
                            },
                            iphone: {
                                name: t('iphone'),
                                data: []
                            }
                        };
                        data.data.forEach(item => {
                            newData[item.device].data.push(item.score);
                        });
                        setCategories(getDataCategories(data.data))
                        setData(Object.values(newData));
                    }
                    setLoading(false);
                }).catch(e => {
                setLoading(false);
                toastr.error(t("error_loading"));
            })
        }
    }, [filter])

    if (!loading) {
        if (data.length > 0) {
            return (
                <Paper elevation={3} sx={{paddingTop: 1, marginTop: 2}}>
                    <Box sx={{overflowX: 'auto', overflowY: 'hidden', height: 280}}>
                        <Chart options={chartOptions}
                               type="bar"
                               width={data.length > 0 ? data[0].data.length * 30 : '100%'}
                               height={'100%'}
                               series={data}
                        />
                    </Box>
                </Paper>
            )
        }
    } else {
        return <Paper elevation={3} sx={{marginTop: 2}}><Skeleton variant={"rectangular"} height={280}/></Paper>
    }
}
const getDataCategories = (data) => {
    const set = new Set();
    if (data.length > 0) {
        data.forEach(item => set.add(item.date));
        return Array.from(set);
    }

    return [];

}