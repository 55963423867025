import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {useState} from "react";
import {MenuItems, MenuItemsAdmin} from "../../../../config/menuItems";
import {Link} from "react-router-dom";
import * as React from "react";
import AuthService from "../../../../services/auth.service";
import {useTranslation} from "react-i18next";


const iconBackColor = 'grey.100';
const iconBackColorOpen = 'grey.200';

export const MobileMenu = () => {
    const {t} = useTranslation();
    const [drawer, setDrawer] = useState(false);

    const handleDrawerToggle = () => {
        setDrawer(!drawer)
    }

    return (
        <Box>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{
                    color: 'text.primary',
                    bgcolor: drawer ? iconBackColorOpen : iconBackColor,
                    display: { xs: 'flex', md: 'none' },
                    ml: { xs: 0, lg: -2 }
                }}
            >
                {!drawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>

            <Drawer
                anchor="left"
                open={drawer}
                onClose={handleDrawerToggle}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={handleDrawerToggle}
                    onKeyDown={handleDrawerToggle}
                >
                    <List sx={{ marginTop: 10 }}>
                        {
                            (AuthService.isAdmin() ? MenuItemsAdmin : MenuItems).map(item => {
                                if(item.type === "group") {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <ListItem>
                                                <ListItemText secondary={t(item.title)} />
                                            </ListItem>

                                            {
                                                item.children.map(item => {
                                                    return (
                                                        <ListItem key={item.id}
                                                                  component={Link}
                                                                  to={item.url}
                                                                  sx={{ color: "grey.500" }}
                                                        >
                                                            <ListItemIcon sx={{ marginRight: 2 }}><item.icon /></ListItemIcon>
                                                            <ListItemText primary={t(item.title)}></ListItemText>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <ListItem sx={{ color: "grey.500" }}
                                                  key={item.id}
                                                  component={Link}
                                                  to={item.url}
                                        >
                                            <ListItemIcon sx={{ marginRight: 2 }}><item.icon /></ListItemIcon>
                                            <ListItemText primary={t(item.title)}></ListItemText>
                                        </ListItem>
                                    );
                                }
                            })
                        }
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}