import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import {Outlet} from 'react-router-dom';
import AuthBackground from "../../assets/images/auth/AuthBackground";
import AuthCard from "./AuthCard";
import {LangSelector} from "../../components/LangSelector";


export const AuthLayout = ({children}) => {
    return (
        <Box sx={{ minHeight: '100vh', position: "relative" }}>
            <AuthBackground />
            <Box sx={{ position: "absolute", right: 10, top: 10 }}>
                <LangSelector />
            </Box>
            <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{
                    minHeight: '100vh'
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <AuthCard><Outlet/></AuthCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}