import {useTranslation} from "react-i18next";
import {Box, Button, Dialog, DialogTitle, Grid, Tab, Tabs} from "@mui/material";
import {AdUrlSelector} from "../../../components/autocompletes/AdUrlSelector";
import {useState} from "react";
import {SelectAdUrlDatesModal} from "../../../components/SelectAdUrlDatesModal";
import {TabPanel} from "../../../components/TabPanel";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AddUrlsDialog = (props) => {
    const {handleClose, handleSuccess, open, campaignId} = props;
    const {t} = useTranslation();

    const [tabValue, setTabValue] = useState(0)
    const [site, setSite] = useState(null);
    const [dateModal, setDateModal] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("add_links_to_campaign")}</DialogTitle>

        {/*<Alert severity="info">*/}
        {/*    {t("report_links_alert")}*/}
        {/*</Alert>*/}

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label={t("add_through_site")} {...a11yProps(0)} />
                    {/*<Tab label={t("add_manually")} {...a11yProps(1)} />*/}
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <AdUrlSelector value={site}
                                       setValue={(e, value) => setSite(value)}
                                       variant={"outlined"}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button color={"primary"}
                                onClick={() => {
                                    if (site) {
                                        setDateModal(true)
                                    }
                                }}
                                variant={"outlined"}
                        >
                            {t("add_ads")}
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            {/*<TabPanel value={tabValue} index={1}>*/}
            {/*    <Formik*/}
            {/*        initialValues={{*/}
            {/*            links: ''*/}
            {/*        }}*/}
            {/*        validationSchema={Yup.object().shape({*/}
            {/*            links: Yup.string().required(t('required')).max(255),*/}
            {/*        })}*/}
            {/*        onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {*/}
            {/*            setSubmitting(true);*/}
            {/*            const payload = {*/}
            {/*                links: values.links.split("\n"),*/}
            {/*            };*/}

            {/*            ReporterService.addClickUrlToCampaign(campaignId, payload).then(data => {*/}
            {/*                if (data.success) {*/}
            {/*                    toastr.success(t("items_added_to_report", {count: data.data}));*/}
            {/*                } else {*/}
            {/*                    toastr.error(t("error_server"));*/}
            {/*                }*/}

            {/*                setSubmitting(false);*/}
            {/*            }).catch(e => {*/}
            {/*                setSubmitting(false);*/}
            {/*                toastr.error(t("error_server"));*/}
            {/*            })*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (*/}
            {/*            <form noValidate onSubmit={handleSubmit}>*/}
            {/*                <Box sx={{flexGrow: 1}}>*/}
            {/*                    <Grid container spacing={2}>*/}
            {/*                        <Grid item xs={12} md={12}>*/}
            {/*                            <TextField fullWidth*/}
            {/*                                       multiline*/}
            {/*                                       rows={10}*/}
            {/*                                       name="links"*/}
            {/*                                       value={values.links}*/}
            {/*                                       label={t("report_links")}*/}
            {/*                                       variant="outlined"*/}
            {/*                                       onBlur={handleBlur}*/}
            {/*                                       onChange={handleChange}*/}
            {/*                                       error={Boolean(touched.links && errors.links)}*/}
            {/*                                       helperText={Boolean(touched.links && errors.links) ? errors.links : ""}*/}
            {/*                            />*/}
            {/*                        </Grid>*/}
            {/*                        <Grid item xs={12} md={2}>*/}
            {/*                            <Button disableElevation*/}
            {/*                                    disabled={isSubmitting}*/}
            {/*                                    variant={"outlined"}*/}
            {/*                                    type={"submit"}*/}
            {/*                            >*/}
            {/*                                {t("add")}*/}
            {/*                            </Button>*/}
            {/*                        </Grid>*/}
            {/*                    </Grid>*/}
            {/*                </Box>*/}
            {/*            </form>*/}
            {/*        )}*/}
            {/*    </Formik>*/}
            {/*</TabPanel>*/}
        </Box>

        <SelectAdUrlDatesModal site={site}
                               open={dateModal}
                               handleSuccess={() => setDateModal(false)}
                               handleClose={() => setDateModal(false)}
                               campaignId={campaignId}
        />
    </Dialog>)
}