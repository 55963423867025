import axios from "axios";
import * as qs from "query-string";

export class ParserService {
    static get(filter = {}) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/parser/log?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
}