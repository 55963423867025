import {Box, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Formik} from 'formik';
import AuthService from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import toastr from "toastr";

export const Profile = (props) => {
    const {t} = useTranslation();

    const [initialValues, setInitialValues] = useState({
        name: "",
        password: ""
    });

    useEffect(() => {
        setInitialValues({
            name: AuthService.getCurrentUserName(),
            password: ""
        });
    }, []);

    return (
        <Box sx={{ p:3 }}>
            <Typography variant="h4">
                {t("edit_profile")}
            </Typography>

            <Box sx={{ marginTop: 4 }}>
                <Paper sx={{ pt:6, pb:6, pl:2, pr:2 }}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(t('required')).max(255, t("max_length", {length: 500})),
                            password: Yup.string().nullable().min(8, t("min_length_8"))
                        })}
                        onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            const payload = {
                                name: values.name,
                                password: values.password,
                            };

                            UserService.update(payload).then(data => {
                                if (data.success) {
                                    toastr.success(t("user_updated"));
                                    AuthService.updateLocalUserName(values.name);
                                } else {
                                    toastr.error(t("error_server"));
                                }

                                setSubmitting(false);
                            }).catch(e => {
                                setSubmitting(false);
                                toastr.error(t("error_server"));
                            })
                        }}
                    >
                        {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <TextField fullWidth
                                                   name="name"
                                                   value={values.name}
                                                   label={t("user_name")}
                                                   variant="outlined"
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   error={Boolean(touched.name && errors.name)}
                                                   helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextField fullWidth
                                                   name="password"
                                                   type={"password"}
                                                   value={values.password}
                                                   label={t("password")}
                                                   variant="outlined"
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   error={Boolean(touched.password && errors.password)}
                                                   helperText={Boolean(touched.password && errors.password) ? errors.password : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button disableElevation
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"submit"}
                                        >
                                            {t("update")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Box>
        </Box>
    )
}