import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CircularProgress, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import ResetPasswordFinishForm from "./ResetPasswordFinishForm";
import AuthService from "../../../services/auth.service";
import toastr from "toastr";

export const ResetPasswordFinish = () => {
    const {t} = useTranslation();
    let {token} = useParams();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AuthService.forgotPasswordCheckToken(token)
            .then(response => {
                if (!response.success) {
                    setError(true)
                }

                setLoading(false);
            }).catch(e => {
                setError(true);
                setLoading(false);
        })
    }, []);

    if (loading) {
        return <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                       sx={{mb: {xs: -0.5, sm: 0.5}}}>
                    <CircularProgress />
                </Stack>
            </Grid>
        </Grid>
    } else {
        if (!error) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                               sx={{mb: {xs: -0.5, sm: 0.5}}}>
                            <Typography variant="h3">{t('reset_password')}</Typography>
                            <Typography component={Link} to="/login" variant="body1" sx={{textDecoration: 'none'}}
                                        color="primary">
                                {t('back_to_login')}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <ResetPasswordFinishForm token={token}/>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack sx={{mb: {xs: -0.5, sm: 0.5}}}>
                            <Typography gutterBottom variant="h3">{t("reset_password_error")}</Typography>
                            <Typography gutterBottom variant="body2">{t("reset_password_error_desc")}</Typography>

                            <Typography align={"center"} component={Link} to="/login" variant="body1" sx={{textDecoration: 'none'}}
                                        color="primary">
                                {t('back_to_login')}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            )
        }
    }

}