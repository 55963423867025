import axios from "axios";

export class ExportsService {
    static exportKeywordSiteDate(payload) {
        return axios.post(`/dashboard/exports/keyword-site-date`, payload, {
            responseType: 'blob'
        }).then((response) => {
            return response;
        });
    }
}