import axios from "axios";

export class AdUrlService {
    static getAll(search, userId) {
        return axios.get(`/dashboard/adUrl/get/all?search=${search}&user_id=${userId}`).then((response) => {
            return response.data;
        });
    }

    static getByUrl(search) {
        return axios.get(`/dashboard/adUrl/get/byUrl?ad_url=${search}`).then((response) => {
            return response.data;
        });
    }

    static getByKeyword(keywordId, userId) {
        return axios.get(`/dashboard/adUrl/get/byKeyword?keyword_id=${keywordId}&user_id=${userId}`).then((response) => {
            return response.data;
        });
    }
}