import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {ReporterPreferencesService} from "../../../../services/reporterPreferences.service";
import toastr from "toastr";
import {ReportCount} from "./ReportPreferences/ReportCount";
import {ReportType} from "./ReportPreferences/ReportType";
import {Projects} from "./ReportPreferences/Projects";
import {Countries} from "./ReportPreferences/Countries";
import {DeviceType} from "./ReportPreferences/DeviceType";

export const ReportPreferencesBasic = ({userId}) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);

    const [projects, setProjects] = useState([]);

    const [reportCount, setReportCount] = useState(1);
    const [reportType, setReportType] = useState(1);
    const [isStarted, setIsStarted] = useState(false);

    const [projectIdsTmp, setProjectIdsTmp] = useState([]);
    const [projectIds, setProjectIds] = useState([]);

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCountriesTmp, setSelectedCountriesTmp] = useState([]);

    const [deviceTypes, setDeviceTypes] = useState([]);

    useEffect(() => {
        ReporterPreferencesService.getMe(userId).then(data => {
            if (data.success) {
                setReportCount(data.data.report_count)
                setReportType(data.data.report_type)
                setIsStarted(!!data.data.is_started)
                setProjectIdsTmp(data.data.project_ids != null ? data.data.project_ids.split(",") : []);
                setSelectedCountriesTmp(data.data.countries != null ? data.data.countries.split(",") : []);
                setDeviceTypes(data.data.device_type != null ? data.data.device_type.split(",") : []);
            }

            setLoading(false);
        }).catch(e => {
            toastr.error(t("error_loading"))
        })
    }, [userId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        ReporterPreferencesService.updateMe({
            report_type: reportType,
            report_count: reportCount,
            is_started: isStarted,
            project_ids: projectIds.length > 0 ? projectIds.map(project => project.id) : null,
            countries: selectedCountries.length > 0 ? selectedCountries.map(country => country.id) : null,
            device_type: deviceTypes.length > 0 ? deviceTypes : null,
            user_id: userId
        }).then(data => {
            if (data.success) {
                toastr.success(t("success_update"));
            } else {
                toastr.error(t("error_when_updating"));
            }
        }).catch(e => {
            toastr.error(t("error_server"))
        })
    }

    if (!loading) {
        return (
            <Card>
                <CardContent>
                    <Box sx={{mb: 3}} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}
                         alignItems={"center"}>
                        <Typography sx={{fontSize: 20}} color="text.secondary" gutterBottom>
                            {t("report_settings")}
                        </Typography>
                    </Box>

                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <ReportCount reportCount={reportCount}
                                             setReportCount={setReportCount}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ReportType reportType={reportType}
                                            setReportType={setReportType}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Projects projectIds={projectIds}
                                          setProjectIds={setProjectIds}
                                          projectIdsTmp={projectIdsTmp}
                                          projects={projects}
                                          setProjects={setProjects}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Countries
                                    selectedCountries={selectedCountries}
                                    setSelectedCountries={setSelectedCountries}
                                    projects={projects}
                                    selectedCountriesTmp={selectedCountriesTmp}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DeviceType
                                    deviceTypes={deviceTypes}
                                    setDeviceTypes={setDeviceTypes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={
                                    <Checkbox checked={isStarted} onChange={(e) => setIsStarted(e.target.checked)}/>
                                } label={t("auto_reporter_enables")}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button size="small" type={"submit"} variant={"outlined"}>{t("update")}</Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        )
    } else {
        return (
            <Card>
                <CardContent>
                    <Box sx={{mb: 3}} display={"flex"} justifyContent={"center"}
                         alignItems={"center"}>
                        <CircularProgress/>
                    </Box>
                </CardContent>
            </Card>
        )
    }
}