import {Alert, Button} from "@mui/material";
import {useEffect, useState} from "react";
import {SubscriptionService} from "../../services/subscription.service";
import toastr from "toastr";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const OtherSubscriptionAlert = (props) => {
    const {t} = useTranslation();
    const {subscription, title} = props

    if (!subscription) {
        return (
            <Alert sx={{mb: 4}} severity={"info"}
                   // action={
                   //     <Button component={Link} to={"/dashboard/subscriptions"} color="inherit" size="small">
                   //         {t("order_subscription")}
                   //     </Button>
                   // }
            >
                {title}
            </Alert>
        );
    } else return "";
}