import {TextField} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";

let searchTimer;

export const SearchKeywords = (props) => {
    const {searchCallback} = props;

    const {t} = useTranslation();

    const [search, setSearch] = useState(props.search);

    const handleSearch = (e) => {
        setSearch(e.target.value);

        if(searchTimer) {
            clearTimeout(searchTimer);
        }

        searchTimer = setTimeout(() => {
            searchCallback(e.target.value);
        }, 1000);
    }

    return (
        <TextField sx={{ marginTop: 2 }}
                   label={t("search_by_keyword")}
                   variant="outlined"
                   fullWidth
                   value={search}
                   onChange={handleSearch}
        />
    )
}