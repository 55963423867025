import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ProxyService} from "../../services/proxy.service";

export const ProxySelector = (props) => {
    const {t} = useTranslation();
    const {value, setValue} = props;
    const [data, setData] = useState([]);

    const allItems = [
        {
            id: null,
            name: t("all_proxies")
        }
    ];


    useEffect(() => {
        ProxyService.getAll().then(data => {
            if (data.data) {
                setData(allItems.concat(data.data))
            }
        }).catch(e => {
            toastr.error(t("error_loading"))
        })
    }, []);

    return <Autocomplete
        disablePortal={false}
        options={data}
        getOptionLabel={option => option.name}
        freeSolo
        onChange={setValue}
        onClick={e => e.stopPropagation()}
        renderInput={(inputParams) =>
            <TextField {...inputParams}
                       fullWidth
                       size={"small"}
                       onClick={e => e.stopPropagation()}
                       variant={"outlined"}
                       label={t("proxies")}
            />
        }
    />
}