import axios from "axios";
import * as qs from "query-string";

export class AdsService {
    static getAdsByDay(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/ads-by-day?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getAdsByUrl(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/ads-by-url?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getUrlByDay(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/url-by-day?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getTopKeywords(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/top-keywords?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getTopSources(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/top-sources?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getTopDevices(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/top-devices?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getActiveAds(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/active-ads?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getActiveAdUrls(filter) {
        const filterQuery = qs.stringify(filter);

        return axios.get(`/dashboard/analytics/active-ad-urls?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
}