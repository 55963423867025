import Box from "@mui/material/Box";
import {Button, Link} from "@mui/material";
import * as React from "react";
import AddIcon from '@mui/icons-material/Add';
import {getParsedKeywords} from "../../../../utils/Keywords";
import i18n from "../../../../lang/i18n";
import _ from "underscore";

const getPreviewKeywords = (params) => {
    let keywords = [];

    for (let i = 1; i < 6; i++) {
        const keywordIdKey = `preview_keyword_id_${i}`;
        const keywordValueKey = `preview_keyword_value_${i}`;

        if (_.has(params.row, keywordIdKey)) {
            if (params.row[keywordIdKey] !== null) {
                keywords.push({
                    id: params.row[keywordIdKey],
                    name: params.row[keywordValueKey],
                });
            }
        }
    }

    return keywords;
}

export const keywords = (params, onModalClick, onKeywordClick) => {
    let keywords = [];

    if(_.has(params.row, "keywords")) {
        keywords = getParsedKeywords(params.row.keywords);
    } else {
        keywords = getPreviewKeywords(params);
    }

    return (
        <div>
            {
                keywords.map((item, index) => {
                    return (
                        <Box key={index} fontSize={9}>
                            + <Link color={"info"} href={"#"} onClick={(e) => {
                                e.preventDefault();
                                onKeywordClick(item)
                            }}>
                            {item.name}
                        </Link>
                        </Box>
                    )
                })
            }

            <Button
                onClick={onModalClick}
                size={"small"}
                sx={{fontSize: 10, marginTop: 1}}
                variant={"outlined"}
                color={"secondary"}
                startIcon={<AddIcon fontSize={"small"}/>}
            >
                {i18n.t("more")}
            </Button>
        </div>
    )
}