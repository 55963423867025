import * as i18n from "i18next";
import {initReactI18next} from "react-i18next";
import ruTranslations from "./ru/translation.json"
import enTranslations from "./en/translation.json"
import moment from "moment";
import 'moment/locale/ru';

const langs = ["en", "ru"];

const defaultLanguage = "ens";
let lng = localStorage.getItem('locale');

if(!lng) {
    const navigatorLanguage = navigator.language;

    lng = defaultLanguage
    langs.forEach(lang => {
        if(navigatorLanguage.indexOf(lang)) {
            lng = lang;
        }
    })

    localStorage.setItem("locale", lng)
}

moment.locale(lng);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            ru: {
                translation: ruTranslations
            },
            en: {
                translation: enTranslations
            }
        },
        lng: lng || defaultLanguage,
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default i18n;