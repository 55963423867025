import {ReportPreferencesBasic} from "./ReportPreferencesBasic";
import {Box, Grid} from "@mui/material";
import {ReportPreferencesBlackList} from "./ReportPreferencesBlackList";
import {ReportPreferencesWhiteList} from "./ReportPreferencesWhiteList";
import AuthService from "../../../../services/auth.service";
import {useState} from "react";
import {UserSelector} from "../../../../components/autocompletes/UserSelector";
import * as React from "react";

export const ReportPreferences = () => {
    const [userId, setUserId] = useState(null);

    const renderForms = () => {
        return <div>
            <ReportPreferencesBasic userId={userId} />

            <Grid sx={{my: 3}} container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ReportPreferencesWhiteList userId={userId} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReportPreferencesBlackList userId={userId} />
                </Grid>
            </Grid>
        </div>
    }

    if (AuthService.isAdmin()) {
        return <div>
            <Box mb={2}>
                <UserSelector
                    excludeAllUsers={true}
                    setValue={(e, value) => {
                        setUserId(value !== null ? value.id : null)
                    }}
                />
            </Box>

            {
                userId !== null ? renderForms() : ''
            }
        </div>
    } else {
        return renderForms();
    }
}