import {Box, Chip, Paper, Skeleton, Tooltip, Typography} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import {NewAdsChart} from "./charts/NewAdsChart";
import * as React from "react";
import {useTranslation} from "react-i18next";

const topBoxStyle = {
    p: 2
}

const countWrapperStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 1
}

export const TopBox = (props) => {
    const {t} = useTranslation();
    const {title, count, chipValue, trend, children, loading} = props;

    return (
        <Paper>
            <Box sx={topBoxStyle}>
                {
                    !loading ? <Typography variant={"h6"} color={"grey.500"}>
                        {title || ""}
                    </Typography> : <Skeleton height={30}/>
                }

                <Box sx={countWrapperStyle}>
                    {
                        !loading ? <Typography variant={"h5"}>
                            {count || 0}
                        </Typography> : <Skeleton width={90} sx={{marginRight: 1}} height={40}/>
                    }

                    {
                        !loading ? <Tooltip title={t("top_box_chip_title")}><Chip color={trend === "up" ? "error" : "success"}
                                         variant={"outlined"}
                                         label={chipValue}
                                         sx={{ml:3}}
                                         icon={trend === "up" ? <TrendingUpIcon/> : <TrendingDownIcon />}
                        /></Tooltip> : <Skeleton width={90} height={40}/>
                    }
                </Box>
            </Box>
            <Box>
                {children}
            </Box>
        </Paper>
    )
}