import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {parserFrequency, parserKeywordsCount, parserSteps} from "../../../../../../utils/Constants";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {calculatePrice} from "../../../../../../utils/Price";


export const ParserDefaultEditFields = (props) => {
    const {t} = useTranslation();
    const {values, handleChange, touched, errors, setFieldValue} = props;

    useEffect(() => {
        setFieldValue("cost", calculatePrice(values.hour_interval, values.keywords_count))
    }, [values.hour_interval, values.keywords_count]);

    useEffect(() => {
        if(touched.is_custom) {
            setFieldValue("hour_interval", parserSteps[0].value)
            setFieldValue("keywords_count", parserKeywordsCount[0])
        }
    }, [values.is_custom]);

    return <>
        <Grid item xs={12} md={6}>
            <FormControl fullWidth>
                <InputLabel>{t("parser_frequency")}</InputLabel>
                <Select
                    name={"hour_interval"}
                    value={values.hour_interval}
                    label={t("parser_frequency")}
                    onChange={handleChange}
                    error={Boolean(touched.hour_interval && errors.hour_interval)}
                >
                    {
                        parserFrequency.map(item => {
                            return <MenuItem key={item.value}
                                             value={item.value}>{t(item.label)}</MenuItem>
                        })
                    }
                </Select>
                {
                    Boolean(touched.hour_interval && errors.hour_interval) ?
                        <FormHelperText
                            error={true}>{errors.hour_interval}</FormHelperText> : ""
                }
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl fullWidth>
                <InputLabel>{t("keywords_count")}</InputLabel>
                <Select
                    name={"keywords_count"}
                    value={values.keywords_count}
                    label={t("keywords_count")}
                    onChange={handleChange}
                    error={Boolean(touched.keywords_count && errors.keywords_count)}
                >
                    {
                        parserKeywordsCount.map(item => <MenuItem key={item}
                                                            value={item}>{item}</MenuItem>)
                    }
                </Select>
                {
                    Boolean(touched.keywords_count && errors.keywords_count) ?
                        <FormHelperText
                            error={true}>{errors.keywords_count}</FormHelperText> : ""
                }
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField fullWidth
                       name="cost"
                       value={values.cost}
                       label={t("subscription_price")}
                       variant="outlined"
                       disabled={true}
            />
        </Grid>
    </>
}