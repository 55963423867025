import {Cookies} from "react-cookie";
import moment from "moment/moment";
import AuthService from "../services/auth.service";

export const setLastPageSize = (pageSize) => {
    localStorage.setItem("last_page_size", pageSize)
}

export const getLastPageSize = () => {
    return Number(localStorage.getItem("last_page_size")) || 10
}

export const setLastSelectedUser = (userId) => {
    const cookies = new Cookies();
    if(userId && AuthService.getCurrentRole() === "admin") {
        cookies.set("a_user_id", userId, {
            path: "/",
            expires: moment().add(10, "minutes").toDate()
        })
    }
}

export const getLastSelectedUser = () => {
    const cookies = new Cookies();
    if(AuthService.getCurrentRole() === "admin") {
        const cookie = cookies.get("a_user_id");

        return cookie ? cookie : null;
    }

    return null;
}