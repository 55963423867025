import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../../utils/Date";
import {Box, Button, Chip, Tooltip, Typography} from "@mui/material";
import {ReporterService} from "../../../../services/reporter.service";
import toastr from "toastr";
import {ReportLogFilter} from "../../../admin/report/ReportLogFilter";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import * as React from "react";
import {grey} from "@mui/material/colors";
import {ReportCampaignItemService} from "../../../../services/reportCampaignItem.service";
import {ReportStatisticFilter} from "./ReportStatisticFilter";
import {country} from "../../../../components/ads/table/column/country";
import {ReportStatisticModal} from "./ReportStatisticModal";

const grey400 = grey[400];

export const ReportStatistic = () => {
    const {t} = useTranslation();

    const pageSize = 12;
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [detailsModal, setDetailsModal] = useState(false);
    const [detailsModalDate, setDetailsModalDate] = useState(null);

    const [filter, setFilter] = useState({
        project_id: null,
        country: null,
        start_date: getDatePickerStartDate(),
        end_date: getDatePickerEndDate(),
    });

    const columns = [
        {
            field: 'date',
            headerName: t("date"),
            width: 100,
            sortable: true,
        },
        {
            field: 'sum_total_passes',
            headerName: t("report_plan_count"),
            width: 270,
            sortable: false,
            align: "center",
            renderCell: params => {
                return <strong style={{fontSize: 14}}>{params.row.sum_total_passes}</strong>
            }
        },
        {
            field: 'sum_passes',
            headerName: t("report_resolved_count"),
            width: 250,
            sortable: false,
            align: "center",
            renderCell: params => {
                return <Chip variant={"outlined"} color={"primary"} label={params.row.sum_passes} size={"small"}/>
            }
        },
        {
            field: 'id',
            headerName: t("detailed"),
            width: 140,
            sortable: false,
            align: "center",
            renderCell: params => {
                return <Button variant={"contained"}
                               color={"info"}
                               size={"small"}
                               onClick={() => {
                                   setDetailsModalDate(params.row.date)
                                   setDetailsModal(true);
                               }}
                >
                    {t("details")}
                </Button>
            }
        }
    ]

    useEffect(() => {
        setPage(-1)
    }, [filter])

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ReportCampaignItemService.getUserStatistic(
                filter.start_date,
                filter.end_date,
                filter.country,
                page + 1
            ).then(data => {
                if (data.data) {
                    setLogs(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    return <div>
        <Box sx={{
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography variant="h5">
                {t("report_statistics")}
            </Typography>
        </Box>

        <ReportStatisticFilter filter={filter} setFilter={setFilter}/>

        <ReportStatisticModal open={detailsModal}
                              handleClose={() => setDetailsModal(false)}
                              date={detailsModalDate}
                              country={filter.country}
        />

        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight={true}
            sx={{
                border: 1,
                borderColor: grey400,
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                '& .MuiDataGrid-columnSeparator': {
                    color: grey400,
                },
                '& .MuiDataGrid-withBorder': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-footerContainer': {
                    borderColor: grey400,
                },
                maxWidth: 800
            }}
            disableSelectionOnClick={true}
            showCellRightBorder={true}
            rows={logs}
            columns={columns}
            paginationMode={"server"}
            page={page < 0 ? 0 : page}
            getRowId={(option) => option.date}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowCount={rowCount}
            disableColumnMenu={true}
            loading={loading}
            disableColumnSelector={true}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            filterMode="server"
        />
    </div>
}