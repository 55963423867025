import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Popover
} from "@mui/material";
import c_ruRU from "date-fns/locale/ru";
import * as React from "react";
import {DateRange} from "react-date-range";
import moment from "moment";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {useEffect} from "react";

export const DateRangePickerCustom = (props) => {
    const {startDate, endDate, onApply, maxDate, minDate, error} = props;

    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [selectionRange, setSelectionRange] = React.useState({
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        key: 'selection',
    });

    useEffect(() => {
        setSelectionRange({
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
            key: 'selection',
        })
    }, [startDate])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

        const newStartDate = moment(selectionRange.startDate).format('YYYY-MM-DD')
        const newEndDate = moment(selectionRange.endDate).format('YYYY-MM-DD')

        onApply(newStartDate, newEndDate);
    };

    const onChange = (data) => {
        setSelectionRange(data.selection)
    }

    const getInputValue = () => {
        return moment(selectionRange.startDate).format('YYYY-MM-DD').toString() +
            " - " +
            moment(selectionRange.endDate).format('YYYY-MM-DD').toString();
    }


    return (
        <div>
            <FormControl fullWidth variant="outlined" onClick={handleClick} aria-describedby={id}>
                <InputLabel htmlFor="outlined-adornment-password">
                    {t("choose_date")}
                </InputLabel>
                <OutlinedInput
                    value={getInputValue()}
                    fullWidth
                    error={!!error}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                            >
                                <CalendarMonthIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("choose_date")}
                />
                {!!error && (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <DateRange
                        months={window.innerWidth < 768 ? 1 : 2}
                        direction={window.innerWidth < 768 ? "vertical" : "horizontal"}
                        showPreview={true}
                        locale={c_ruRU}
                        ranges={[selectionRange]}
                        maxDate={maxDate !== undefined ? moment(maxDate).toDate() : moment().toDate()}
                        minDate={minDate !== undefined ? moment(minDate).toDate() : moment().subtract(10, "years").toDate()}
                        showDateDisplay={false}
                        onChange={onChange}
                    />
                </Box>
                <Box sx={{ padding: 1, display: "flex", justifyContent: "flex-end"}}>
                    <Button color={"primary"} variant={"contained"} size={"small"} onClick={handleClose}>
                        {t("apply")}
                    </Button>
                </Box>
            </Popover>
        </div>
    )
}