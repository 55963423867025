import {Autocomplete, Box, Button, Dialog, DialogTitle, Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import {Formik} from 'formik';
import toastr from "toastr";
import {KeywordsService} from "../../../../services/keywords.service";
import {deviceTypes} from "../../../../utils/Constants";
import {useEffect, useState} from "react";

export const EditDialog = (props) => {
    const {handleClose, open, countries, projectId, keywordId} = props;
    const {t} = useTranslation();

    const [initialValues, setInitialValues] = useState({
        name: '',
        types: [],
        country: null,
    });

    useEffect(() => {
        if (keywordId) {
            KeywordsService.getById(keywordId).then(data => {
                if (data.success) {
                    const newInitialValues = {
                        name: data.data.name
                    }

                    newInitialValues["types"] = data.data.keyword_types.map(item => {
                        return deviceTypes.find(deviceType => {
                            return deviceType.id === item.type
                        })
                    })

                    newInitialValues["country"] = countries.find(item => {
                        return item.code === data.data.country
                    })

                    setInitialValues(newInitialValues);
                } else {
                    toastr.error(t("error_server"));
                }
            }).catch(e => {
                toastr.error(t("error_server"));
            })
        }
    }, [open])

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("updating_keyword")}</DialogTitle>

        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
                name: Yup.string().required(t('required')),
                types: Yup.array().min(1, t('required')),
                country: Yup.object().typeError(t('required'))
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);
                const payload = {
                    name: values.name,
                    country: values.country.code,
                    devices: values.types.map(item => item.id),
                    project_id: projectId
                };

                KeywordsService.updateKeyword(keywordId, payload).then(data => {
                    if (data.success) {
                        toastr.success(t("keyword_updated"))
                    } else {
                        setErrors(data.data)
                    }

                    setSubmitting(false)
                }).catch(e => {
                    toastr.error(t("error_server"));
                    setSubmitting(false)
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{flexGrow: 1, padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth
                                           name="name"
                                           value={values.name}
                                           label={t("keyword")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    value={values.types}
                                    options={deviceTypes}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name}
                                    onChange={((event, value) => setFieldValue("types", value))}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                        error={Boolean(touched.types && errors.types)}
                                        helperText={Boolean(touched.types && errors.types) ? errors.types : ""}
                                        variant="outlined"
                                        label={t("devices")}
                                    />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    value={values.country}
                                    options={countries}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    getOptionLabel={(option) => option.label}
                                    onChange={((event, value) => setFieldValue("country", value))}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={Boolean(touched.country && errors.country) ? errors.country : ""}
                                        variant="outlined"
                                        label={t("country")}
                                    />)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button disableElevation
                                        disabled={isSubmitting}
                                        variant={"outlined"}
                                        type={"submit"}
                                >
                                    {t("update")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        </Formik>
    </Dialog>)
}