import Box from "@mui/material/Box";
import {IconButton, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {AdsService} from "../../../services/ads.service";
import {useEffect, useState} from "react";
import toastr from "toastr";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {SelectorType} from "./SelectorType";
import {getPrettifiedDomain} from "../../../utils/Domain";
import {CustomColors} from "../../../themes/custom-colors";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 600,
    maxHeight: "90%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingTop: 10
};

const styleCloseButton = {
    position: 'absolute',
    top: '10px',
    right: '10px',
};

const selectorStyle = {
    position: 'absolute',
    top: '10px',
    left: '20px',
};

export const AdsCountModal = (props) => {
    const {t} = useTranslation();
    const {open, handleClose, adUrl, userId} = props;

    const [data, setData] = useState([]);
    const [type, setType] = useState("day");
    const [domain, setDomain] = useState("");

    var chartSeries = [
        {
            name: t("ads"),
            data: getDataValues(data)
        }
    ];

    var chartOptions = {
        colors: [CustomColors.orange],
        chart: {
            height: 280,
            type: "area",
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: getDataCategories(data)
        },
        title: {
            text: t("statistic_by_ad_url"),
            align: 'left'
        },
        subtitle: {
            text: domain,
            align: 'left'
        },
    };

    useEffect(() => {
        if(type === "day") {
            getData()
        } else {
            setType("day")
        }

        setDomain(getPrettifiedDomain(adUrl));
    }, [adUrl]);

    useEffect(() => {
        getData();
    }, [type]);

    const getData = () => {
        if (adUrl !== null) {
            if (type === "day") {
                AdsService.getCountAdUrlByDay(adUrl, userId).then(data => {
                    if (data.data) {
                        setData(data.data);
                    }
                }).catch(e => {
                    toastr.error(t("error_loading"));
                })
            }
            if (type === "month") {
                AdsService.getCountAdUrlByMonth(adUrl, userId).then(data => {
                    if (data.data) {
                        setData(data.data);
                    }
                }).catch(e => {
                    toastr.error(t("error_loading"));
                })
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <IconButton sx={styleCloseButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>

                <Box sx={selectorStyle}>
                    <SelectorType value={type} setValue={(e, value) => {
                        setType(value.props.value);
                    }}/>
                </Box>

                <Chart options={chartOptions} type="area" series={chartSeries}/>
            </Box>
        </Modal>
    )
}

const getDataValues = (data) => {
    return data.map(item => item.total)
}
const getDataCategories = (data) => {
    return data.map(item => item.category)
}