import axios from "axios";
import * as qs from "query-string";

export class UserService {
    static update(payload) {
        return axios.post(`/dashboard/user/update`, payload).then((response) => {
            return response.data;
        });
    }

    static create(payload) {
        return axios.post(`/dashboard/user`, payload).then((response) => {
            return response.data;
        });
    }

    static getAll(role = null) {
        const filterQuery = qs.stringify({
            role
        });

        return axios.get(`/dashboard/user/get/all?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static index(page, perPage, showDeactivated) {
        const filterQuery = qs.stringify({
            page,
            perPage,
            showDeactivated
        });

        return axios.get(`/dashboard/user?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static delete(userId) {
        return axios.post(`/dashboard/user/delete/${userId}`).then((response) => {
            return response.data;
        });
    }

    static restore(userId) {
        return axios.post(`/dashboard/user/restore/${userId}`).then((response) => {
            return response.data;
        });
    }

    static getUserById(userId) {
        return axios.get(`/dashboard/user/${userId}`).then((response) => {
            return response.data;
        });
    }

    static confirmEmail(userId) {
        return axios.post(`/dashboard/user/confirm-email/${userId}`).then((response) => {
            return response.data;
        });
    }

    static getSubscriptions(userId) {
        return axios.get(`/dashboard/user/${userId}/subscriptions`).then((response) => {
            return response.data;
        });
    }

    static getCurrentSubscriptions(cache = 0) {
        return axios.get(`/dashboard/subscriptions?cache=${cache}`).then((response) => {
            return response.data;
        });
    }
}