import {Box, Button, Chip, FormControlLabel, Switch, Typography} from "@mui/material";
import {DataGrid, GridActionsCellItem, ruRU} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {grey} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {UserService} from "../../../services/user.service";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import {AlertDialog} from "../../../components/AlertDialog";
import AddIcon from "@mui/icons-material/Add";
import {CreateUserDialog} from "./CreateUserDialog";
import EditIcon from '@mui/icons-material/Edit';
import {EditUserDialog} from "./EditUserDialog";
import RestoreIcon from '@mui/icons-material/Restore';

const grey400 = grey[400];

export const Users = () => {
    const {t} = useTranslation();

    const pageSize = 24;
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [targetUser, setTargetUser] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [restoreDialog, setRestoreDialog] = useState(false);

    const [createDialog, setCreateDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const [showDeactivated, setShowDeactivated] = useState(false);

    const columns = [
        {
            field: 'id',
            headerName: "ID",
            width: 40,
            sortable: false
        },
        {
            field: 'name',
            headerName: t("user_name"),
            width: 150,
            sortable: false
        },
        {
            field: 'roles',
            headerName: t("role"),
            width: 70,
            sortable: false,
            renderCell: (params) => {
                return <strong style={{textTransform: "capitalize"}}>
                    {params.row.roles[0].name}
                </strong>
            }
        },
        {
            field: 'email',
            headerName: "E-mail",
            width: 180,
            sortable: false
        },
        {
            field: 'deleted_at',
            headerName: t("status"),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                if (params.row.deleted_at === null) {
                    return <Chip size={"small"} color={"success"} label={t("is_active")}/>
                } else {
                    return <Chip size={"small"} color={"error"} label={t("is_diactivated")}/>
                }
            }
        },
        {
            field: 'server',
            headerName: t("server"),
            width: 200,
            sortable: false,
            renderCell: (params) => {
                if (params.row.servers.length > 0) {
                    return <div>{
                        params.row.servers.map(item => <div key={item.type}>
                            <p><strong>{t(item.type + "_server")}:</strong> {item.server_count}</p>
                        </div>)
                    }</div>
                } else {
                    return <div style={{textAlign: "center"}}>
                        <strong>{t("no_servers")}</strong>
                    </div>
                }
            }
        },
        {
            field: 'email_verified_at',
            headerName: t("verification"),
            width: 175,
            sortable: false,
            renderCell: (params) => {
                if (params.row.email_verified_at === null) {
                    return <Chip size={"small"} color={"warning"} label={t("not_verified")}/>
                } else {
                    return <Chip size={"small"} color={"info"} label={t("verified")}/>
                }
            }
        },
        {
            field: 'created_at',
            headerName: t("created_at"),
            width: 120,
            sortable: false,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'updated_at',
            headerName: t("updated_at"),
            width: 120,
            sortable: false,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'telegram',
            headerName: "Telegram",
            width: 100,
        },
        {
            field: 'actions',
            headerName: t("actions"),
            width: 150,
            sortable: false,
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    color={"primary"}
                    icon={<EditIcon/>}
                    onClick={() => {
                        setEditDialog(true);
                        setTargetUser(params.row.id)
                    }}
                    label={"2"}
                />,
                <GridActionsCellItem
                    color={"error"}
                    icon={params.row.deleted_at === null ? <DeleteIcon/> : <RestoreIcon/>}
                    onClick={() => {
                        if(params.row.deleted_at === null) {
                            setDeleteDialog(true);
                        } else {
                            setRestoreDialog(true);
                        }
                        setTargetUser(params.row.id)
                    }}
                    label={"2"}
                />,
            ]
        },
    ]

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            UserService.index(page + 1, pageSize, showDeactivated).then(data => {
                if (data.data) {
                    setUsers(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    const handleDelete = () => {
        if (targetUser !== null) {
            UserService.delete(targetUser).then(data => {
                if (data.success) {
                    toastr.success(t("user_deactivated"))
                    setPage(-1);
                } else {
                    toastr.error(t("user_not_deactivated"));
                }

                setDeleteDialog(false);
            }).catch(e => {
                setDeleteDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    const handleRestore = () => {
        if (targetUser !== null) {
            UserService.restore(targetUser).then(data => {
                if (data.success) {
                    toastr.success(t("user_restored"))
                    setPage(-1);
                } else {
                    toastr.error(t("user_not_restored"));
                }

                setRestoreDialog(false);
            }).catch(e => {
                setRestoreDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    return (
        <div>
            <Box sx={{
                marginBottom: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: 'wrap'
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <Typography sx={{marginRight: 3}} variant="h5">
                        {t("all_users")}
                    </Typography>
                    <FormControlLabel control={
                        <Switch checked={showDeactivated}
                                onChange={(e) => {
                                    setShowDeactivated(e.target.checked)
                                    setPage(-1)
                                }}
                        />
                    }
                                      label={t("show_deactivated")}
                    />
                </Box>

                <Button
                    startIcon={<AddIcon/>}
                    variant={"contained"}
                    onClick={() => setCreateDialog(true)}
                >
                    {t("add")}
                </Button>
            </Box>

            <DataGrid
                getRowHeight={() => 'auto'}
                autoHeight={true}
                sx={{
                    border: 1,
                    borderColor: grey400,
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                    '& .MuiDataGrid-columnSeparator': {
                        color: grey400,
                    },
                    '& .MuiDataGrid-withBorder': {
                        borderColor: grey400,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderColor: grey400,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderColor: grey400,
                    }
                }}
                disableSelectionOnClick={true}
                showCellRightBorder={true}
                rows={users}
                columns={columns}
                paginationMode={"server"}
                page={page < 0 ? 0 : page}
                onPageChange={(newPage) => setPage(newPage)}
                pageSize={pageSize}
                rowCount={rowCount}
                disableColumnMenu={true}
                loading={loading}
                disableColumnSelector={true}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                filterMode="server"
            />

            <AlertDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t("user_delete_dialog_title")}
                content={t("user_delete_dialog_content")}
                handleCancel={() => setDeleteDialog(false)}
                handleOk={handleDelete}
            />

            <AlertDialog
                open={restoreDialog}
                handleClose={() => setRestoreDialog(false)}
                title={t("user_restore_dialog_title")}
                content={t("user_restore_dialog_content")}
                handleCancel={() => setRestoreDialog(false)}
                handleOk={handleRestore}
            />

            <CreateUserDialog open={createDialog}
                              handleClose={() => setCreateDialog(false)}
                              handleSuccess={() => {
                                  setPage(-1);
                                  setCreateDialog(false)
                              }}
            />

            <EditUserDialog
                open={editDialog}
                handleClose={() => {
                    setPage(-1)
                    setEditDialog(false)
                }}
                userId={targetUser}
            />
        </div>
    )
}