import Box from "@mui/material/Box";
import ReactApexChart from "react-apexcharts";
import {Skeleton, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {AdImpressionsService} from "../../../../services/adImpressions.service";
import toastr from "toastr";
import {CustomColors} from "../../../../themes/custom-colors";
import {useTranslation} from "react-i18next";

export const AdImpressionByDay = (props) => {
    const {t} = useTranslation();
    const {adUrl, userId, loading, setLoading, date} = props;

    const defaultChartOptions = {
        colors: [
            CustomColors.orange,
            CustomColors.red,
            CustomColors.blue,
            CustomColors.green,
            "#C6A664",
            "#000080"
        ],
        chart: {
            type: 'bar',
            height: 500,
            stacked: true
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                hideOverlappingLabels: true,
            },
            categories: []
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    total: { enabled: true },
                },
            },
        },
        fill: {
            opacity: 1

        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center'
        },
        title: {
            text: t("impression_statistic"),
            align: 'left'
        },
        subtitle: {
            text: adUrl,
            align: 'left'
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
        },
    };

    const [data, setData] = useState(null);
    const [chartOptions, setChartOptions] = useState(defaultChartOptions)

    const getData = (inputStartDate = null, inputEndDate = null) => {
        const newStartDate = inputStartDate === null ? date.start_date : inputStartDate;
        const newEndDate = inputEndDate === null ? date.end_date : inputEndDate;

        setLoading(true);
        if (adUrl !== null) {
            AdImpressionsService.getByDateAndAdUrl(newStartDate, newEndDate, adUrl, userId).then(data => {
                if (data.success) {
                    setData(data.data);

                    setChartOptions({
                        ...defaultChartOptions, xaxis: {
                            categories: data.data.categories
                        },
                    })
                } else {
                    setData(null)
                }

                setLoading(false)
            }).catch(e => {
                toastr.error(t("error_loading"));
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        getData();
    }, [date])

    return <Box>
        {
            !loading ? data !== null ? <ReactApexChart
                    height={500}
                    options={chartOptions}
                    type="bar"
                    series={data !== null ? data.series.map(seriesItem => {
                        const newValue = {...seriesItem};
                        newValue.name = t(newValue.name)
                        return newValue;
                    }) : []}
                /> : <Box>
                    <Typography variant={"h5"} align={"center"}>
                        {t("statistic_impression_not_enough")}
                    </Typography>
                </Box>
                : <Skeleton variant={"rectangular"} height={500} sx={{mt: 2}}/>
        }
    </Box>
}