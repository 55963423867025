import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ProxyService} from "../../services/proxy.service";
import {UserService} from "../../services/user.service";
import {AdUrlService} from "../../services/adUrl.service";

let filterTimer;

export const AdUrlSelector = (props) => {
    const {t} = useTranslation();
    const {value, setValue, width, variant, userId} = props;
    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);

    const allItems = [""];


    useEffect(() => {
        if (filterTimer) {
            clearTimeout(filterTimer);
        }

        filterTimer = setTimeout(() => {
            setLoading(true);
            AdUrlService.getAll(inputValue, userId).then(data => {
                if (data.data) {
                    setData(allItems.concat(data.data))
                }
                setLoading(false)
            }).catch(e => {
                setLoading(false)
                toastr.error(t("error_loading"))
            })
        }, 500)

    }, [inputValue]);

    useEffect(() => {
        setInputValue("");
        setData([])
    }, [userId])

    return <Autocomplete
        disablePortal={false}
        options={data}
        freeSolo
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        loading={loading}
        loadingText={t("loading")}
        noOptionsText={t("not_found")}
        value={value}
        onChange={setValue}
        onClick={e => e.stopPropagation()}
        renderInput={(inputParams) =>
            <TextField {...inputParams}
                       sx={width ? {width: width} : {}}
                       fullWidth={true}
                       onClick={e => e.stopPropagation()}
                       variant={variant}
                       label={t("site")}
            />
        }
    />
}