import {Box, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Skeleton, Typography} from "@mui/material";
import {getDomainSource, getPrettifiedDomain} from "../../../../utils/Domain";
import {useTranslation} from "react-i18next";
import * as React from "react"
import {CustomColors} from "../../../../themes/custom-colors";
import {Link} from "react-router-dom";
import AuthService from "../../../../services/auth.service";

export const TopSites = (props) => {
    const {t} = useTranslation();
    const {sites, loading} = props;

    if (!loading) {
        if (sites.length > 0) {
            return (
                <List component={Paper}>
                    {
                        sites.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ListItemButton
                                        sx={{paddingTop: 1.2}}
                                        component={Link}
                                        to={
                                            AuthService.isAdmin() ? `/dashboard/admin/ad-url?ad_url=${item.ad_url}`
                                                : `/dashboard/ad-url?ad_url=${item.ad_url}`
                                        }
                                    >
                                        <ListItemText
                                            primary={t(getDomainSource(item.ad_url))}
                                            secondary={getPrettifiedDomain(item.ad_url)}
                                        />

                                        <Box sx={{textAlign: "center"}}>
                                            <Typography variant={"h5"} color={CustomColors.red}>
                                                {item.count}
                                            </Typography>
                                            <Typography variant={"h5"} color={"grey.300"} fontSize={8}>
                                                {t("ads_1")}
                                            </Typography>
                                        </Box>
                                    </ListItemButton>

                                    {
                                        sites.length - 1 !== index ? <Divider/> : ""
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </List>
            )
        } else {
            return (
                <Paper sx={{p: 3}}>
                    <Typography variant={"h5"}>{t("no_data")}</Typography>
                </Paper>
            )
        }
    } else {
        return (
            <Paper>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60} sx={{mb: 1}}/>
                <Skeleton variant={"rectangular"} height={60}/>
            </Paper>
        )
    }
}