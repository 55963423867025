import {Button, Chip, CircularProgress, IconButton, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {SelectorType} from "../../../../components/ads/AdsCountModal/SelectorType";
import Chart from "react-apexcharts";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {AdsService} from "../../../../services/ads.service";
import toastr from "toastr";
import {ReportCampaignItemService} from "../../../../services/reportCampaignItem.service";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {grey} from "@mui/material/colors";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 1200,
    maxHeight: "90%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingTop: 10
};

const styleCloseButton = {
    position: 'absolute',
    top: '10px',
    right: '10px',
};

const grey400 = grey[400];

export const ReportStatisticModal = (props) => {
    const {t} = useTranslation();
    const {open, handleClose, date, country} = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const pageSize = 12;
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        setPage(-1)
    }, [date])

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (date) {
            setLoading(true);
            ReportCampaignItemService.getDetailedUserStatistic(date, country, page + 1).then(data => {
                if (data.data) {
                    setData(data.data.data);
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"));
                setLoading(false);
                window.scrollTo(0, 0)
            })
        }
    }, [page]);

    const columns = [
        {
            field: 'ad_url',
            headerName: t("site"),
            width: 400,
            sortable: true,
        },
        {
            field: 'sum_total_passes',
            headerName: t("report_plan_count"),
            width: 270,
            sortable: false,
            align: "center",
            renderCell: params => {
                return <strong style={{fontSize: 14}}>{params.row.sum_total_passes}</strong>
            }
        },
        {
            field: 'sum_passes',
            headerName: t("report_resolved_count"),
            width: 250,
            sortable: false,
            align: "center",
            renderCell: params => {
                return <Chip variant={"outlined"} color={"primary"} label={params.row.sum_passes} size={"small"}/>
            }
        }
    ]

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <IconButton sx={styleCloseButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>


                {
                    !loading ? <DataGrid
                        getRowHeight={() => 'auto'}
                        autoHeight={true}
                        sx={{
                            border: 1,
                            borderColor: grey400,
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                            '& .MuiDataGrid-columnSeparator': {
                                color: grey400,
                            },
                            '& .MuiDataGrid-withBorder': {
                                borderColor: grey400,
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                borderColor: grey400,
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderColor: grey400,
                            },
                            maxWidth: 1200
                        }}
                        disableSelectionOnClick={true}
                        showCellRightBorder={true}
                        rows={data}
                        columns={columns}
                        paginationMode={"server"}
                        page={page < 0 ? 0 : page}
                        onPageChange={(newPage) => setPage(newPage)}
                        pageSize={pageSize}
                        rowCount={rowCount}
                        disableColumnMenu={true}
                        loading={loading}
                        disableColumnSelector={true}
                        getRowId={(column) => column.ad_url}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        filterMode="server"
                    /> : <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{my: 2}}>
                        <CircularProgress/> </Box>
                }
            </Box>
        </Modal>
    )
}