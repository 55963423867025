import {Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover} from "@mui/material";
import {useState} from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const MenuDropdown = (props) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const {title, elements} = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if(elements) {
        return (
            <>
                <Button
                    onClick={handleClick}
                    endIcon={<ArrowDropDownIcon/>}
                    sx={{ my: 2, mx:1, color: "#616161", fontWeight: 'bold', display: 'flex' }}
                >
                    {title}
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <List>
                        {
                            elements.map((item) => {
                                return (
                                    <ListItem component={Link}
                                              sx={{ color: "#9e9e9e" }}
                                              to={item.url}
                                              key={item.id}
                                              onClick={handleClose}
                                              disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemIcon sx={{ marginRight: 2 }}>
                                                <item.icon />
                                            </ListItemIcon>
                                            <ListItemText  primary={t(item.title)} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Popover>
            </>
        )
    } else {
        return "";
    }
}