import {Outlet} from 'react-router-dom';

// material-ui
import {Box, Toolbar} from '@mui/material';

// project import
// types
import {HeaderTop} from "./HeaderTop/HeaderTop";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            {/*<Header open={open} handleDrawerToggle={handleDrawerToggle} />*/}
            <HeaderTop />
            {/*<Drawer open={open} handleDrawerToggle={handleDrawerToggle} />*/}
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 }, py: {md: 5} }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
