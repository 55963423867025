import {Box, Card, CardContent, Grid, IconButton, Typography, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TopBox} from "../../user/home/TopBoxes/TopBox";
import {getPercentage} from "../../../utils/Number";
import {AdminAnalyticsService} from "../../../services/adminAnalytics.service";
import {getDatePickerEndDate, getDatePickerStartDate, setLastDates} from "../../../utils/Date";
import {DateRangePickerCustom} from "../../../components/DateRangePickerCustom";
import {ReporterLogChart} from "./charts/ReporterLogChart";
import {ParserLogChart} from "./charts/ParserLogChart";
import {UserLogChart} from "./charts/UserLogChart";
import DeleteIcon from "@mui/icons-material/Delete";
import {CardItem} from "./cardItem";
import StorageIcon from '@mui/icons-material/Storage';
import {grey} from "@mui/material/colors";
import {CustomColors as customColors} from "../../../themes/custom-colors";
import FlagIcon from '@mui/icons-material/Flag';
import ViewListIcon from '@mui/icons-material/ViewList';
import _ from "underscore";


export const AdminHome = () => {
    const {t} = useTranslation();

    const [parserLogs, setParserLogs] = useState([]);
    const [parserLogsToday, setParserLogsToday] = useState({
        count: 0,
        trend: "up",
        percent: ""
    });
    const [parserLogsLoading, setParserLogsLoading] = useState(true);

    const [reporterLogs, setReporterLogs] = useState([]);
    const [reporterLogsToday, setReporterLogsToday] = useState({
        count: 0,
        trend: "up",
        percent: ""
    });
    const [reporterLogsLoading, setReporterLogsLoading] = useState(true);

    const [userLogs, setUserLogs] = useState([]);
    const [userLogsToday, setUserLogsToday] = useState({
        count: 0,
        trend: "up",
        percent: ""
    });
    const [userLogsLoading, setUserLogsLoading] = useState(true);

    const [serversCount, setServersCount] = useState({
        parser: {
            count: 0
        },
        reporter: {
            count: 0
        },
    });
    const [serversLoading, setServersLoading] = useState(true);

    const [subscriptionCount, setSubscriptionCount] = useState({
        reporter_subscription_count: 0,
        parser_subscription_count: 0
    });
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);

    const [filter, setFilter] = useState({
        startDate: getDatePickerStartDate(),
        endDate: getDatePickerEndDate()
    })

    useEffect(() => {
        setParserLogsLoading(true);
        setReporterLogsLoading(true);
        setUserLogsLoading(true);

        AdminAnalyticsService.getParserLogsByDay(filter).then(data => {
            if (data.data) {
                const logs = data.data.slice()
                setParserLogs(logs);

                const logsCount = logs.reduce((previousValue, currentValue) => {
                    return previousValue + Number(currentValue.count)
                }, 0);
                const todayLogs = logs[logs.length - 1];
                const todayLogsCount = todayLogs !== undefined ? todayLogs.count : 0;

                const logsAvg = logsCount / logs.length;
                const newLogsPercent = getPercentage(todayLogsCount, logsAvg);
                const logsPercentFormatted = (newLogsPercent >= 0 ? newLogsPercent : "-" + (newLogsPercent * -1)) + "%";

                setParserLogsToday({
                    count: logsCount,
                    trend: newLogsPercent > 0 ? "up" : "down",
                    percent: logsPercentFormatted
                });
            }

            setParserLogsLoading(false)
        }).catch(e => {
            setParserLogsLoading(false);
        });

        AdminAnalyticsService.getReporterLogsByDay(filter).then(data => {
            if (data.data) {
                const reporterLogs = data.data.slice()
                setReporterLogs(reporterLogs)

                const logsCount = reporterLogs.reduce((previousValue, currentValue) => {
                    return previousValue + Number(currentValue.total_count)
                }, 0);
                const todayLogs = reporterLogs[reporterLogs.length - 1];
                const todayLogsCount = todayLogs !== undefined ? todayLogs.total_count : 0;

                const logsAvg = logsCount / reporterLogs.length;
                const newLogsPercent = getPercentage(todayLogsCount, logsAvg);
                const logsPercentFormatted = (newLogsPercent >= 0 ? newLogsPercent : "-" + (newLogsPercent * -1)) + "%";

                setReporterLogsToday({
                    count: logsCount,
                    trend: newLogsPercent > 0 ? "up" : "down",
                    percent: logsPercentFormatted
                });
            }

            setReporterLogsLoading(false)
        }).catch(e => {
            setReporterLogsLoading(false);
        });

        AdminAnalyticsService.getUserLogsByDay(filter).then(data => {
            if (data.data) {
                const userLogs = data.data.slice()
                setUserLogs(userLogs)

                const logsCount = userLogs.reduce((previousValue, currentValue) => {
                    return previousValue + Number(currentValue.count)
                }, 0);
                const todayLogs = userLogs[userLogs.length - 1];
                const todayLogsCount = todayLogs !== undefined ? todayLogs.count : 0;

                const logsAvg = logsCount / userLogs.length;
                const newLogsPercent = getPercentage(todayLogsCount, logsAvg);
                const logsPercentFormatted = (newLogsPercent >= 0 ? newLogsPercent : "-" + (newLogsPercent * -1)) + "%";

                setUserLogsToday({
                    count: logsCount,
                    trend: newLogsPercent > 0 ? "up" : "down",
                    percent: logsPercentFormatted
                });
            }

            setUserLogsLoading(false)
        }).catch(e => {
            setUserLogsLoading(false);
        });
    }, [filter]);

    useEffect(() => {
        setServersLoading(true);
        setSubscriptionLoading(true);

        AdminAnalyticsService.getActiveServerCounts(filter).then(data => {
            if (data.data) {
                setServersCount(data.data)
            }
            setServersLoading(false)
        }).catch(e => {
            setServersLoading(false);
        });

        AdminAnalyticsService.getSubscriptionCounts(filter).then(data => {
            if (data.data) {
                setSubscriptionCount(data.data)
            }
            setSubscriptionLoading(false)
        }).catch(e => {
            setSubscriptionLoading(false);
        });
    }, [])

    return (
        <Box sx={{py: 3}}>
            <Typography variant="h4">
                {t("home")}
            </Typography>

            <Box sx={{
                my: 3,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-end"
            }}>
                <DateRangePickerCustom
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onApply={(startDate, endDate) => {
                        setLastDates(startDate, endDate)
                        setFilter({...filter, startDate: startDate, endDate: endDate})
                    }}
                />
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TopBox
                        title={t('parser_page_count')}
                        count={parserLogsToday.count}
                        chipValue={parserLogsToday.percent}
                        trend={parserLogsToday.trend}
                        loading={parserLogsLoading}
                    >
                        <ParserLogChart loading={parserLogsLoading} logs={parserLogs}/>
                    </TopBox>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TopBox
                        title={t('reporter_logs')}
                        count={reporterLogsToday.count}
                        chipValue={reporterLogsToday.percent}
                        trend={reporterLogsToday.trend}
                        loading={reporterLogsLoading}
                    >
                        <ReporterLogChart loading={reporterLogsLoading} logs={reporterLogs}/>
                    </TopBox>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TopBox
                        title={t('users')}
                        count={userLogsToday.count}
                        chipValue={userLogsToday.percent}
                        trend={userLogsToday.trend}
                        loading={userLogsLoading}
                    >
                        <UserLogChart loading={userLogsLoading} logs={userLogs}/>
                    </TopBox>
                </Grid>
            </Grid>

            <Grid sx={{mt: 3}} container spacing={2}>
                <Grid item xs={12} md={3}>
                    <CardItem title={t("active_servers")}
                              count={_.has(serversCount, 'parser') ? serversCount.parser.count : 0}
                              loading={serversLoading}
                              subTitle={t("for_parser")}
                              color={customColors.orange}
                              bgColor={"rgba(250, 186, 107, 0.3)"}
                    ><StorageIcon fontSize="inherit"/></CardItem>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CardItem title={t("active_servers")}
                              count={_.has(serversCount, 'reporter') ? serversCount.reporter.count : 0}
                              loading={serversLoading}
                              subTitle={t("for_reporter")}
                              color={customColors.green}
                              bgColor={"rgba(7, 236, 19, 0.3)"}
                    ><StorageIcon fontSize="inherit"/></CardItem>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CardItem title={t("active_subscriptions")}
                              count={subscriptionCount.reporter_subscription_count}
                              loading={subscriptionLoading}
                              subTitle={t("for_reporter")}
                              color={customColors.blue}
                              bgColor={"rgba(7, 109, 236, 0.3)"}
                    ><ViewListIcon fontSize="inherit"/></CardItem>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CardItem title={t("active_subscriptions")}
                              count={subscriptionCount.parser_subscription_count}
                              loading={subscriptionLoading}
                              subTitle={t("for_parser")}
                              color={customColors.purple}
                              bgColor={"rgba(236, 7, 224, 0.3)"}
                    ><FlagIcon fontSize="inherit"/></CardItem>
                </Grid>
            </Grid>
        </Box>
    );
}