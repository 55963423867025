import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

export const ReportCount = ({reportCount, setReportCount}) => {
    const {t} = useTranslation();

    return <FormControl fullWidth>
        <InputLabel>
            {t("campaign_passes")}
        </InputLabel>
        <Select
            name={"passes"}
            value={reportCount}
            label={t("campaign_passes")}
            onChange={(e) => setReportCount(e.target.value)}
        >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
        </Select>
    </FormControl>
}