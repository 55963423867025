import {Button} from "@mui/material";
import {Link} from "react-router-dom";

export const MenuItem = (props) => {
    const {title, url} = props;

    if(title && url) {
        return (
            <>
                <Button
                    component={Link}
                    to={url}
                    sx={{ my: 2, mx:1, color: "#616161", fontWeight: 'bold', display: 'flex' }}
                >
                    {title}
                </Button>
            </>
        )
    } else {
        return "";
    }
}