import {Box, Button} from "@mui/material";
import {MenuDropdown} from "./MenuDropdown";
import {MenuItems, MenuItemsAdmin} from "../../../../config/menuItems";
import {MenuItem} from "./MenuItem";
import AuthService from "../../../../services/auth.service";
import {useTranslation} from "react-i18next";

export const DesktopMenu = () => {
    const {t} = useTranslation();

    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
                (AuthService.isAdmin() ? MenuItemsAdmin : MenuItems).map(item => {
                    if(item.type === "group") {
                        return (
                            <MenuDropdown key={item.id} title={t(item.title)} elements={item.children} />
                        )
                    } else {
                        return (
                            <MenuItem key={item.id} title={t(item.title)} url={item.url} />
                        )
                    }
                })
            }
        </Box>
    )
}