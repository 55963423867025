import axios from "axios";
import qs from "qs";

export class AdImpressionsService {
    static getByDateAndAdUrl(startDate, endDate, adUrl, userId) {
        let filterQuery = qs.stringify({
            start_date: startDate,
            end_date: endDate,
            ad_url: adUrl,
            user_id: userId
        });

        return axios.get(`/dashboard/ad-impressions?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
    static getByDateAndAdUrlAndKeyword(startDate, endDate, adUrl, userId) {
        let filterQuery = qs.stringify({
            start_date: startDate,
            end_date: endDate,
            ad_url: adUrl,
            user_id: userId
        });

        return axios.get(`/dashboard/ad-impressions/by-keyword?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
    static getByHourAndAdUrlAndKeyword(date, adUrl, userId) {
        let filterQuery = qs.stringify({
            date: date,
            ad_url: adUrl,
            user_id: userId
        });

        return axios.get(`/dashboard/ad-impressions/by-hour?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
}