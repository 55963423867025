import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import "./lang/i18n"
import { Provider as ReduxProvider } from 'react-redux';
import toastr from "toastr"
import "toastr/build/toastr.css"

import 'simplebar/src/simplebar.css';
import {store} from "./store";

toastr.options.positionClass = "toast-bottom-right"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReduxProvider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ReduxProvider>
);
