import {Chip, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {CustomColors} from "../../../themes/custom-colors";
import React from "react";
import {useTranslation} from "react-i18next";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

export const ParserInvoice = (props) => {
    const {t} = useTranslation();
    const {id, invoice} = props;

    const getPaymentStatusChip = (status) => {
        if(status === "success") {
            return <Chip variant={"outlined"} color={"success"} label={t("is_paid")} />
        }
        if(status === "pending") {
            return <Chip variant={"outlined"} color={"primary"} label={t("pending")} />
        }
        if(status === "error") {
            return <Chip variant={"outlined"} color={"error"} label={t("error_or_canceled")} />
        }
        if(status === "expired") {
            return <Chip variant={"outlined"} color={"error"} label={t("expired_payment")} />
        }
    }

    const renderDevices = () => {
        return <>
            {
                invoice.details.has_desktop ?
                    <ScreenSearchDesktopIcon color={"grey"} sx={{marginRight: 1}} fontSize={"small"}/> : ""
            }
            {
                invoice.details.has_android ?
                    <AndroidIcon color={"success"} sx={{marginRight: 1}} fontSize={"small"}/> : ""
            }
            {
                invoice.details.has_iphone ? <AppleIcon color={"info"} fontSize={"small"}/> : ""
            }
        </>
    }

    return <>
        <Typography variant={"h1"} align={"center"} color={CustomColors.black} gutterBottom>
            {t("invoice")} #{id}
        </Typography>
        <Typography sx={{ mb: 1.5 }} align={"center"} color="text.secondary">
            {t("invoice_parser_sub_title")}
        </Typography>
        <TableContainer>
            <Table  aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("sum")}
                        </TableCell>
                        <TableCell align="right">{invoice.sum} $</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("parser_daily_interval")}
                        </TableCell>
                        <TableCell align="right">
                            {invoice.details.hour_interval}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("keywords_count")}
                        </TableCell>
                        <TableCell align="right">
                            {invoice.details.keywords_count}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("subscription_expires_date")}
                        </TableCell>
                        <TableCell align="right">
                            {invoice.details.expires_date}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {t("payment_status")}
                        </TableCell>
                        <TableCell align="right">
                            {getPaymentStatusChip(invoice.status)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
}