import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import RegisterForm from "./RegisterForm";

export const Register = () => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                       sx={{mb: {xs: -0.5, sm: 0.5}}}>
                    <Typography variant="h3">{t('registration')}</Typography>
                    <Typography component={Link} to="/login" variant="body1" sx={{textDecoration: 'none'}}
                                color="primary">
                        {t("have_account_sign_in")}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <RegisterForm />
            </Grid>
        </Grid>
    )
}