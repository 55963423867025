import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";

export const HeaderAutocomplete = (props) => {
    const {data, width, label, labelKey, value, setValue, variant, size} = props;

    return (
        <Autocomplete
            disablePortal={false}
            options={data}
            getOptionLabel={option => option[labelKey]}
            sx={{ width: width }}
            freeSolo
            value={value}
            onChange={setValue}
            disableClearable={props.disableClearable || false}
            onClick={e => e.stopPropagation()}
            renderInput={(inputParams) =>
                <TextField {...inputParams}
                           fullWidth
                           onClick={e => e.stopPropagation()}
                           variant={variant || "standard"}
                           size={size || "normal"}
                           label={label}
                />
            }
        />
    )
}