import {DataGrid, ruRU} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Chip, Tooltip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ReportLogFilter} from "./ReportLogFilter";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../utils/Date";
import {ReporterService} from "../../../services/reporter.service";

const grey400 = grey[400];

export const ReportLogDetailed = () => {
    const {t} = useTranslation();

    const pageSize = 24;
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [filter, setFilter] = useState({
        proxy_id: null,
        user_id: null,
        proxy_country: null,
        start_date: getDatePickerStartDate(),
        end_date: getDatePickerEndDate(),
    });

    const columns = [
        {
            field: 'id',
            headerName: "ID",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <div>{params.row.id}</div>
                        <Typography sx={{
                            fontSize: 10,
                            color: grey400
                        }} variant="caption" display="block">
                            {params.row.session_id}
                        </Typography>
                    </div>
                )
            }
        },
        {
            field: 'type',
            headerName: t("type"),
            width: 150,
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => {
                return <Chip color={params.row.type === "error" ? "error" : "primary"}
                             size={"small"}
                             label={t(params.row.type)}
                />
            }
        },
        {
            field: 'server_id',
            headerName: t("server"),
            width: 120,
            sortable: false,
        },
        {
            field: 'user',
            headerName: t("user"),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return params.row.user.name
            }
        },
        {
            field: 'proxy_id',
            headerName: t("proxy"),
            width: 120,
            sortable: false,
            renderCell: (params) => {
                if(params.row.proxy) {
                    return params.row.proxy.name
                } else {
                    return t("no_proxy")
                }
            }
        },
        {
            field: 'proxy_country',
            headerName: t("proxy_country"),
            width: 120,
            sortable: false,
            renderCell: (params) => {
                return <Typography variant="overline" display="block" gutterBottom>
                    {params.row.proxy_country}
                </Typography>
            }
        },
        {
            field: 'details',
            headerName: t("details"),
            width: 300,
            sortable: false
        },
        {
            field: 'created_at',
            headerName: t("created_at"),
            width: 100,
            sortable: false
        }
    ]

    useEffect(() => {
        setPage(-1)
    }, [filter])

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ReporterService.getLogDetailed({
                page: page + 1,
                perPage: pageSize,
                ...filter
            }).then(data => {
                if (data.data) {
                    setLogs(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    return <div>
        <Box sx={{
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography variant="h5">
                {t("report_statistics")}
            </Typography>
        </Box>

        <ReportLogFilter filter={filter} setFilter={setFilter}/>

        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight={true}
            sx={{
                border: 1,
                borderColor: grey400,
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                '& .MuiDataGrid-columnSeparator': {
                    color: grey400,
                },
                '& .MuiDataGrid-withBorder': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-footerContainer': {
                    borderColor: grey400,
                }
            }}
            disableSelectionOnClick={true}
            showCellRightBorder={true}
            rows={logs}
            columns={columns}
            paginationMode={"server"}
            page={page < 0 ? 0 : page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowCount={rowCount}
            disableColumnMenu={true}
            loading={loading}
            disableColumnSelector={true}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            filterMode="server"
        />
    </div>
}