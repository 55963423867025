import {useTranslation} from "react-i18next";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {ProxyService} from "../../../services/proxy.service";
import {MultipleAutocomplete} from "../../../components/autocompletes/MultipleAutocomplete";
import {useEffect, useState} from "react";
import {UserService} from "../../../services/user.service";

export const CreateProxyDialog = (props) => {
    const {handleClose, handleSuccess, open} = props;
    const {t} = useTranslation();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        UserService.getAll(null).then(data => {
            if (data.data) {
                setUsers(data.data.map(user => {
                    return {id: user.id, name: user.name}
                }));
            }
        }).catch(e => {
            toastr.error(t("error_loading"))
        })
    }, []);

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("proxy_creating")}</DialogTitle>

        <Formik
            initialValues={{
                name: '',
                type: 'parser',
                protocol: '',
                url: '',
                port: 0,
                login: '',
                password: '',
                users: []
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(t('required')).max(255),
                type: Yup.string().required(t('required')),
                protocol: Yup.string().required(t('required')),
                url: Yup.string().required(t('required')),
                port: Yup.number().required(t('required')),
                login: Yup.string(),
                password: Yup.string(),
                users: Yup.array()
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);

                const payload = {
                    name: values.name,
                    type: values.type,
                    protocol: values.protocol,
                    url: values.url,
                    port: values.port,
                    login: values.login,
                    password: values.password,
                    users: values.users.map(user => user.id)
                };

                ProxyService.create(payload).then(data => {
                    if (data.success) {
                        toastr.success(t("proxy_created"));
                        handleSuccess();
                    } else {
                        toastr.error(t("error_server"));
                    }

                    setSubmitting(false);
                }).catch(e => {
                    setSubmitting(false);
                    toastr.error(t("error_server"));
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{flexGrow: 1, padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="name"
                                           value={values.name}
                                           label={t("name")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        {t("proxy_type")}
                                    </InputLabel>
                                    <Select
                                        name={"type"}
                                        value={values.type}
                                        label={t("proxy_type")}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"parser"}>{t("proxy_parser")}</MenuItem>
                                        <MenuItem value={"reporter"}>{t("proxy_reporter")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField fullWidth
                                           name="protocol"
                                           value={values.protocol}
                                           label={t("protocol")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.protocol && errors.protocol)}
                                           helperText={Boolean(touched.protocol && errors.protocol) ? errors.protocol : ""}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField fullWidth
                                           name="port"
                                           type={"number"}
                                           value={values.port}
                                           label={t("port")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.port && errors.port)}
                                           helperText={Boolean(touched.port && errors.port) ? errors.port : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth
                                           name="url"
                                           value={values.url}
                                           label={t("url")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.url && errors.url)}
                                           helperText={Boolean(touched.url && errors.url) ? errors.url : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Alert color={"info"} icon={false}>
                                    <p>{t("available_macroses")}: </p>
                                    <p>
                                        <strong>{"{uuid}"}</strong> - {t("unique_session_id")}
                                    </p>
                                    <p>
                                        <strong>{"{country}"}</strong> - {t("proxy_country")}
                                    </p>
                                </Alert>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="login"
                                           value={values.login}
                                           label={t("user_login")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.login && errors.login)}
                                           helperText={Boolean(touched.login && errors.login) ? errors.login : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="password"
                                           value={values.password}
                                           label={t("password")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.password && errors.password)}
                                           helperText={Boolean(touched.password && errors.password) ? errors.password : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <MultipleAutocomplete
                                    equalKey={'id'}
                                    options={users}
                                    externalValues={values.users}
                                    setExternalValues={(newUsers) => setFieldValue('users', newUsers)}
                                    label={t("users")}
                                    placeholder={t("users")}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    disabled={isSubmitting}
                                    sx={{marginTop: 3}}
                                    variant={"outlined"}
                                    type={"submit"}
                                >
                                    {t("create")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        </Formik>
    </Dialog>)
}