import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {ReporterService} from "../../../services/reporter.service";

export const CreateReportDialog = (props) => {
    const {handleClose, handleSuccess, open, showLinks} = props;
    const {t} = useTranslation();

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("creating_report")}</DialogTitle>

        <Formik
            initialValues={{
                name: '',
                passes: 1,
                second_label: 3,
                links: ''
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(t('required')).max(255),
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);
                const payload = {
                    name: values.name,
                    passes: values.passes,
                    second_label: values.second_label,
                    links: values.links.split("\n"),
                };

                ReporterService.create(payload).then(data => {
                    if (data.success) {
                        toastr.success(t("report_created"));
                        handleSuccess(data.data.id);
                    } else {
                        toastr.error(t("error_server"));
                    }

                    setSubmitting(false);
                }).catch(e => {
                    setSubmitting(false);
                    toastr.error(t("error_server"));
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{flexGrow: 1, padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="name"
                                           value={values.name}
                                           label={t("report_name")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        {t("campaign_passes")}
                                    </InputLabel>
                                    <Select
                                        name={"passes"}
                                        value={values.passes}
                                        label={t("campaign_passes")}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        {t("report_type")}
                                    </InputLabel>
                                    <Select
                                        name={"second_label"}
                                        value={values.second_label}
                                        label={t("report_type")}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>
                                            1
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            2
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            3
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            4
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            {t(`mixed`)}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                !!showLinks ? <Grid item xs={12} md={12}>
                                    <TextField fullWidth
                                               multiline
                                               rows={10}
                                               name="links"
                                               value={values.links}
                                               label={t("report_links")}
                                               variant="outlined"
                                               onBlur={handleBlur}
                                               onChange={handleChange}
                                               error={Boolean(touched.links && errors.links)}
                                               helperText={Boolean(touched.links && errors.links) ? errors.links : ""}
                                    />
                                </Grid> : ""
                            }
                            <Grid item xs={12} md={2}>
                                <Button disableElevation
                                        disabled={isSubmitting}
                                        variant={"outlined"}
                                        type={"submit"}
                                >
                                    {t("create")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        </Formik>
    </Dialog>)
}