import {Alert, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {reportsCounts} from "../../../../../../utils/Constants";
import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import _ from "underscore";

export const ReporterDefaultEditFields = (props) => {
    const {values, touched, errors, handleChange, setFieldValue} = props;
    const {t} = useTranslation();

    useEffect(() => {
        console.log(touched, values)
        if (touched.is_custom) {
            setFieldValue("available_reports", Object.keys(reportsCounts)[0])
        }
    }, [values.is_custom]);

    return <>
        <Grid item xs={12} md={12}>
            <FormControl fullWidth>
                <InputLabel>
                    {t("target_report_count")}
                </InputLabel>
                <Select
                    name={"available_reports"}
                    value={values.available_reports}
                    label={t("reports_available")}
                    onChange={handleChange}
                    error={Boolean(touched.available_reports && errors.available_reports)}
                >
                    {
                        Object.keys(reportsCounts).map(item => {
                            return <MenuItem key={item} value={item}>{item}</MenuItem>
                        })
                    }
                </Select>

                {
                    Boolean(touched.available_reports && errors.available_reports) ?
                        <FormHelperText
                            error={true}>{errors.available_reports}</FormHelperText> : ""
                }
            </FormControl>
        </Grid>
        <Grid item xs={12} sx={{my: 1}}>
            <Alert severity={"info"}>
                {
                    t("price_for_reports", {
                        count: values.available_reports
                    })
                } - <strong>{_.has(reportsCounts, values.available_reports) ? reportsCounts[values.available_reports].sum : 0}$</strong>
            </Alert>
        </Grid>
    </>
}