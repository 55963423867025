import {Box, Button, Dialog, DialogTitle, Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import {Formik} from 'formik';
import toastr from "toastr";
import {ProjectsService} from "../../../services/projects.service";

export const CreateDialog = (props) => {
    const {handleClose, handleSuccess, open} = props;
    const {t} = useTranslation();

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("creating_project")}</DialogTitle>

        <Formik
            initialValues={{
                name: '',
                notes: ''
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(t('required')).max(255),
                notes: Yup.string().nullable().max(500, t("max_length", {length: 500})),
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);
                const payload = {
                    name: values.name,
                    notes: values.notes,
                };

                ProjectsService.create(payload).then(data => {
                    if (data.success) {
                        toastr.success(t("project_created"));
                        handleSuccess();
                    } else {
                        toastr.error(t("error_server"));
                    }

                    setSubmitting(false);
                }).catch(e => {
                    setSubmitting(false);
                    toastr.error(t("error_server"));
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{flexGrow: 1, padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="name"
                                           value={values.name}
                                           label={t("project_name")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="notes"
                                           multiline
                                           rows={4}
                                           value={values.notes}
                                           label={t("notes")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.notes && errors.notes)}
                                           helperText={Boolean(touched.notes && errors.notes) ? errors.notes : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button disableElevation
                                        disabled={isSubmitting}
                                        variant={"outlined"}
                                        type={"submit"}
                                >
                                    {t("create")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        </Formik>
    </Dialog>)
}