import _ from "underscore";
import * as React from "react";

export const country = (country, countries) => {
    const countriesArray = country.split(",")
    return (
        <div>
            {
                countriesArray.map((item, index) => {
                    const countryIndex = _.findIndex(countries, {
                        code: item
                    });

                    if (countryIndex > -1) {
                        return (
                            <div key={index}>{countries[countryIndex].label}</div>
                        )
                    } else {
                        return ""
                    }
                })
            }
        </div>
    )
}