import {Box, Chip, FormControl, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

const deviceTypesList = ['android', 'iphone', 'desktop']
export const DeviceType = ({deviceTypes, setDeviceTypes}) => {
    const {t} = useTranslation();

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setDeviceTypes(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return <FormControl fullWidth={true}>
        <Select
            multiple
            displayEmpty={true}
            value={deviceTypes}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
            renderValue={(selected) => {
                if (selected.length > 0) {
                    return <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map((value) => (
                            <Chip key={value} label={t(value)}/>
                        ))}
                    </Box>
                } else {
                    return <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        <Chip label={t('all_device_types')}/>
                    </Box>
                }
            }}
        >
            <MenuItem disabled value="">
                <em>{t("all_device_types")}</em>
            </MenuItem>
            {deviceTypesList.map((deviceType) => (
                <MenuItem
                    key={deviceType}
                    value={deviceType}
                >
                    {t(deviceType)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}