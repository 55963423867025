import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {ProxyService} from "../../services/proxy.service";
import {UserService} from "../../services/user.service";
import {AdUrlService} from "../../services/adUrl.service";
import {ReporterService} from "../../services/reporter.service";

let filterTimer;

export const ReportCampaignSelector = (props) => {
    const {t} = useTranslation();

    const defaultItem = {
        "id": null,
        "name": t("no_selected")
    }

    const {value, setValue, width, variant, error, trigger} = props;
    const [data, setData] = useState([]);
    const [innerValue, setInnerValue] = useState(defaultItem)

    useEffect(() => {
        ReporterService.getAll().then(data => {
            if (data.data) {
                setData([defaultItem].concat(data.data))
            }
        }).catch(e => {
            toastr.error(t("error_loading"))
        })

    }, [trigger]);

    return <Autocomplete
        disablePortal={false}
        options={data}
        freeSolo
        fullWidth={true}
        getOptionLabel={(option) => option.name}
        // filterOptions={(options, params) => {
        //     const filtered = createFilterOptions(options, params);
        //
        //     if (params.inputValue !== '') {
        //         filtered.push({
        //             inputValue: params.inputValue,
        //             title: `Add "${params.inputValue}"`,
        //         });
        //     }
        //
        //     return filtered;
        // }}
        value={innerValue}
        onChange={(e, value) => {
            setInnerValue(value);
            setValue(e, value);
        }}
        onClick={e => e.stopPropagation()}
        renderInput={(inputParams) =>
            <TextField {...inputParams}
                       sx={width ? {width: width} : {}}
                       fullWidth={true}
                       onClick={e => e.stopPropagation()}
                       variant={variant}
                       error={!!error}
                       helperText={!!error ? error : ""}
                       label={t("report_campaigns")}
            />
        }
    />
}