import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";
import {DatePickerCustom} from "../../../../../../components/DatePickerCustom";
import {SubscriptionService} from "../../../../../../services/subscription.service";
import {ParserCustomEditFields} from "./ParserCustomEditFields";
import {ParserDefaultEditFields} from "./ParserDefaultEditFields";
import {calculatePrice} from "../../../../../../utils/Price";
import {InvoicesService} from "../../../../../../services/invoices.service";
import {GeneratedInvoiceModal} from "../GeneratedInvoiceModal";

export const ParserSubscriptionEdit = (props) => {
    const {handleClose, open, subscription, userId} = props;
    const {t} = useTranslation();

    const [initialValues, setInitialValues] = useState({
        hour_interval: 1,
        keywords_count: 100,
        has_desktop: false,
        has_android: false,
        has_iphone: false,
        status: false,
        expires_date: moment().toDate(),
        is_custom: false,
        cost: 0,
        page_per_parse: 1
    });

    const [generatedInvoiceModal, setGeneratedInvoiceModal] = useState(false);
    const [generatedInvoiceId, setGeneratedInvoiceId] = useState("");

    useEffect(() => {
        setInitialValues({
            hour_interval: subscription.hour_interval,
            keywords_count: subscription.keywords_count,
            has_desktop: Boolean(subscription.has_desktop),
            has_android: Boolean(subscription.has_android),
            has_iphone: Boolean(subscription.has_iphone),
            is_custom: Boolean(subscription.is_custom),
            status: Boolean(subscription.status),
            expires_date: subscription.expires_date !== null ?
                moment(subscription.expires_date).toDate() : moment().add("M", 1).toDate(),
            cost: subscription.cost === 0 ? calculatePrice(subscription.hour_interval, subscription.keywords_count) : subscription.cost,
            page_per_parse: subscription.page_per_parse,
            delay_time: null
        })
    }, [subscription]);

    const generateInvoice = (values) => {
        InvoicesService.generateParserInvoice(userId, values).then(data => {
            setGeneratedInvoiceId(data.data.id);
            setGeneratedInvoiceModal(true);
        }).catch(e => {
            toastr.error(toastr.error(e.response.data.message || t("error_server")));
        })
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>{t("editing_parser_subscription")}</DialogTitle>

            <Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        hour_interval: Yup.number().min(1).max(24).required(t('required')),
                        keywords_count: Yup.number().required(t('required')),
                        has_desktop: Yup.bool().required(t('required')),
                        has_android: Yup.bool().required(t('required')),
                        has_iphone: Yup.bool().required(t('required')),
                        status: Yup.bool().required(t('required')),
                        expires_date: Yup.date().required(t('required')),
                        page_per_parse: Yup.number().min(1).max(3).required(t('required')),
                        delay_time: Yup.date().nullable()
                    })}
                    onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                        setSubmitting(true);

                        if (values.has_desktop || values.has_android || values.has_iphone) {
                            if (moment(values.expires_date).isBefore(moment().startOf("day"))) {
                                if (!window.confirm(t("expires_less_then_now"))) {
                                    setSubmitting(false);
                                    return;
                                }
                            }

                            const insertValues = {...values};
                            insertValues.expires_date = moment(insertValues.expires_date).format("YYYY-MM-DD")

                            SubscriptionService.updateParserSubscription(userId, insertValues).then(data => {
                                if (data.success) {
                                    toastr.success(t("subscription_updated"));
                                } else {
                                    toastr.error(data.message || t("error_server"));
                                }

                                setSubmitting(false);
                            }).catch(e => {
                                setSubmitting(false);
                                toastr.error(toastr.error(e.response.data.message || t("error_server")));
                            })
                        } else {
                            toastr.error(t("choose_at_least_one_device"));
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box sx={{p: 3}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name={"is_custom"}
                                                          checked={values.is_custom}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                />
                                            }
                                            label={t("custom_subscription")}/>
                                    </Grid>

                                    {
                                        values.is_custom ?
                                            <ParserCustomEditFields
                                                values={values}
                                                handleChange={handleChange}
                                                handleBluer={handleBlur}
                                                touched={touched}
                                                errors={errors}
                                            /> :
                                            <ParserDefaultEditFields
                                                values={values}
                                                handleChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                            />
                                    }

                                    <Grid item xs={12} md={6}>
                                        <Typography gutterBottom>
                                            {t("parser_devices")}
                                        </Typography>
                                        <FormControl>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={values.has_desktop}
                                                    name={"has_desktop"}
                                                    onChange={handleChange}
                                                />}
                                                label={t("desktop")}
                                            />
                                            {
                                                Boolean(touched.has_desktop && errors.has_desktop) ?
                                                    <FormHelperText
                                                        error={true}>{errors.has_desktop}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                        <FormControl>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={values.has_android}
                                                    name={"has_android"}
                                                    onChange={handleChange}
                                                />}
                                                label="Android"
                                            />
                                            {
                                                Boolean(touched.has_android && errors.has_android) ?
                                                    <FormHelperText
                                                        error={true}>{errors.has_android}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                        <FormControl>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={values.has_iphone}
                                                    name={"has_iphone"}
                                                    onChange={handleChange}
                                                />}
                                                label="IPhone"
                                            />
                                            {
                                                Boolean(touched.has_iphone && errors.has_iphone) ?
                                                    <FormHelperText
                                                        error={true}>{errors.has_iphone}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField fullWidth
                                                   name="page_per_parse"
                                                   value={values.page_per_parse}
                                                   label={t("page_per_parse")}
                                                   type={"number"}
                                                   variant="outlined"
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   error={Boolean(touched.page_per_parse && errors.page_per_parse)}
                                                   helperText={Boolean(touched.page_per_parse && errors.page_per_parse) ? errors.page_per_parse : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DatePickerCustom
                                            onBlur={handleBlur}
                                            date={moment(values.expires_date).toDate()}
                                            onApply={item => setFieldValue("expires_date", moment(item).format("YYYY-MM-DD"))}
                                            error={Boolean(touched.expires_date && errors.expires_date)}
                                            maxDate={moment().add("years", 5).format("YYYY-MM-DD")}
                                        />
                                        {
                                            Boolean(errors.expires_date) ?
                                                <FormHelperText error={true}>{errors.expires_date}</FormHelperText> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={values.status}
                                                    name={"status"}
                                                    onChange={handleChange}
                                                />}
                                                label={t("status")}
                                            />
                                            {
                                                Boolean(errors.status) ?
                                                    <FormHelperText error={true}>{errors.status}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField fullWidth
                                                   name="delay_time"
                                                   value={values.delay_time}
                                                   label={t("update_with_delay")}
                                                   type={"datetime-local"}
                                                   variant="outlined"
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   error={Boolean(touched.delay_time && errors.delay_time)}
                                                   helperText={Boolean(touched.delay_time && errors.delay_time) ? errors.delay_time : t('updating_with_delay_subscription_desc')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button disableElevation
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"submit"}
                                        >
                                            {t("update")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button disableElevation
                                                onClick={() => generateInvoice(values)}
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"button"}
                                                color={"info"}
                                        >
                                            {t("generate_invoice")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>

            <GeneratedInvoiceModal open={generatedInvoiceModal}
                                   invoiceId={generatedInvoiceId}
                                   handleClose={() => setGeneratedInvoiceModal(false)}
            />
        </Dialog>
    )
}