import {Navigate, useRoutes} from "react-router-dom";
import {UserHome} from "./pages/user/home/user.home";
import {AdminHome} from "./pages/admin/home/admin.home";
import {AuthLayout} from "./layouts/auth/AuthLayout";
import {Login} from "./pages/auth/Login/Login";
import {NotFound} from "./pages/common/NotFound";
import {PublicRoute} from "./components/common/PublicRoute";
import {PrivateRoute} from "./components/common/PrivateRoute";
import {AdsAdUrl} from "./pages/user/adsAdUrl/AdsAdUrl"
import MainLayout from "./layouts/main/MainLayout";
import {Ads} from "./pages/user/ads/Ads";
import {Projects} from "./pages/user/projects/Projects";
import {Profile} from "./pages/user/profile/Profile";
import {ReportCampaigns} from "./pages/user/reports/ReportCampaigns";
import {Proxies} from "./pages/admin/proxies/Proxies";
import {ParserLog} from "./pages/admin/parser/ParserLog";
import {ReportLog} from "./pages/admin/report/ReportLog";
import {ReportLogDetailed} from "./pages/admin/report/ReportLogDetailed";
import {Users} from "./pages/admin/user/Users";
import {UserCurrentSubscriptions} from "./pages/user/subscriptions/UserCurrentSubscriptions";
import {Invoice} from "./pages/common/Invoice/Invoice";
import {Payments} from "./pages/user/payments/Payments";
import {Register} from "./pages/auth/Register/Register";
import {AfterRegister} from "./pages/auth/Register/AfterRegister";
import {ConfirmEmail} from "./pages/auth/Register/ConfirmEmail";
import {ResetPassword} from "./pages/auth/ResetPassword/ResetPassword";
import {ResetPasswordFinish} from "./pages/auth/ResetPassword/ResetPasswordFinish";
import {ReportPreferences} from "./pages/user/reports/ReportPreferences/ReportPreferences";
import {ReportStatistic} from "./pages/user/reports/Statistic/ReportStatistic";
import {ProjectsList} from "./pages/admin/projects/ProjectsList";
import {Exports} from "./pages/admin/exports/Exports";
import {KeywordsStatistics} from "./pages/user/keywords/KeywordsStatistics/KeywordsStatistics";

export const Routes = () => {
    return useRoutes([
        {
            path: '/',
            element: <Navigate to={'/login'} replace/>
        },
        {
            element: <PrivateRoute layout={<MainLayout/>} role={"any"}/>,
            children: [
                {
                    path: '/dashboard/invoices/:id',
                    element: <Invoice/>,
                    exact: true
                }
            ]
        },
        {
            element: <PrivateRoute layout={<MainLayout/>} role={"user"}/>,
            children: [
                {
                    path: '/dashboard',
                    element: <UserHome/>,
                    exact: true
                },
                {
                    path: '/dashboard/subscriptions',
                    element: <UserCurrentSubscriptions/>,
                    exact: true
                },
                {
                    path: '/dashboard/ad-url',
                    element: <AdsAdUrl/>,
                    exact: true
                },
                {
                    path: '/dashboard/ads',
                    element: <Ads/>,
                    exact: true
                },
                {
                    path: '/dashboard/projects',
                    element: <Projects/>,
                    exact: true
                },
                {
                    path: '/dashboard/profile',
                    element: <Profile/>,
                    exact: true
                },
                {
                    path: '/dashboard/reports',
                    element: <ReportCampaigns/>,
                    exact: true
                },
                {
                    path: '/dashboard/payments',
                    element: <Payments/>,
                    exact: true
                },
                {
                    path: '/dashboard/report-preferences',
                    element: <ReportPreferences/>,
                    exact: true
                },
                {
                    path: '/dashboard/report-statistic',
                    element: <ReportStatistic/>,
                    exact: true
                },
                {
                    path: '/dashboard/keyword-statistic',
                    element: <KeywordsStatistics/>,
                    exact: true
                },
            ]
        },
        {
            element: <PrivateRoute layout={<MainLayout/>} role={"admin"}/>,
            children: [
                {
                    path: '/dashboard/admin',
                    element: <AdminHome/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/analytics',
                    element: <UserHome role={"admin"}/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/profile',
                    element: <Profile/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/proxies',
                    element: <Proxies/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/parser/log',
                    element: <ParserLog/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/report/log',
                    element: <ReportLog/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/report/log/details',
                    element: <ReportLogDetailed/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/users',
                    element: <Users/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/projects',
                    element: <ProjectsList/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/ad-url',
                    element: <AdsAdUrl role={"admin"}/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/ads',
                    element: <Ads role={"admin"}/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/exports',
                    element: <Exports/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/report-preferences',
                    element: <ReportPreferences/>,
                    exact: true
                },
                {
                    path: '/dashboard/admin/keyword-statistic',
                    element: <KeywordsStatistics/>,
                    exact: true
                },
            ]
        },
        {
            element: <PublicRoute layout={<AuthLayout/>}/>,
            children: [
                {
                    path: 'login',
                    element: <Login/>,
                    exact: true
                },
                {
                    path: 'register',
                    element: <Register/>,
                    exact: true
                },
                {
                    path: 'after-register',
                    element: <AfterRegister/>,
                    exact: true
                },
                {
                    path: 'confirm-email/:token',
                    element: <ConfirmEmail/>,
                    exact: true
                },
                {
                    path: 'reset-password',
                    element: <ResetPassword/>,
                    exact: true
                },
                {
                    path: 'reset-password/:token',
                    element: <ResetPasswordFinish/>,
                    exact: true
                },
            ]
        },
        {path: '*', element: <NotFound/>}
    ])
}