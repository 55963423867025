import ClipboardJS from "clipboard";
import i18n from "../lang/i18n";
import toastr from "toastr";

export const initClipboard = () => {
    const clipboard = new ClipboardJS('[data-clipboard-target]');
    clipboard.on('success', function(e) {
        toastr.success(i18n.t("text_copied"))
        e.clearSelection()
    });
}