import Grid from "@mui/material/Grid";
import {DateRangePickerCustom} from "../../../components/DateRangePickerCustom";
import {setLastDates} from "../../../utils/Date";
import * as React from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ProxySelector} from "../../../components/autocompletes/ProxySelector";
import {UserSelector} from "../../../components/autocompletes/UserSelector";
import {ProjectSelector} from "../../../components/autocompletes/common/ProjectSelector";

export const ParserLogFilter = (props) => {
    const {t} = useTranslation();
    const {filter, setFilter} = props;

    return (
        <Box sx={{my: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <DateRangePickerCustom
                        startDate={filter.start_date}
                        endDate={filter.end_date}
                        onApply={(startDate, endDate) => {
                            setLastDates(startDate, endDate)
                            setFilter({...filter, start_date: startDate, end_date: endDate})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ProxySelector
                        setValue={(e, value) => {
                            setFilter({...filter, proxy_id: value !== null ? value.id : null})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <UserSelector
                        setValue={(e, value) => {
                            setFilter({...filter, user_id: value !== null ? value.id : null})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ProjectSelector
                        setValue={(e, value) => {
                            setFilter({...filter, project_id: value !== null ? value.id : null})
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}