import {Alert, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {dateToDefaultFormat, lessThanDays} from "../../../../utils/Date";
import {grey, orange, red} from "@mui/material/colors";

const grey_ = grey[600];
const orange_ = orange[400];
const red_ = red[400]

export const ParserSubscriptionWidget = (props) => {
    const {t} = useTranslation();

    const {subscription} = props;
    const [attentionColor, setAttentionColor] = useState(grey_);

    useEffect(() => {
        if (!!subscription) {
            if (lessThanDays(subscription.expires_date, 2)) {
                setAttentionColor(red_)
                return;
            }
            if (lessThanDays(subscription.expires_date, 10)) {
                setAttentionColor(orange_)
                return;
            }


            setAttentionColor(grey_)
        }
    }, [subscription]);

    if (!!subscription) {
        if (subscription.status) {
            return <Alert
                sx={{
                    mr: 1,
                    width: {
                        xs: "100%",
                        sm: "auto"
                    },
                    mb: {
                        xs: 3,
                        sm: 0,
                        md: 0
                    }
                }}
                icon={false}
                variant={"outlined"}
                severity={"primary"}
            >
                <strong style={{color: attentionColor}}>{t("parser_subscription")}</strong><br/>
                {t("subscription_expires_date")}: <Typography display={"inline"}
                                                              color={attentionColor}>{dateToDefaultFormat(subscription.expires_date)}</Typography>
                <br/>
                {subscription.hour_interval}/{t('day')}, {t("keywords_count")} {subscription.keywords_count}
            </Alert>
        }
    } else return null
}