import {
    Backdrop,
    Box,
    Divider,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Modal,
    Skeleton,
    Typography
} from "@mui/material";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {useTranslation} from "react-i18next";
import {Fragment, useEffect, useState} from "react";
import {AdUrlService} from "../../../../services/adUrl.service";
import toastr from "toastr";
import {useNavigate} from "react-router-dom";
import * as React from "react";
import {getLastSelectedUser} from "../../../../utils/Paginations";
import AuthService from "../../../../services/auth.service";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: 600,
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const TopKeywordsModal = ({open, setOpen, keyword}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [urlPrefix, setUrlPrefix] = useState('/dashboard/ad-url');

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (keyword !== null) {
            setLoading(true);

            AdUrlService.getByKeyword(keyword.id, getLastSelectedUser()).then(data => {
                if (data.data) {
                    setSites(data.data.slice());
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                toastr.error(t("error_loading"))
            })
        }
    }, [keyword])

    useEffect(() => {
        if(AuthService.isAdmin()) {
            setUrlPrefix('/dashboard/admin/ad-url')
        }
    }, [])

    const renderNoData = () => {
        return <Typography variant={"h6"} align={"center"} marginBottom={1}>
            {t('no_data')}
        </Typography>
    }

    const renderLoading = () => {
        return <Box>
            <Skeleton variant={"rectangular"} height={40} sx={{mb: 1}}/>
            <Skeleton variant={"rectangular"} height={40} sx={{mb: 1}}/>
            <Skeleton variant={"rectangular"} height={40} sx={{mb: 1}}/>
            <Skeleton variant={"rectangular"} height={40} sx={{mb: 1}}/>
        </Box>
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                {
                    keyword !== null ? <Box sx={style}>
                        <Typography variant={"h5"} align={"center"} marginBottom={2}>
                            {t('sites_by_keyword', {
                                name: keyword.name
                            })}
                        </Typography>
                        {
                            loading ? renderLoading() : sites.length > 0 ? <List dense={true}>
                                {
                                    sites.map((site, index) => {
                                        return <Fragment key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton onClick={() => {
                                                        navigate(`${urlPrefix}?ad_url=${site.ad_url}`)
                                                    }}>
                                                        <ManageSearchIcon/>
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText
                                                    primary={site.ad_url}
                                                />
                                            </ListItem>
                                            <Divider/>
                                        </Fragment>
                                    })
                                }
                            </List> : renderNoData()
                        }
                    </Box> : <Box sx={style}>
                        {renderNoData()}
                    </Box>
                }
            </Fade>
        </Modal>
    )
}