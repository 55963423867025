import axios from "axios";

class AuthService {
    static login(email, password) {
        return axios
            .post(`/auth/login`, {
                email,
                password
            })
            .then((response) => {
                if (response.data.success) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                }

                return response.data;
            });
    }

    static register(data) {
        return axios
            .post(`/auth/register`, data)
            .then((response) => {
                return response.data;
            });
    }

    static forgotPassword(email) {
        return axios
            .post(`/auth/forgot-password`, {
                email
            })
            .then((response) => {
                return response.data;
            });
    }

    static forgotPasswordCheckToken(token) {
        return axios
            .post(`/auth/forgot-password/${token}`)
            .then((response) => {
                return response.data;
            });
    }

    static resetPassword(token, password) {
        return axios
            .post(`/auth/reset-password/${token}`, {
                password
            })
            .then((response) => {
                return response.data;
            });
    }

    static confirmEmail(token) {
        return axios
            .get(`/public/auth/confirm-email?token=${token}`)
            .then((response) => {
                return response.data;
            });
    }

    static logout() {
        return axios.post(`/auth/logout`).then((response) => {
            if (response.data.status) {
                localStorage.removeItem('user');
            }

            return response.data;
        });
    }

    static me() {
        return axios.get(`me`).then((response) => {
            return response.data;
        })
    }

    static getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    static isAuthed() {
        return AuthService.getCurrentUser() !== null;
    }

    static getCurrentRole() {
        const user = this.getCurrentUser();

        if (user) {
            return user.role;
        }

        return null;
    }

    static getCurrentUserName() {
        const user = this.getCurrentUser();

        if (user) {
            return user.name;
        }

        return "User";
    }

    static updateLocalUserName(newUserName) {
        const user = this.getCurrentUser();

        user.name = newUserName;
        localStorage.setItem("user", JSON.stringify(user));
    }

    static isAdmin() {
        return this.getCurrentRole() === "admin"
    }
}

export default AuthService;