import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import AuthService from "../../services/auth.service";

export const PublicRoute = (props) => {
    const {layout} = props

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [role, setRole] = useState(AuthService.getCurrentRole())

    useEffect(() => {
        AuthService.me().then(
            (data) => {
                console.log(Object.keys(data.data).length)
                if (Object.keys(data.data).length > 0) {
                    setIsAuthenticated(true)
                    setRole(data.data.role)
                } else {
                    setIsAuthenticated(false)
                }
            },
            (error) => {
                setIsAuthenticated(false);
            }
        );
    }, []);

    if (isAuthenticated) {
        if (role === "admin") {
            return <Navigate to={"dashboard/admin"} replace/>
        }
        if (role === "user") {
            return <Navigate to={"dashboard"} replace/>
        }
    } else {
        return layout
    }
}