import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import {ReporterService} from "../../../services/reporter.service";
import {UserService} from "../../../services/user.service";

export const CreateUserDialog = (props) => {
    const {handleClose, handleSuccess, open} = props;
    const {t} = useTranslation();

    return (<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>{t("creating_user")}</DialogTitle>

        <Formik
            initialValues={{
                name: '',
                telegram: '',
                email: '',
                password: '',
                role: 'user'
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(t('required')),
                telegram: Yup.string(),
                email: Yup.string().email(t('email_invalid')).max(255).required(t('required')),
                password: Yup.string().min(8, t('min_length_8')).required(t('required')),
                role: Yup.string().required(t('required'))
            })}
            onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                setSubmitting(true);
                const payload = {
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    role: values.role,
                    telegram: values.telegram
                };

                UserService.create(payload).then(data => {
                    if (data.success) {
                        toastr.success(t("user_created"));
                        handleSuccess(data.data.id);
                    } else {
                        toastr.error(t("user_not_created"));
                    }

                    setSubmitting(false);
                }).catch(e => {
                    setSubmitting(false);
                    toastr.error(e.response.data.message || t("error_server"));
                })
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{flexGrow: 1, padding: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth
                                           name="name"
                                           value={values.name}
                                           label={t("user_name")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.name && errors.name)}
                                           helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth
                                           name="email"
                                           value={values.email}
                                           label={"E-mail"}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.email && errors.email)}
                                           helperText={Boolean(touched.email && errors.email) ? errors.email : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        {t("role")}
                                    </InputLabel>
                                    <Select
                                        name={"role"}
                                        value={values.role}
                                        label={t("role")}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"user"}>
                                            {t("user")}
                                        </MenuItem>
                                        <MenuItem value={"admin"}>
                                            {t("admin")}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="password"
                                           type={"password"}
                                           value={values.password}
                                           label={t("password")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.password && errors.password)}
                                           helperText={Boolean(touched.password && errors.password) ? errors.password : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth
                                           name="telegram"
                                           value={values.telegram}
                                           label={t("Telegram")}
                                           variant="outlined"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           error={Boolean(touched.telegram && errors.telegram)}
                                           helperText={Boolean(touched.telegram && errors.telegram) ? errors.telegram : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button disableElevation
                                        disabled={isSubmitting}
                                        variant={"outlined"}
                                        type={"submit"}
                                >
                                    {t("create")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        </Formik>
    </Dialog>)
}