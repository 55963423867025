import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import {useTranslation} from "react-i18next";

export const AlertDialog = (props) => {
    const {t} = useTranslation();
    const {title, content, open, handleClose, handleCancel, handleOk, loading} = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!!loading ? <LinearProgress /> : ""}
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} disabled={!!loading}>
                    {t("cancel")}
                </Button>
                <Button onClick={handleOk} autoFocus disabled={!!loading}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}