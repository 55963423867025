import axios from "axios";

export class SubscriptionService {
    static hasAnySubscription() {
        return axios.get(`/dashboard/subscriptions/has-any`).then((response) => {
            return response.data;
        });
    }

    static hasReporterSubscription() {
        return axios.get(`/dashboard/subscriptions/has-reporter-subscription`).then((response) => {
            return response.data;
        });
    }

    static hasParserSubscription() {
        return axios.get(`/dashboard/subscriptions/has-parser-subscription`).then((response) => {
            return response.data;
        });
    }

    static updateParserSubscription(userID, data) {
        return axios.post(`/dashboard/subscriptions/updateParser/${userID}`, data).then((response) => {
            return response.data;
        });
    }

    static updateCurrentParserSubscription(data) {
        return axios.post(`/dashboard/subscriptions/updateParser`, data).then((response) => {
            return response.data;
        });
    }

    static setTemporaryParserSubscription(data) {
        return axios.post(`/dashboard/subscriptions/setTempParser`, data).then((response) => {
            return response.data;
        });
    }

    static updateCurrentReporterSubscription(data) {
        return axios.post(`/dashboard/subscriptions/updateReporter`, data).then((response) => {
            return response.data;
        });
    }

    static updateReporterSubscription(userID, data) {
        return axios.post(`/dashboard/subscriptions/updateReporter/${userID}`, data).then((response) => {
            return response.data;
        });
    }


}