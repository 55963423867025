import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid, ruRU} from '@mui/x-data-grid';
import {AdsService} from "../../../services/ads.service";
import {useTranslation} from "react-i18next";
import {adUrl} from "../../../components/ads/table/column/adUrl";
import {keywords} from "../../../components/ads/table/column/keywords";
import {device} from "../../../components/ads/table/column/device";
import {KeywordsModal} from "../../../components/ads/KeywordsModal";
import {grey} from "@mui/material/colors";
import {getParsedKeywords} from "../../../utils/Keywords";
import {Alert, Link, TextField, Typography} from "@mui/material";
import {CountryService} from "../../../services/country.service";
import {ProjectsService as ProjectService} from "../../../services/projects.service";
import {country} from "../../../components/ads/table/column/country";
import {HeaderSelect} from "../../../components/autocompletes/HeaderSelect";
import {HeaderAutocomplete} from "../../../components/autocompletes/HeaderAutocomplete";
import {device as deviceHeader} from "../../../components/ads/table/header/device";
import {KeywordsService} from "../../../services/keywords.service";
import {AdsAdUrlFilter} from "../adsAdUrl/AdsAdUrlFilter";
import moment from "moment";
import {RouterLink} from "../../../components/RouterLink";
import {getDatePickerEndDate, getDatePickerStartDate} from "../../../utils/Date";
import {AdUrlSelector} from "../../../components/autocompletes/AdUrlSelector";
import {AddReportClickUrl} from "./AddReportClickUrl";
import {SubscriptionService} from "../../../services/subscription.service";
import toastr from "toastr";
import {OtherSubscriptionAlert} from "../../../components/subscriptions/OtherSubscriptionAlert";
import {getLastPageSize, getLastSelectedUser, setLastPageSize} from "../../../utils/Paginations";

const grey400 = grey[400];
const sortDefault = {
    field: "first_seen_at",
    order: "desc"
};

let filterTimer;

export const Ads = (props) => {
    const {t} = useTranslation();
    const {role} = props;

    const params = new URLSearchParams(window.location.search);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [started, setStarted] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(getLastPageSize());

    const [keyChecked, setKeyChecked] = useState(false);

    const [countries, setCountries] = useState([]);
    const [projects, setProjects] = useState([]);
    const [keywordsList, setKeywordsList] = useState([]);
    const [targetProject, setTargetProject] = useState({
        id: 0,
        name: ""
    });
    const [targetKeyword, setTargetKeyword] = useState({
        id: 0,
        name: ""
    });

    const [keywordsModal, setKeywordsModal] = useState(false);
    const [keywordsModalList, setKeywordsModalList] = useState([]);
    const [keywordsModalProjectId, setKeywordsModalProjectId] = useState(null);
    
    const [parserSubscription, setParserSubscription] = useState(true);
    const [reporterSubscription, setReporterSubscription] = useState(false);

    const [filter, setFilter] = React.useState({
        adUrl: params.get("ad_url") || "",
        projectId: Number(params.get("project_id")) || "",
        keywordId: Number(params.get("keyword_id")) || "",
        title: "",
        description: "",
        country: "",
        devices: 0,
        startDate: getDatePickerStartDate(),
        endDate: getDatePickerEndDate(),
        isNew: false,
        isActive: false,
        source: 0,
        user_id: getLastSelectedUser(),
        device: 0
    });
    const [sort, setSort] = useState(sortDefault);

    const columns = [
        {
            field: 'project_name',
            headerName: t("project"),
            width: 120,
            sortable: false,
            renderHeader: (params) => {
                return (
                    <div>
                        <HeaderSelect label={params.colDef.headerName}
                                      value={targetProject.id}
                                      values={projects}
                                      width={100}
                                      onChange={(e, value) => {
                                          setFilter({...filter, projectId: value !== null ? value.props.value : ""})
                                          setTargetProject({
                                              id: value.props.value,
                                              name: value.props.children
                                          })
                                      }}
                        />
                    </div>
                )
            },
            renderCell: params => {
                return (
                    <div>
                        <div>{params.row.project_name}</div>
                        {
                            reporterSubscription ? <AddReportClickUrl ad={params.row} /> : ""
                        }
                    </div>
                )
            },
        },
        {
            field: 'ad_url',
            headerName: t("site"),
            flex: 1,
            minWidth: 215,
            sortable: false,
            renderCell: params => {
                return adUrl(params, (e) => {
                    e.preventDefault();
                    setFilter({...filter, adUrl: e.target.textContent})
                }, true);
            },
            renderHeader: (params) => {
                return (
                    <AdUrlSelector
                        variant={"standard"}
                        width={210}
                        userId={filter.user_id}
                        value={filter.adUrl}
                        setValue={(e, value) => {
                            setFilter({...filter, adUrl: value})
                        }}
                    />
                )
            },
        },
        {
            field: 'click_url',
            headerName: t("full_ad_url"),
            minWidth: 170,
            sortable: false,
            renderCell: params => {
                return <Link target={"_blank"}
                             href={params.row.click_url}
                             sx={{
                                 textOverflow: "ellipsis",
                                 overflow: "hidden"
                             }}
                >
                    {
                        params.row.click_url
                    }
                </Link>
            }
        },
        {
            field: 'keywords',
            headerName: t("keywords"),
            width: 180,
            sortable: false,
            renderCell: params => {
                return keywords(params, () => {
                    setKeywordsModalList(getParsedKeywords(params.row.keywords, -1));
                    setKeywordsModalProjectId(params.row.project_id);
                    setKeywordsModal(true)
                }, value => {
                    setFilter({...filter, keywordId: value.id})
                    setTargetKeyword(value)
                })
            },
            renderHeader: (params) => {
                return (
                    <div>
                        <HeaderAutocomplete
                            width={160}
                            labelKey={"name"}
                            label={params.colDef.headerName}
                            data={keywordsList}
                            value={targetKeyword}
                            setValue={(e, value) => {
                                setFilter({...filter, keywordId: value !== null ? value.id : ""})
                                setTargetKeyword(value)
                            }}
                        />
                    </div>
                )
            },
        },
        {
            field: 'first_seen_at',
            headerName: t("first_seen_at"),
            width: 90,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'last_seen_at',
            headerName: t("last_seen_at"),
            width: 90,
            valueFormatter: params => {
                return moment(params.value).format("YYYY-MM-DD H:mm")
            }
        },
        {
            field: 'title',
            headerName: t("title"),
            minWidth: 180,
            sortable: false,
            renderHeader: (params) => {
                return (
                    <div>
                        <TextField label={t("title")}
                                   variant="standard"
                                   sx={{ width: 215}}
                                   value={filter.title}
                                   onChange={(e) => {
                                       setFilter({...filter, title: e.target.value})
                                   }}
                        />
                    </div>
                )
            },
        },
        {
            field: 'description',
            headerName: t("description"),
            minWidth: 180,
            sortable: false,
            renderHeader: (params) => {
                return (
                    <div>
                        <TextField label={t("description")}
                                   variant="standard"
                                   fullWidth
                                   value={filter.description}
                                   onChange={(e) => {
                                       setFilter({...filter, description: e.target.value})
                                   }}
                        />
                    </div>
                )
            },
        },
        {
            field: 'country',
            headerName: t("countries"),
            width: 130,
            sortable: false,
            renderCell: params => {
                return country(params.row.country, countries);
            },
            renderHeader: (params) => {
                return (
                    <div>
                        <HeaderAutocomplete
                            width={115}
                            labelKey={"label"}
                            label={params.colDef.headerName}
                            data={countries}
                            setValue={(e, value) => {
                                setFilter({...filter, country: value !== null ? value.code : ""})
                            }}
                        />
                    </div>
                )
            },
        },
        {
            field: 'is_desktop',
            headerName: t("devices"),
            width: 100,
            sortable: false,
            renderCell: params => {
                return device(params);
            },
            renderHeader: params => {
                return deviceHeader(params, (e, value) => {
                    setFilter({...filter, devices: value !== null ? value.props.value : 0})
                }, filter.devices)
            },
        },
    ];

    useEffect(() => {
        setPage(0)
    }, [filter])

    useEffect(() => {
        if (filterTimer) {
            clearTimeout(filterTimer);
        }

        filterTimer = setTimeout(() => {
            getAds();
        }, 1000)
    }, [page, sort, filter, pageSize])

    useEffect(() => {
        if (targetProject.id !== 0) {
            KeywordsService.getByProjectId(targetProject.id).then(data => {
                if (data.data) {
                    setKeywordsList(data.data);

                    if (keyChecked) {
                        setFilter({...filter, keywordId: ""})
                    } else {
                        const paramKeyword = params.get("keyword_id");

                        if (paramKeyword === null) {
                            setFilter({...filter, keywordId: ""})
                        } else {
                            data.data.forEach(item => {
                                if (item.id === Number(paramKeyword)) {
                                    setTargetKeyword(item);
                                }
                            })
                        }

                        setKeyChecked(true);
                    }
                }
            })
        }
    }, [targetProject])

    useEffect(() => {
        CountryService.get().then(data => {
            if (data.data) {
                setCountries(Object.keys(data.data).map(item => {
                    return {
                        code: item.toLowerCase(),
                        label: data.data[item]
                    }
                }))
            }
        })
    }, []);

    useEffect(() => {
        ProjectService.get(1, null, true, null, filter.user_id).then(data => {
            if (data.data) {
                setProjects(data.data)

                const projectId = params.get("project_id");

                if (projectId) {
                    data.data.forEach(item => {
                        if (item.id === Number(projectId)) {
                            setTargetProject(item)
                        }
                    })
                }
            }
        })
    }, [filter.user_id])


    useEffect(() => {
        if(role !== "admin") {
            SubscriptionService.hasParserSubscription().then(data => {
                setParserSubscription(data.success);
            }).catch(e => {
                toastr.error(t("any_subscription_error"))
                setParserSubscription(true) // Just to hide alert if error on server
            })
            SubscriptionService.hasReporterSubscription().then(data => {
                setReporterSubscription(data.success);
            }).catch(e => {
                toastr.error(t("any_subscription_error"))
                setParserSubscription(true) // Just to hide alert if error on server
            })
        }
    }, []);

    const getAds = () => {
        if (!loading) {
            if((role === "admin" && filter.user_id === null)) return;

            setLoading(true)
            setStarted(true);
            AdsService.getAds(page + 1, sort.field, sort.order, pageSize, filter).then(data => {
                setLoading(false)
                setData(data.data.data);
                setRowCount(data.data.total);
                window.scrollTo(0, 0)
            }).catch(e => {
                setLoading(false)
                window.scrollTo(0, 0)
                console.log(e)
            })
        }
    }

    const handleSortModelChange = (sortModel) => {
        if (sortModel.length === 0) {
            setSort(sortDefault)
        } else {
            setSort({
                field: sortModel[0].field,
                order: sortModel[0].sort
            })
        }
        setPage(0);
    };

    return (
        <div style={{width: '100%'}}>
            <OtherSubscriptionAlert subscription={parserSubscription} title={t("empty_parser_subscriptions")}/>
            {
                !params.entries().next().done ? <RouterLink
                    text={t("return_to_sites")}
                    href={`/dashboard/${role === "admin" ? "admin/" : ""}ad-url`}
                    sx={{display: "block", marginBottom: 2, opacity: .9, color: grey400}}
                /> : ""
            }
            <Typography variant="h5" sx={{marginBottom: 2}}>
                {t("ads")}
            </Typography>
            <AdsAdUrlFilter role={role} hasExport={true} filter={filter} setFilter={setFilter}/>

            {
                role === "admin" && filter.user_id === null ? <div>
                    <Alert severity={"warning"}>{t("select_user_to_continue")}</Alert>
                </div> : <div>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        autoHeight={true}
                        sx={{
                            border: 1,
                            borderColor: grey400,
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                            '& .MuiDataGrid-columnSeparator': {
                                color: grey400,
                            },
                            '& .MuiDataGrid-withBorder': {
                                borderColor: grey400,
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                borderColor: grey400,
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderColor: grey400,
                            },
                        }}
                        disableSelectionOnClick={true}
                        showCellRightBorder={true}
                        rows={data}
                        columns={columns}
                        paginationMode={"server"}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        pageSize={pageSize}
                        pagination
                        onPageSizeChange={(pageSize) => {
                            setPageSize(pageSize)
                            setLastPageSize(pageSize)
                        }}
                        rowsPerPageOptions={[10,20,50]}
                        rowCount={rowCount}
                        disableColumnMenu={true}
                        loading={loading || !started}
                        disableColumnSelector={true}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        filterMode="server"
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                    />

                    <KeywordsModal open={keywordsModal}
                                   inputKeywords={keywordsModalList}
                                   adUrl={null}
                                   projectId={keywordsModalProjectId}
                                   onItemSelect={(item, projectId) => {
                                       setKeywordsModal(false);
                                       setTargetKeyword(item);
                                       setFilter({...filter, keywordId: item.id})
                                   }}
                                   handleClose={() => setKeywordsModal(false)}
                    />
                </div>
            }
        </div>
    )
}