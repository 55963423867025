import {Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

export const ReporterCustomEditFields = (props) => {
    const {values, touched, errors, handleChange, handleBlur} = props;
    const {t} = useTranslation();
    
    return <>
        <Grid item xs={12} md={6}>
            <TextField fullWidth
                       name={"available_reports"}
                       value={values.available_reports}
                       label={t("target_report_count")}
                       variant="outlined"
                       type={"number"}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       error={Boolean(touched.available_reports && errors.available_reports)}
                       helperText={Boolean(touched.available_reports && errors.available_reports) ? errors.available_reports : ""}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField fullWidth
                       name={"custom_price"}
                       value={values.custom_price}
                       label={t("subscription_price")}
                       variant="outlined"
                       type={"number"}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       error={Boolean(touched.custom_price && errors.custom_price)}
                       helperText={Boolean(touched.custom_price && errors.custom_price) ? errors.custom_price : ""}
            />
        </Grid>
    </>
}