import {Box, Button, Chip, Switch, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataGrid, GridActionsCellItem, ruRU} from "@mui/x-data-grid";
import toastr from "toastr";
import {grey} from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {ProxyService} from "../../../services/proxy.service";
import {CreateProxyDialog} from "./CreateProxyDialog";
import {AlertDialog} from "../../../components/AlertDialog";
import {EditProxyDialog} from "./EditProxyDialog";
import {getProxiesUsersText} from "../../../utils/Proxies";

const grey400 = grey[400];

export const Proxies = (props) => {
    const {t} = useTranslation();

    const pageSize = 24;
    const [proxies, setProxies] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [targetProxy, setTargetProxy] = useState(null);
    const [createDialog, setCreateDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [editId, setEditId] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [togglingActive, setTogglingActive] = useState(false);

    const columns = [
        {
            field: 'id',
            headerName: "ID",
            width: 50,
            sortable: false,
        },
        {
            field: 'name',
            headerName: t("name"),
            width: 140,
            sortable: false,
            renderCell: params => {
                return <strong>{params.row.name}</strong>
            }
        },
        {
            field: 'type',
            headerName: t("proxy_type"),
            width: 170,
            align: "center",
            sortable: false,
            renderCell: params => {
                if (params.row.type === "parser") {
                    return <Chip variant="outlined" size={"small"} label={t("proxy_parser")} color={"primary"}/>
                } else {
                    return <Chip variant="outlined" size={"small"} label={t("proxy_reporter")} color={"warning"}/>
                }
            }
        },
        {
            field: "is_active",
            headerName: t("is_active"),
            width: 70,
            align: "center",
            sortable: false,
            renderCell: params => {
                return <Switch
                    disabled={togglingActive}
                    checked={Boolean(proxies.find(item => params.row.id === item.id).is_active || false)}
                    onChange={() => handleToggleActive(params.row.id)}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {
            field: 'url',
            headerName: t("url"),
            width: 200,
            sortable: false,
        },
        {
            field: 'port',
            headerName: t("port"),
            width: 70,
            align: "center",
            sortable: false,
        },
        {
            field: 'protocol',
            headerName: t("protocol"),
            width: 80,
            align: "center",
            sortable: false,
        },
        {
            field: 'login',
            headerName: t("login"),
            width: 220,
            sortable: false,
        },
        {
            field: 'password',
            headerName: t("password"),
            width: 220,
            sortable: false,
        },
        {
            field: 'users',
            headerName: t("user"),
            width: 140,
            sortable: false,
            valueFormatter: params => {
                return getProxiesUsersText(params.value)
            }
        },
        {
            field: "action",
            headerName: t("actions"),
            width: 100,
            sortable: false,
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    color={"primary"}
                    icon={<EditIcon/>}
                    onClick={() => {
                        setEditId(params.row.id)
                        setEditDialog(true);
                    }}
                    label={"2"}
                />,
                <GridActionsCellItem
                    color={"error"}
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setDeleteDialog(true);
                        setTargetProxy(params.row.id)
                    }}
                    label={"2"}
                />,
            ]
        }
    ]

    useEffect(() => {
        if (page === -1) {
            setPage(0);
            return;
        }

        if (!loading) {
            setLoading(true);
            ProxyService.get(page + 1, pageSize).then(data => {
                if (data.data) {
                    setProxies(data.data.data)
                    setRowCount(data.data.total);
                    window.scrollTo(0, 0)
                }
                setLoading(false);
            }).catch(e => {
                toastr.error(t("error_loading"))
                window.scrollTo(0, 0)
                setLoading(false);
            })
        }
    }, [page]);

    const handleDelete = () => {
        if (targetProxy !== null) {
            ProxyService.delete(targetProxy).then(data => {
                if (data.success) {
                    toastr.success(t("proxy_deleted"))
                    setPage(-1);
                } else {
                    toastr.error(t("proxy_not_deleted"));
                }

                setDeleteDialog(false);
            }).catch(e => {
                setDeleteDialog(false);
                toastr.error(t("error_server"));
            })
        }
    }

    const handleToggleActive = (proxyId) => {
        setTogglingActive(true);

        let previousValue = false;

        const newProxies = proxies.map(item => {
            if (proxyId === item.id) {
                previousValue = item.is_active;
                item.is_active = !item.is_active
            }

            return item;
        });

        setProxies([...newProxies])

        ProxyService.toggleActive(proxyId).then(data => {
            if (data.success) {
                if (previousValue) {
                    toastr.success(t("proxy_diactivated"))
                } else {
                    toastr.success(t("proxy_activated"))
                }
            } else {
                toastr.error(t("error_server"));
            }

            setTogglingActive(false)
        }).catch(e => {
            setTogglingActive(false)
            toastr.error(t("error_server"));
        })
    }

    return <div>
        <Box sx={{
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography variant="h5">
                {t("proxies")}
            </Typography>

            <Button
                startIcon={<AddIcon/>}
                variant={"contained"}
                onClick={() => setCreateDialog(true)}
            >
                {t("add")}
            </Button>
        </Box>

        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight={true}
            sx={{
                border: 1,
                borderColor: grey400,
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
                '& .MuiDataGrid-columnSeparator': {
                    color: grey400,
                },
                '& .MuiDataGrid-withBorder': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderColor: grey400,
                },
                '& .MuiDataGrid-footerContainer': {
                    borderColor: grey400,
                }
            }}
            disableSelectionOnClick={true}
            showCellRightBorder={true}
            rows={proxies}
            columns={columns}
            paginationMode={"server"}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            rowCount={rowCount}
            disableColumnMenu={true}
            loading={loading}
            disableColumnSelector={true}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            filterMode="server"
        />

        <CreateProxyDialog open={createDialog}
                           handleClose={() => setCreateDialog(false)}
                           handleSuccess={() => {
                               setPage(-1)
                               setCreateDialog(false)
                           }}
        />

        <EditProxyDialog open={editDialog}
                         proxyId={editId}
                         handleClose={() => setEditDialog(false)}
                         handleSuccess={() => {
                             setPage(-1)
                         }}
        />

        <AlertDialog
            open={deleteDialog}
            handleClose={() => setDeleteDialog(false)}
            title={t("proxy_delete_dialog_title")}
            content={""}
            handleCancel={() => setDeleteDialog(false)}
            handleOk={handleDelete}
        />
    </div>
}