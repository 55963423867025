import Chart from "react-apexcharts";
import {Paper, Skeleton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import {AdsService as AnalyticsService} from "../../../../services/analytics.service";
import {CustomColors} from "../../../../themes/custom-colors";

export const TopSources = (props) => {
    const {t} = useTranslation();
    const {filter} = props;

    const [sources, setSources] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        AnalyticsService.getTopSources(filter).then(data => {
            if (data.data) {
                setSources(data.data.map(item => item.count))
                setCategories(data.data.map(item => t(item.source)))
            }

            setLoading(false);
        }).catch(e => {
            setLoading(false);
        });
    }, [filter]);

    const chartData = {
        series: [{
            name: t("sites_1"),
            data: sources
        }],
        options: {
            colors: [CustomColors.orange, CustomColors.purple, CustomColors.blue],
            chart: {
                height: 150,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 4,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: categories,
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        }
    };

    if(!loading) {
        if(sources.length > 0) {
            return (
                <Paper>
                    <Chart options={chartData.options}
                           height={150}
                           type={"bar"}
                           series={chartData.series}
                    />
                </Paper>
            )
        } else {
            return (
                <Paper sx={{ p:3 }}>
                    <Typography variant={"h5"}>{t("no_data")}</Typography>
                </Paper>
            )
        }
    } else {
        return (
            <Paper>
                <Skeleton variant={"rectangular"} height={150}/>
            </Paper>
        )
    }
}