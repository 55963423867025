import {Autocomplete, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import toastr from "toastr";
import {UserService} from "../../services/user.service";

export const UserSelector = (props) => {
    const {t} = useTranslation();
    const {value, setValue, excludeAllUsers, userType, freeSolo} = props;
    const [data, setData] = useState([]);

    const allItems = [
        {
            id: null,
            name: t("all_users")
        }
    ];

    useEffect(() => {
        const role = userType ? userType : null;

        UserService.getAll(role).then(data => {
            if (data.data) {
                if (!excludeAllUsers) {
                    setData(allItems.concat(data.data))
                } else {
                    setData(data.data.map(user => {
                        return {id: user.id, name: user.name}
                    }));
                }
            }
        }).catch(e => {
            toastr.error(t("error_loading"))
        })
    }, []);

    if (value) {
        return <Autocomplete
            disablePortal
            options={data}
            value={value}
            getOptionLabel={option => option.name}
            freeSolo={freeSolo !== undefined ? freeSolo : true}
            onChange={setValue}
            onClick={e => e.stopPropagation()}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            renderInput={(inputParams) =>
                <TextField {...inputParams}
                           fullWidth
                           autoComplete={'off'}
                           size={"small"}
                           onClick={e => e.stopPropagation()}
                           variant={"outlined"}
                           label={t("user")}
                />
            }
        />
    } else {
        return <Autocomplete
            disablePortal
            options={data}
            getOptionLabel={option => option.name}
            freeSolo={freeSolo !== undefined ? freeSolo : true}
            onChange={setValue}
            onClick={e => e.stopPropagation()}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            renderInput={(inputParams) =>
                <TextField {...inputParams}
                           fullWidth
                           size={"small"}
                           onClick={e => e.stopPropagation()}
                           variant={"outlined"}
                           label={t("user")}
                />
            }
        />
    }
}