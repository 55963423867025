import {Box, IconButton, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {KeywordsStatisticsByDay} from "./KeywordsStatisticsByDay";
import * as React from "react";
import {KeywordsStatisticsByHour} from "./KeywordsStatisticsByHour";
import {KeywordsStatisticsByDomains} from "./KeywordsStatisticsByDomains";

const style = {
    position: 'absolute',
    top: '5%',
    left: '5%',
    width: "90%",
    height: "90%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    pt: 6
};

const styleCloseButton = {
    position: 'absolute',
    top: '10px',
    right: '10px',
};

const selectorStyle = {
    position: 'absolute',
    top: '10px',
    left: '20px',
};

export const KeywordsStatisticsModal = ({open, handleClose, incomingData, type}) => {
    const renderChartByType = () => {
        if (type === 'day') {
            return <KeywordsStatisticsByDay incomingData={incomingData}/>
        } else if (type === 'domains') {
            return <KeywordsStatisticsByDomains incomingData={incomingData}/>
        } else {
            return <KeywordsStatisticsByHour incomingData={incomingData}/>
        }
    }

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={style}>
            <IconButton sx={styleCloseButton} onClick={handleClose}>
                <CloseIcon/>
            </IconButton>

            {/*<Box mb={3}>*/}
            {/*    <KeywordsStatisticsSelector value={type} setValue={(e, value) => {*/}
            {/*        setType(value.props.value);*/}
            {/*    }}/>*/}
            {/*</Box>*/}

            {renderChartByType()}
        </Box>
    </Modal>
}