import Grid from "@mui/material/Grid";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {grey} from "@mui/material/colors";

const grey500 = grey[500];

export const AfterRegister = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [email, setEmail] = useState(null)

    useEffect(() => {
        const registerEmail = localStorage.getItem("register_email");

        if (registerEmail) {
            setEmail(registerEmail)
        } else navigate("/login")
    }, []);

    if (email != null) {
        return <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack sx={{mb: {xs: -0.5, sm: 0.5}}}>
                    <Typography gutterBottom align={"center"} display={"block"} variant="h3">{t('confirm_your_email')}</Typography>
                    <Typography display={"block"} variant="h5" color={grey500}>{t('confirm_your_email_desc', {
                        email: email
                    })}</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt:3 }}>
                        <Button variant={"contained"} color={"primary"} type={"button"} onClick={() => navigate("/login")}>
                            {t("back_to_login")}
                        </Button>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    } else {
        return <div></div>
    }
}