import {Box, Button, Card, CardContent, FormHelperText, Grid, Typography} from "@mui/material";
import * as Yup from "yup";
import toastr from "toastr";
import {Formik} from "formik";
import {useTranslation} from "react-i18next";
import {DateRangePickerCustom} from "../../../components/DateRangePickerCustom";
import * as React from "react";
import {useState} from "react";
import moment from "moment";
import {ProjectSelector} from "../../../components/autocompletes/common/ProjectSelector";
import {UserSelector} from "../../../components/autocompletes/UserSelector";
import {ProjectsService as ProjectService} from "../../../services/projects.service";
import {ExportsService} from "../../../services/exports.service";

export const KeywordSiteDateExport = () => {
    const {t} = useTranslation();

    const [projects, setProjects] = useState([]);

    const setNewProjects = (userId) => {
        ProjectService.get(1, null, true, null, userId).then(data => {
            if (data.data.length === 0) {
                alert("У пользователя нету проектов")
            }

            setProjects(data.data)
        })
    }

    return <Card sx={{mt: 3}}>
        <CardContent>

            <Typography variant={"h5"} color={"text.secondary"}>
                Keyword Site Date
            </Typography>

            <Formik
                initialValues={{
                    start_date: moment().subtract('7', 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    user_id: null,
                    project_id: null
                }}
                validationSchema={Yup.object().shape({
                    start_date: Yup.date().required(t('required')),
                    end_date: Yup.date().required(t('required')),
                    user_id: Yup.number().required(t('required')),
                    project_id: Yup.number().required(t('required')),
                })}
                onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                    setSubmitting(true);

                    ExportsService.exportKeywordSiteDate(values).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');

                        const currentTimestamp = Math.floor(Date.now() / 1000);
                        const fileName = `keyword_site_date_${currentTimestamp}.xlsx`;

                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();

                        window.URL.revokeObjectURL(url);
                        link.remove();
                        setSubmitting(false)
                    }).catch(e => {
                        setSubmitting(false)
                    })
                }}
            >
                {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue}) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{flexGrow: 1, padding: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <DateRangePickerCustom
                                        startDate={values.start_date}
                                        endDate={values.end_date}
                                        onApply={(startDate, endDate) => {
                                            setFieldValue("start_date", startDate);
                                            setFieldValue("end_date", endDate);
                                        }}
                                    />

                                    {
                                        errors.start_date || errors.end_date ? <FormHelperText error={true}>
                                            {errors.start_date}
                                        </FormHelperText> : ""
                                    }
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <UserSelector
                                        excludeAllUsers={true}
                                        setValue={(e, value) => {
                                            setFieldValue('project_id', null);
                                            setFieldValue('user_id', value !== null ? value.id : null)

                                            if (value !== null) {
                                                setNewProjects(value.id)
                                            }
                                        }}
                                    />

                                    {
                                        errors.user_id ? <FormHelperText error={true}>
                                            {errors.user_id}
                                        </FormHelperText> : ""
                                    }
                                </Grid>

                                {
                                    values.user_id !== null && projects.length > 0 ? <Grid item xs={12} md={3}>
                                        <ProjectSelector
                                            projects={projects}
                                            setValue={(e, value) => {
                                                setFieldValue('project_id', value !== null ? value.id : null)
                                            }}
                                        />

                                        {
                                            errors.project_id ? <FormHelperText error={true}>
                                                {errors.project_id}
                                            </FormHelperText> : ""
                                        }
                                    </Grid> : ''
                                }

                                <Grid item xs={12} md={2}>
                                    <Button disableElevation
                                            disabled={isSubmitting}
                                            variant={"outlined"}
                                            type={"submit"}
                                    >
                                        {t("create")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                )}
            </Formik>

        </CardContent>
    </Card>
}