import LanguageIcon from "@mui/icons-material/Language";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SensorsIcon from '@mui/icons-material/Sensors';
import GroupIcon from '@mui/icons-material/Group';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import IosShareIcon from '@mui/icons-material/IosShare';
import KeyIcon from '@mui/icons-material/Key';

export const MenuItems = [
    {
        id: 'home-dashboard',
        title: "analytics",
        type: 'group',
        children: [
            {
                id: 'home-dashboard-analytics',
                title: "ads_analytics",
                url: '/dashboard',
                icon: InsightsIcon,
            },
            {
                id: 'keyword-statistics',
                title: "keywords_analytics",
                url: "/dashboard/keyword-statistic",
                icon: KeyIcon,
            },
        ]
    },
    {
        id: 'group-dashboard',
        title: "parser",
        type: 'group',
        children: [
            {
                id: 'projects',
                title: "projects",
                url: '/dashboard/projects',
                icon: FolderIcon,
            },
            {
                id: "ad-url",
                title: "all_sites",
                type: 'item',
                url: '/dashboard/ad-url',
                icon: LanguageIcon,
            },
            {
                id: "ads",
                title: 'ads',
                url: '/dashboard/ads',
                icon: SearchIcon,
            }
        ]
    },
    {
        id: 'group-dashboard1',
        title: "reports",
        type: 'group',
        children: [
            {
                id: 'report-campaigns',
                title: "campaigns",
                url: '/dashboard/reports',
                icon: CampaignIcon,
            },
            {
                id: 'report-preferences',
                title: "auto_report",
                url: "/dashboard/report-preferences",
                icon: AutoFixNormalIcon
            },
            {
                id: 'report-statistic',
                title: "statistic",
                url: "/dashboard/report-statistic",
                icon: BarChartIcon
            },
        ]
    },
];

export const MenuItemsAdmin = [
    {
        id: 'home-admin-dashboard',
        title: "home",
        type: 'item',
        url: "/dashboard/admin",
        icon: HomeIcon
    },
    {
        id: 'home-dashboard',
        title: "analytics",
        type: 'group',
        children: [
            {
                id: 'home-dashboard-analytics',
                title: "ads_analytics",
                url: '/dashboard/admin/analytics',
                icon: InsightsIcon,
            },
            {
                id: 'keyword-statistics-admin',
                title: "keywords_analytics",
                url: "/dashboard/admin/keyword-statistic",
                icon: KeyIcon,
            },
        ]
    },
    {
        id: 'parser',
        title: "parser",
        type: 'group',
        children: [
            {
                id: 'parser-projects',
                title: "projects",
                url: '/dashboard/admin/projects',
                icon: FolderIcon,
            },
            {
                id: "ad-url-admin",
                title: "all_sites",
                type: 'item',
                url: '/dashboard/admin/ad-url',
                icon: LanguageIcon,
            },
            {
                id: "ads-admin",
                title: 'ads',
                url: '/dashboard/admin/ads',
                icon: SearchIcon,
            },
            {
                id: 'parser-log',
                title: "parser_statistics",
                url: "/dashboard/admin/parser/log",
                icon: ListAltIcon,
            }
        ]
    },
    {
        id: 'report',
        title: "reports",
        type: 'group',
        icon: SettingsInputComponentIcon,
        children: [
            {
                id: 'report-statistics',
                title: "statistics",
                url: "/dashboard/admin/report/log",
                icon: CampaignIcon,
            },
            {
                id: 'report-statistics-details',
                title: "logs",
                url: "/dashboard/admin/report/log/details",
                icon: ListAltIcon,
            },
            {
                id: 'report-preferences',
                title: "auto_report",
                url: "/dashboard/admin/report-preferences",
                icon: AutoFixNormalIcon
            },
        ]
    },
    {
        id: 'users',
        title: "users",
        type: 'item',
        url: "/dashboard/admin/users",
        icon: GroupIcon
    },
    {
        id: 'proxies',
        title: "server_exemplars",
        type: 'item',
        url: "/dashboard/admin/proxies",
        icon: SensorsIcon
    },
    {
        id: 'exports',
        title: "exports",
        type: 'item',
        url: "/dashboard/admin/exports",
        icon: IosShareIcon
    }
];