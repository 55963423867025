import {Alert, Button} from "@mui/material";
import {useEffect, useState} from "react";
import {SubscriptionService} from "../../services/subscription.service";
import toastr from "toastr";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {UserService} from "../../services/user.service";
import AuthService from "../../services/auth.service";

export const AnySubscriptionAlert = (props) => {
    const {t} = useTranslation();
    const [hasSubscription, setHasSubscription] = useState(true);

    useEffect(() => {
        if (AuthService.getCurrentRole() !== "admin") {
            SubscriptionService.hasAnySubscription().then(data => {
                setHasSubscription(data.success);
            }).catch(e => {
                toastr.error(t("any_subscription_error"))
                setHasSubscription(true) // Just to hide alert if error on server
            })
        }
    }, []);

    if (!hasSubscription) {
        return (
            <Alert sx={{mb: 4}} severity={"info"}>
                {t("empty_subscriptions")}
            </Alert>
        );
    } else return "";
}