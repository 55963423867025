export const getParsedKeywords = (keywords, length = 6) => {
    let keys = keywords.slice().split("||");

    if(length !== -1) {
        keys = keys.splice(1, length)
    } else {
        keys.shift()
    }

    return keys.map(item => {
        const splited = item.split("::");
        return {
            id: splited[0],
            name: splited[1]
        };
    });
}