import Chart from "react-apexcharts";
import {Paper, Skeleton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import {AdsService as AnalyticsService} from "../../../../services/analytics.service";
import {CustomColors} from "../../../../themes/custom-colors";

export const TopDevices = (props) => {
    const {t} = useTranslation();
    const {filter} = props;

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        AnalyticsService.getTopDevices(filter).then(data => {
            if (data.data) {
                setSeries(Object.keys(data.data).map(item => data.data[item]))
                setCategories(Object.keys(data.data).map(item => t(item)))
            }

            setLoading(false);
        }).catch(e => {
            setLoading(false);
        });
    }, [filter]);

    const chartData = {
        series: series,
        options: {
            colors: [CustomColors.orange, CustomColors.purple, CustomColors.blue],
            chart: {
                id: "top-devices-chart",
                width: 350,
                height: 350,
                type: 'pie',
            },
            labels: categories,
            legend: {
                fontSize: "16px",
                position: "bottom",
                itemMargin: {
                    vertical: 2
                },
            }
        }
    };

    if (!loading) {
        if (series.length > 0) {
            return (
                <Paper sx={{py:2}}>
                    <style>
                        {'#apexchartstop-devices-chart {margin: 0 auto}'}
                    </style>
                    <Chart options={chartData.options}
                           height={350}
                           width={350}
                           type={"pie"}
                           series={chartData.series}
                    />
                </Paper>
            )
        } else {
            return (
                <Paper sx={{p: 3}}>
                    <Typography variant={"h5"}>{t("no_data")}</Typography>
                </Paper>
            )
        }
    } else {
        return (
            <Paper>
                <Skeleton variant={"rectangular"} height={150}/>
            </Paper>
        )
    }
}