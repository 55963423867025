import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export const HeaderSelect = (props) => {
    const {values, value, onChange, label, width} = props;
    return (
        <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
            <Select
                sx={{ width: width }}
                value={value}
                onChange={onChange}
                label={label}
            >
                {
                    values.map((value, index) => {
                        return (
                            <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}