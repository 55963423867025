import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogTitle} from "@mui/material";
import {DateRangePickerCustom} from "./DateRangePickerCustom";
import * as React from "react";
import {getDatePickerEndDate, getDatePickerStartDate} from "../utils/Date";
import {AdUrlService} from "../services/adUrl.service";
import toastr from "toastr";
import {ReporterService} from "../services/reporter.service";

export const SelectAdUrlDatesModal = (props) => {
    const {handleClose, handleSuccess, open, site, campaignId} = props;
    const {t} = useTranslation();

    const [startDate, setStartDate] = useState(getDatePickerStartDate())
    const [endDate, setEndDate] = useState(getDatePickerEndDate())

    const [targetStartDate, setTargetStartDate] = useState(getDatePickerStartDate())
    const [targetEndDate, setTargetEndDate] = useState(getDatePickerEndDate())

    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (site !== null) {
            AdUrlService.getByUrl(site).then(data => {
                if (data.data) {
                    setStartDate(data.data.first_seen_at);
                    setEndDate(data.data.last_seen_at);
                    setTargetStartDate(data.data.first_seen_at);
                    setTargetEndDate(data.data.last_seen_at);
                }
            }).catch(e => {
                toastr.error(t("error_loading"))
            })
        }
    }, [open]);

    const handleSubmit = () => {
        setSubmit(true)

        const payload = {
            ad_url: site,
            start_date: targetStartDate,
            end_date: targetEndDate
        };

        ReporterService.addToCampaign(campaignId, payload).then(data => {
            if (data.success) {
                toastr.success(t("items_added_to_report", {count: data.data}));
                handleSuccess();
            } else {
                toastr.error(t("error_server"));
            }

            setSubmit(false);
        }).catch(e => {
            setSubmit(false);
            toastr.error(t("error_server"));
        })
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"md"}>
            <Box sx={{p: 3}}>
                <DateRangePickerCustom
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={endDate}
                    onApply={(startDate, endDate) => {
                        setTargetStartDate(startDate)
                        setTargetEndDate(endDate)
                    }}
                    error={null}
                />

                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", pt: 2}}>
                    <Button
                        disabled={submit}
                        variant={"outlined"}
                        color={"primary"}
                        onClick={handleSubmit}
                    >
                        {t("add")}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}