import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Switch
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";
import {DatePickerCustom} from "../../../../../../components/DatePickerCustom";
import {SubscriptionService} from "../../../../../../services/subscription.service";
import {InvoicesService} from "../../../../../../services/invoices.service";
import {GeneratedInvoiceModal} from "../GeneratedInvoiceModal";
import {ReporterCustomEditFields} from "./ReporterCustomEditFields";
import {ReporterDefaultEditFields} from "./ReporterDefaultEditFields";


export const ReporterSubscriptionEdit = (props) => {
    const {handleClose, open, subscription, userId} = props;
    const {t} = useTranslation();

    const [initialValues, setInitialValues] = useState({
        available_reports: 30000,
        status: false,
        is_custom: false,
        expires_date: moment().toDate(),
        custom_price: 0
    });

    const [generatedInvoiceModal, setGeneratedInvoiceModal] = useState(false);
    const [generatedInvoiceId, setGeneratedInvoiceId] = useState("");

    useEffect(() => {
        setInitialValues({
            is_custom: Boolean(subscription.is_custom),
            available_reports: 30000,
            custom_price: subscription.custom_price === null ? 0 : subscription.custom_price,
            status: Boolean(subscription.status),
            expires_date: subscription.expires_date !== null ?
                moment(subscription.expires_date).toDate() : moment().add("M", 1).toDate()
        })
    }, [subscription]);

    const generateInvoice = (values) => {
        InvoicesService.generateReporterInvoice(userId, values).then(data => {
            setGeneratedInvoiceId(data.data.id);
            setGeneratedInvoiceModal(true);
        }).catch(e => {
            toastr.error(toastr.error(e.response.data.message || t("error_server")));
        })
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>{t("editing_reporter_subscription")}</DialogTitle>

            <Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        available_reports: Yup.number()
                            .min(0, t("min_value_error", {number: 0}))
                            .max(10000000, t("max_value_error", {number: 10000000}))
                            .required(t('required')),
                        status: Yup.bool().required(t('required')),
                        expires_date: Yup.date().required(t('required')),
                        is_custom: Yup.bool(),
                        custom_price: Yup.number()
                            .min(0, t("min_value_error", {number: 0}))
                            .max(10000000, t("max_value_error", {number: 10000000}))
                            .when("is_custom", {
                                is: true,
                                then: Yup.number().required(t("fill_custom_price"))
                            })
                    })}
                    onSubmit={(values, {setErrors, setFieldValue, setSubmitting, resetForm}) => {
                        setSubmitting(true);

                        if (moment(values.expires_date).isBefore(moment().startOf("day"))) {
                            if (!window.confirm(t("expires_less_then_now"))) {
                                setSubmitting(false);
                                return;
                            }
                        }

                        const insertValues = {...values};
                        insertValues.expires_date = moment(insertValues.expires_date).format("YYYY-MM-DD")

                        SubscriptionService.updateReporterSubscription(userId, insertValues).then(data => {
                            if (data.success) {
                                toastr.success(t("subscription_updated"));
                            } else {
                                toastr.error(data.message || t("error_server"));
                            }

                            setSubmitting(false);
                        }).catch(e => {
                            setSubmitting(false);
                            toastr.error(toastr.error(e.response.data.message || t("error_server")));
                        })
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box sx={{p: 3}}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name={"is_custom"}
                                                          checked={values.is_custom}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                />
                                            }
                                            label={t("custom_subscription")}/>
                                    </Grid>

                                    {
                                        values.is_custom ?
                                            <ReporterCustomEditFields
                                                values={values}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                touched={touched}
                                                errors={errors}
                                            /> :
                                            <ReporterDefaultEditFields
                                                values={values}
                                                handleChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                            />
                                    }


                                    <Grid item xs={12} md={4}>
                                        <DatePickerCustom
                                            onBlur={handleBlur}
                                            date={moment(values.expires_date).toDate()}
                                            onApply={item => setFieldValue("expires_date", moment(item).format("YYYY-MM-DD"))}
                                            error={Boolean(touched.expires_date && errors.expires_date)}
                                            maxDate={moment().add("years", 5).format("YYYY-MM-DD")}
                                        />
                                        {
                                            Boolean(errors.expires_date) ?
                                                <FormHelperText error={true}>{errors.expires_date}</FormHelperText> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={values.status}
                                                    name={"status"}
                                                    onChange={handleChange}
                                                />}
                                                label={t("status")}
                                            />
                                            {
                                                Boolean(errors.status) ?
                                                    <FormHelperText error={true}>{errors.status}</FormHelperText> : ""
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button disableElevation
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"submit"}
                                        >
                                            {t("update")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button disableElevation
                                                onClick={() => generateInvoice(values)}
                                                disabled={isSubmitting}
                                                variant={"outlined"}
                                                type={"button"}
                                                color={"info"}
                                        >
                                            {t("generate_invoice")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>

            <GeneratedInvoiceModal open={generatedInvoiceModal}
                                   invoiceId={generatedInvoiceId}
                                   handleClose={() => setGeneratedInvoiceModal(false)}
            />
        </Dialog>
    )
}