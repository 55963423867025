import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    createFilterOptions,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {AdUrlService} from "../../../../services/adUrl.service";
import toastr from "toastr";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import {ReportWhiteListService} from "../../../../services/reportWhiteList.service";

const filter = createFilterOptions();

let timer;

export const ReportPreferencesWhiteList = ({userId}) => {
    const {t} = useTranslation();

    const addDomainPrefix = t('add_domain') + ": ";

    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(true);

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const [adUrls, setAdUrls] = useState([]);
    const [autoCompleteInputValue, setAutoCompleteInputValue] = useState("");
    const [newAdUrl, setNewAdUrl] = useState(null);

    useEffect(() => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            AdUrlService.getAll(autoCompleteInputValue, userId).then(data => {
                setAdUrls(data.data.slice())
            }).catch(e => {
                toastr.error(t("error_loading"))
            })
        }, 500)
    }, [autoCompleteInputValue, userId])

    useEffect(() => {
        if (page === -1) {
            setPage(1);
            return;
        }

        getItems();
    }, [page]);

    useEffect(() => {
        setPage(-1)
    }, [userId])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (newAdUrl !== null) {
            ReportWhiteListService.store({
                ad_url: newAdUrl,
                user_id: userId
            }).then(data => {
                if (data.success) {
                    toastr.success(t("success_added"));
                } else {
                    toastr.error(t("error_when_adding"));
                }

                setPage(-1);
                setAutoCompleteInputValue("");
                setNewAdUrl(null);
            }).catch(e => {
                toastr.error(t("error_loading"))
            })
        } else {
            toastr.warning(t("should_select_website"));
            return false;
        }
    }

    const handleDelete = (id) => {
        ReportWhiteListService.delete(id, userId).then(data => {
            if (data.success) {
                toastr.success(t("success_remove"));
            } else {
                toastr.error(t("error_when_deleting"));
            }

            setPage(-1);
        }).catch(e => {
            toastr.error(t("error_loading"))
        })
    }

    const getItems = () => {
        setLoadingItems(true);
        ReportWhiteListService.index(page, userId).then(data => {
            if (data.data) {
                setItems(data.data);
                setPageCount(data.last_page)
            }
            setLoadingItems(false);
        }).catch(e => {
            toastr.error(t("error_loading"))
            setLoadingItems(false);
        })
    }

    const changePage = (event, newPage) => {
        setPage(newPage)
    }

    return (
        <Card>
            <CardContent>
                <Typography sx={{fontSize: 20}} color="text.secondary" gutterBottom>
                    {t("white_list")}
                </Typography>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {t("white_list_desc")}
                </Typography>

                <form noValidate style={{marginTop: 24, display: "flex"}} onSubmit={handleSubmit}>
                    <Autocomplete
                        value={newAdUrl}
                        onChange={(e, value) => setNewAdUrl(value.replace(addDomainPrefix, ''))}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const {inputValue} = params;

                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => {
                                return option.indexOf(inputValue) > -1
                            });

                            if (inputValue !== '' && !isExisting) {
                                filtered.push(addDomainPrefix + inputValue)
                            }

                            return filtered;
                        }}
                        options={adUrls}
                        getOptionLabel={(option) => option}
                        disablePortal
                        inputValue={autoCompleteInputValue}
                        onInputChange={(e, newValue) => setAutoCompleteInputValue(newValue)}
                        id="black-list-autocomplete"
                        sx={{width: "100%", mr: 1}}
                        renderInput={(params) =>
                            <TextField{...params}
                                      label={t("site_link")}
                                      fullWidth={true}
                            />
                        }
                    />
                    <Button
                        startIcon={<AddIcon/>}
                        sx={{flexShrink: 0}}
                        type={"submit"}
                        variant={"contained"}
                    >
                        {t("add")}
                    </Button>
                </form>

                {
                    !loadingItems ? <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("site")}</TableCell>
                                    <TableCell align="right">{t("actions")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item) => (
                                    <TableRow
                                        key={item.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{item.ad_url}</TableCell>
                                        <TableCell align="right">
                                            <IconButton color={"error"} onClick={() => handleDelete(item.id)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        <Box display={"flex"} height={300} justifyContent={"center"} alignItems={"center"} sx={{my: 2}}>
                            <CircularProgress/>
                        </Box>
                }

                <Pagination sx={{my: 2}} count={pageCount} page={page} onChange={changePage}/>

            </CardContent>
        </Card>
    )
}