import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import _ from "underscore";
import moment from "moment/moment";
import {Alert, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {calculatePrice, getDevicesCount} from "../../../../utils/Price";
import {PriceDiff} from "./PriceDiff";
import {lessThanDays} from "../../../../utils/Date";


export const PriceAlert = (props) => {
    const {t} = useTranslation();
    const {oldPrice, expiresDate, status, startDate, onNewPrice} = props;
    const {values} = useFormikContext() ?? {};
    const [price, setPrice] = useState(0);
    const [header, setHeader] = useState(t("selected_current_subscription"));

    const [nextExpiresDate, setNextExpiresDate] = useState(null);
    const [lessThan3days, setLessThan3days] = useState(false);

    useEffect(() => {
        if (_.isEmpty(values)) return;

        const newPrice = calculatePrice(values.hour_interval, values.keywords_count, getDevicesCount(values));
        setPrice(newPrice);
        setTitle(newPrice);

        onNewPrice(newPrice)
    }, [values]);

    useEffect(() => {
        setPrice(oldPrice)
    }, [oldPrice]);

    useEffect(() => {
        const momentExpiresDate = moment(expiresDate);
        const leftLessThan3Days = moment().isAfter(momentExpiresDate.subtract(3, "days"));
        const nowIsGreaterThenExpire = moment().isAfter(momentExpiresDate);

        setLessThan3days(leftLessThan3Days);

        if (leftLessThan3Days) {
            if(nowIsGreaterThenExpire) {
                setNextExpiresDate(moment().add(30, "days"))
            } else {
                setNextExpiresDate(momentExpiresDate.add(30, "days"))
            }
        }
    }, [expiresDate])


    const setTitle = (newPrice) => {
        if (status && newPrice === oldPrice) {
            setHeader(t("selected_current_subscription"));
        }
        if (status && newPrice > oldPrice) {
            setHeader(t("selected_new_subscription_is_better"));
        }
        if (status && newPrice < oldPrice) {
            setHeader(t("selected_new_subscription_is_worth"));
        }

        if (!status) {
            setHeader(t("selected_new_subscription"));
        }
    }

    return (
        <Alert severity={"info"}>
            <Typography variant={"h5"} gutterBottom={true}>
                {header}
            </Typography>

            {
                startDate ? <>
                    {t("current_price_per_month")}: <Typography variant={"subtitle1"} display={"inline"} color={"text.secondary"}>
                    {oldPrice}$
                </Typography>
                </> : ''
            }

            {
                price !== oldPrice || !status ? <>
                    <br/>
                    {t("new_price_per_month")}: <Typography display={"inline"} variant={"subtitle1"}>{price}$</Typography>
                </> : ""
            }


            <PriceDiff newPrice={price} oldPrice={oldPrice} expiresDate={expiresDate} />

            {
                expiresDate !== null ? <>
                    <br/>
                    {t("subscription_expires_date")}: <Typography display={"inline"} variant={"subtitle1"}>
                    {moment(expiresDate).format("YYYY-MM-DD")}
                </Typography>
                </> : ""
            }

            {
                nextExpiresDate !== null ? <>
                    <br/>
                    {t("new_subscription_date")}: <Typography display={"inline"} variant={"subtitle1"}>
                    {nextExpiresDate.format("YYYY-MM-DD")}
                </Typography>
                </> : ''
            }

            {
                status && price > oldPrice && !lessThan3days ? <p>{t("current_is_lower_than_new")}</p> : ""
            }
            {
                status && price < oldPrice && lessThan3days ? <p>
                    {
                        t("current_is_higher_than_new", {
                            "date": moment(expiresDate).format("YYYY-MM-DD")
                        })
                    }
                </p> : ""
            }
        </Alert>
    )
}