import Box from "@mui/material/Box";
import {IconButton, LinearProgress, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {DateRangePickerCustom} from "../../../../components/DateRangePickerCustom";
import {AdImpressionByDay} from "./AdImpressionByDay";
import {ImpressionSelectorType} from "./ImpressionSelectorType";
import {getDatePickerEndDate, getDatePickerStartDate, setLastDates} from "../../../../utils/Date";
import {AdImpressionByKeyword} from "./AdImpressionByKeyword";
import {DatePickerCustom} from "../../../../components/DatePickerCustom";
import {AdImpressionByHour} from "./AdImpressionByHour";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 1200,
    maxHeight: "90%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const styleCloseButton = {
    position: 'absolute',
    top: '10px',
    right: '10px',
};

const selectorStyle = {
    position: 'absolute',
    top: '10px',
    left: '20px',
};

export const AdsImpressionModal = (props) => {
    const {t} = useTranslation();
    const {open, handleClose, adUrl, userId} = props;
    const [loading, setLoading] = useState(false);

    const [type, setType] = useState("day");

    const [date, setDate] = useState({
        start_date: getDatePickerStartDate(),
        end_date: getDatePickerEndDate()
    })

    const [oneDate, setOneDate] = useState(moment());

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={style}>

            {
                loading ? <LinearProgress/> : ""
            }

            <IconButton sx={styleCloseButton} onClick={handleClose}>
                <CloseIcon/>
            </IconButton>

            <Box>
                <ImpressionSelectorType value={type} setValue={(e, value) => {
                    setType(value.props.value);
                }}/>
            </Box>

            {
                type === "hour" ? <Box sx={{my: 4}}>
                    <DatePickerCustom
                        date={oneDate.toDate()}
                        onApply={item => setOneDate(moment(item))}
                        maxDate={moment().format("YYYY-MM-DD")}
                    />
                </Box> : <Box sx={{my: 4}}>
                    <DateRangePickerCustom
                        startDate={date.start_date}
                        endDate={date.end_date}
                        onApply={(newStartDate, newEndDate) => {
                            setLastDates(newStartDate, newEndDate)
                            setDate({
                                start_date: newStartDate,
                                end_date: newEndDate
                            })
                        }}
                    />
                </Box>
            }

            {
                type === "day" ? <AdImpressionByDay adUrl={adUrl}
                                                    userId={userId}
                                                    loading={loading}
                                                    date={date}
                                                    setLoading={(value) => setLoading(value)}
                /> : ""
            }

            {
                type === "keyword" ? <AdImpressionByKeyword adUrl={adUrl}
                                                            userId={userId}
                                                            loading={loading}
                                                            date={date}
                                                            setLoading={(value) => setLoading(value)}
                /> : ""
            }

            {
                type === "hour" ? <AdImpressionByHour adUrl={adUrl}
                                                      userId={userId}
                                                      loading={loading}
                                                      date={oneDate}
                                                      setLoading={(value) => setLoading(value)}
                /> : ""
            }

        </Box>
    </Modal>
}