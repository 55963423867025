import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import * as React from "react";
import {useEffect, useState} from "react";
import {Skeleton} from "@mui/material";
import moment from "moment";
import {CustomColors} from "../../../../themes/custom-colors";

const defaultData = {
    total_count: [],
    recaptcha_count: [],
    error_count: []
};

export const ReporterLogChart = (props) => {
    const {t} = useTranslation();
    const {logs, loading} = props;

    const [data, setData] = useState({...defaultData});
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const totalCount = [];
        const recaptchaCount = [];
        const errorCount = [];

        logs.forEach(item => {
            totalCount.push(item.total_count)
            recaptchaCount.push(item.recaptcha_count)
            errorCount.push(item.error_count)
        })

        setData({
            total_count: [...totalCount],
            recaptcha_count: [...recaptchaCount],
            error_count: [...errorCount]
        });

        setCategories(logs.map(item => item.date))
    }, [logs]);

    const chartSettings = {
        series: [
            {
                name: t("total_count"),
                data: data.total_count
            },
            {
                name: t("recaptcha_count"),
                data: data.recaptcha_count
            },
            {
                name: t("error_count"),
                data: data.error_count
            },
        ],
        options: {
            colors: [CustomColors.orange, CustomColors.blue, CustomColors.red],
            chart: {
                height: 200,
                type: 'line',
                toolbar: {
                    show: false,
                },
                sparkline: {
                    enabled: false
                },
                selection: {
                    enabled: false
                },
                zoom: {
                    enabled: false
                }
            },
            grid: {
                show: true,
                padding: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    hideOverlappingLabels: true,
                    formatter: function (value) {
                        return moment(value, "YYYY-MM-DD", "uk")
                            .format("D MMM");
                    }
                },
                crosshairs: {
                    show: false,
                },
                type: 'date',
                tickAmount: 7
            },
            yaxis: {
                labels: {
                    show: true,
                }
            },
            legend: {
                show:false,
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                marker: {
                    show: true,
                },
            }
        },
    }

    if(!loading) {
        if(data.total_count.length > 0) {
            return (
                <Chart options={chartSettings.options}
                       type={"line"}
                       height={200}
                       series={chartSettings.series}
                />
            )
        }
    } else {
        return <Skeleton variant={"rectangular"} height={200}/>
    }
}