export const keywordsCountCoefficients = {
    100: 1,
    150: 1.4,
    250: 2.2
};
export const intervalBasePrices = {
    8: 1000,
    12: 1425,
    24: 2700
}

export const calculatePrice = (interval, keywordsCount) => {
    return Math.round(
        intervalBasePrices[interval] * keywordsCountCoefficients[keywordsCount]
    )
}

export const getDevicesCount = (values) => {
    let count = 0;

    if (values.has_desktop) count++;
    if (values.has_android) count++;
    if (values.has_iphone) count++;

    return count - 1;
}