import {Box, Button, Card, CardContent, Chip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {useState} from "react";
import {ReporterSubscriptionEdit} from "./ReporterSubscriptionEdit";
import {CurrentReporterSubscriptionEdit} from "../../../../../user/subscriptions/CurrentReporterSubscriptionEdit";

export const ReporterSubscriptionCard = (props) => {
    const {subscription, userId, onUpdate, isCurrent} = props;
    const {t} = useTranslation();

    const [editModal, setEditModal] = useState(false);

    const renderStatus = () => {
        if (!subscription) return "";

        if (subscription.status) {
            return (
                <Chip size={"small"} label={t("is_active")} color={"success"}/>
            )
        } else {
            return (
                <Chip size={"small"} label={t("no_active")} color={"secondary"}/>
            )
        }
    }

    const renderCustomLabel = () => {
        if (!subscription) return "";

        if (subscription.is_custom) {
            return (
                <Chip sx={{mr: 1}} size={"small"} label={t("custom_subscription")} color={"warning"}/>
            )
        }
    }

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Box sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <Typography variant={"h4"}>
                        {t("reporter_subscription")}
                    </Typography>
                    <div>
                        {renderCustomLabel()}
                        {renderStatus()}
                    </div>
                </Box>
                {
                    subscription.status ? <>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Box sx={{p: 2}}>
                                <Typography variant={"body2"}>{t("reports_available")}</Typography>
                                <Typography variant={"h5"} align={"center"}>{!!subscription ? subscription.available_reports : ""}</Typography>
                            </Box>
                        </Box>

                        <Typography variant={"caption"} gutterBottom align={"center"} display={"block"} color={"text.secondary"}>
                            {t("subscription_expires_date")} <br/>
                            {moment(subscription.expires_date).format("YYYY-MM-DD")}
                        </Typography>
                    </> : <Typography gutterBottom align={"center"} variant={"h6"}>{t("subscription_is_not_active")}</Typography>
                }

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Button
                        size={"small"}
                        variant={"outlined"}
                        onClick={() => setEditModal(true)}
                    >
                        {
                            isCurrent ? t("add_reports") : t("edit")
                        }
                    </Button>
                </Box>
            </CardContent>

            {
                !isCurrent ? <ReporterSubscriptionEdit
                    open={editModal}
                    handleClose={() => {
                        setEditModal(false)
                        onUpdate();
                    }}
                    subscription={subscription}
                    userId={userId}
                /> : <CurrentReporterSubscriptionEdit
                    open={editModal}
                    handleClose={() => {
                        setEditModal(false)
                        onUpdate();
                    }}
                    userId={userId}
                />
            }

        </Card>
    )
}