import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';

// material-ui
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

// third party
import * as Yup from 'yup';
import {Formik} from 'formik';

// project import
import AnimateButton from '../../../components/@extended/AnimateButton';

// assets
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import AuthService from "../../../services/auth.service";
import {useTranslation} from "react-i18next";
import toastr from "toastr";

const RegisterForm = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    name: '',
                    password: '',
                    confirm_password: '',
                    telegram: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(t('email_invalid')).max(255).required(t('required')),
                    password: Yup.string().min(8, t('min_length_8')).required(t('required')),
                    confirm_password: Yup.string().min(8, t('min_length_8')).required(t('required')).oneOf([Yup.ref('password'), null], t("passwords_must_match")),
                    telegram: Yup.string().required(t('required')),
                    name: Yup.string().required(t('required')),
                })}
                onSubmit={(values, {setErrors, setStatus, setSubmitting}) => {
                    AuthService.register(values)
                        .then(response => {
                            if (response.success) {
                                navigate("/after-register")
                                localStorage.setItem("register_email", response.data.email)
                            } else {
                                toastr.error(response.message || t("error_server"));
                            }

                            setSubmitting(false)
                        }).catch(e => {
                            setSubmitting(false)
                            setErrors({
                                submit: Object.keys(e.response.data.data).map(item => {
                                    return e.response.data.data[item]
                                }).join("\n") || t("error_server")
                            })
                        })
                }}
            >
                {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate autoComplete={"off"} onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login-2">E-mail</InputLabel>
                                    <OutlinedInput
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        id="email-login-2"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Email"
                                        fullWidth
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="name">{t("your_name")}</InputLabel>
                                    <OutlinedInput
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        id="name"
                                        type="text"
                                        value={values.name}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t("your_name")}
                                        fullWidth
                                        error={Boolean(touched.name && errors.name)}
                                    />
                                    {touched.name && errors.name && (
                                        <FormHelperText error>
                                            {errors.name}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-login">{t('password')}</InputLabel>
                                    <OutlinedInput
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        fullWidth
                                        error={Boolean(touched.password && errors.password)}
                                        id="-password-login"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder={t('password')}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-confirm">{t('password')}</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        autocomplete="new-password"
                                        error={Boolean(touched.confirm_password && errors.confirm_password)}
                                        id="-password-confirm"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirm_password}
                                        name="confirm_password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder={t('password')}
                                    />
                                    {touched.confirm_password && errors.confirm_password && (
                                        <FormHelperText error>
                                            {errors.confirm_password}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="telegram">Telegram</InputLabel>
                                    <OutlinedInput
                                        id="telegram"
                                        type="text"
                                        value={values.telegram}
                                        name="telegram"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Telegram"
                                        fullWidth
                                        error={Boolean(touched.telegram && errors.telegram)}
                                    />
                                    {touched.telegram && errors.telegram && (
                                        <FormHelperText error>
                                            {errors.telegram}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {t('register')}
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RegisterForm;
