import {Tooltip, Typography} from "@mui/material";

import InfoIcon from '@mui/icons-material/Info';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import moment from "moment";

export const PriceDiff = (props) => {
    const {t} = useTranslation();
    const {expiresDate, newPrice, oldPrice} = props;
    const [diff, setDiff] = useState(0);
    const [calculation, setCalculation] = useState({
        new_price: 0,
        old_price: 0,
        days: 0,
        new_days: 0
    })

    const calculateDiff = (newPrice) => {
        if(expiresDate === null) return;

        const mExpiresDate = moment(expiresDate);
        const mStartDate = moment()

        const leftLessThan3Days = moment().isAfter(mExpiresDate.clone().subtract(3, "days"));
        const newExpiresDate = leftLessThan3Days ? 30 : 0;

        const duration = moment.duration(moment(expiresDate).diff(mStartDate));
        const daysToExpires = Math.round(duration.asDays());

        const newPricePerDay = newPrice / 30;
        const oldPricePerDay = oldPrice / 30;

        const result = ((newPricePerDay * daysToExpires) - (oldPricePerDay * daysToExpires)) + (newExpiresDate * newPricePerDay);

        setCalculation({
            new_price: newPricePerDay,
            old_price: oldPricePerDay,
            days: daysToExpires,
            new_days: newExpiresDate
        });

        setDiff(Math.ceil(result));
    }

    useEffect(() => {
        const momentExpiresDate = moment(expiresDate);
        const leftLessThan3Days = moment().isAfter(momentExpiresDate.subtract(3, "days"));

        calculateDiff(newPrice)
    }, [newPrice])

    if (newPrice > oldPrice && expiresDate !== null) {
        return <>
            <br/>
            {t("price_diff")}: <Typography display={"inline"} variant={"subtitle1"}>{diff}$ <Tooltip title={
            <>
                <Typography color="inherit">{t("example_calc_subs_diff")}</Typography>
                <ul style={{padding: 0}}>
                    <li>{t("example_calc_subs_new_price_per_day")}: {calculation.new_price}$</li>
                    <li>{t("example_calc_subs_old_price_per_day")}: {calculation.old_price}$</li>
                    <li>{t("example_calc_subs_left_days")}: {calculation.days}</li>
                    {calculation.new_days > 0 ? <li>{t("example_calc_add_term")}: {calculation.new_days}</li> : ""}
                </ul>

                <p>(({calculation.new_price} * {calculation.days}) - ({calculation.old_price} * {calculation.days})) {calculation.new_days > 0 ? `+ (${calculation.new_days} * ${calculation.new_price})` : ""}
                    = {diff}$</p>
            </>
        }>
            <InfoIcon display={"inline"} fontSize={"15px"}/>
        </Tooltip></Typography>
        </>
    }

    return ""
}