import {Autocomplete, FormControl, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {ProjectsService as ProjectService} from "../../../../../services/projects.service";
import {CountryService} from "../../../../../services/country.service";

export const Countries = ({selectedCountries, setSelectedCountries, projects, selectedCountriesTmp}) => {
    const {t} = useTranslation();

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        CountryService.get().then(data => {
            if (data.data) {
                setCountries(Object.keys(data.data).map(item => {
                    return {
                        id: item.toLowerCase(),
                        label: data.data[item]
                    }
                }))
            }
        })
    }, []);

    useEffect(() => {
        if (selectedCountriesTmp.length > 0 && countries.length > 0) {
            setSelectedCountries(selectedCountriesTmp.map(id => {
                const findIndex = countries.findIndex(item => item.id === id)

                if (findIndex > -1) return countries[findIndex]
            }))
        }
    }, [countries, selectedCountriesTmp]);

    return <FormControl fullWidth>
        <Autocomplete
            multiple
            options={countries}
            onChange={(event, value, reason, details) => {
                setSelectedCountries(value);
            }}
            disableCloseOnSelect={true}
            value={selectedCountries}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("country")}
                />
            )}
        />
    </FormControl>
}