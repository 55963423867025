import {Button, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Typography} from "@mui/material";
import TranslateIcon from '@mui/icons-material/Translate';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../lang/i18n";
import moment from "moment";

export const LangSelector = (props) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'lang_selector' : undefined;

    const setLang = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("locale", lang)
        moment.locale(lang);
    }

    return (
        <>
            <IconButton onClick={handleClick} color={"secondary"} size={"small"}>
                <TranslateIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setLang("ru")}>
                            <ListItemText primary={t("lang_ru")} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setLang("en")}>
                            <ListItemText primary={t("lang_en")} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
        </>
    )
}