import axios from "axios";
import qs from "qs";

export class ReportCampaignItemService {
    static getUserStatistic(startDate, endDate, country, page) {
        let filterQuery = qs.stringify({
            start_date: startDate,
            end_date: endDate,
            country: country,
            page
        });

        return axios.get(`/dashboard/report-campaign-item/statistic?${filterQuery}`).then((response) => {
            return response.data;
        });
    }

    static getDetailedUserStatistic(date, country, page) {
        let filterQuery = qs.stringify({
            date,
            country,
            page
        });

        return axios.get(`/dashboard/report-campaign-item/statistic/detailed?${filterQuery}`).then((response) => {
            return response.data;
        });
    }
}