import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import * as React from "react";

export const device = (params) => {
    return (
        <div>
            {
                params.row.is_desktop ?
                    <ScreenSearchDesktopIcon color={"grey"} sx={{marginRight: 1}} fontSize={"small"}/> : ""
            }
            {
                params.row.is_android ?
                    <AndroidIcon color={"success"} sx={{marginRight: 1}} fontSize={"small"}/> : ""
            }
            {
                params.row.is_iphone ? <AppleIcon color={"info"} fontSize={"small"}/> : ""
            }
        </div>
    )
}